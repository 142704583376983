import {
  AvatarComponent,
  BadgeComponent,
  ButtonComponent,
  EnvironmentBannerComponent,
} from '@abb-procure/common';
import {
  EMPTY_GUID,
  ENVIRONMENT_INFO,
  EnvironmentInfo,
} from '@abb-procure/constants';
import {
  DocumentsApiService,
  LoginUserService,
  LookupDataState,
  ResourceLinkSlimModel,
  UserState,
} from '@abb-procure/data';
import { InboxComponent } from '@abb-procure/inbox';
import { QuickSearchComponent } from '@abb-procure/search';
import { SnackbarService } from '@abb-procure/snackbar';
import { TemplateFinderDialogService } from '@abb-procure/template-finder';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ElementRef,
  Input,
  ViewChild,
  booleanAttribute,
  inject,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router, RouterLink } from '@angular/router';
import { QuicklinkDirective } from 'ngx-quicklink';
import { AppNavigationComponent } from '../app-navigation/app-navigation.component';
import { CreateMenuComponent } from '../create-menu/create-menu.component';

@Component({
  selector: '[procure-app-header]',
  templateUrl: './app-header.component.html',
  styleUrl: './app-header.component.scss',
  standalone: true,
  imports: [
    EnvironmentBannerComponent,
    RouterLink,
    ButtonComponent,
    MatIconModule,
    AppNavigationComponent,
    MatTooltipModule,
    QuickSearchComponent,
    MatMenuModule,
    AvatarComponent,
    BadgeComponent,
    InboxComponent,
    QuicklinkDirective,
    CreateMenuComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppHeaderComponent {
  @ViewChild('header', { static: false }) protected header?: ElementRef;

  @Input({ transform: booleanAttribute })
  protected isEnvironmentBannerEnabled: boolean = false;

  protected readonly isExpanded = signal(false);

  protected readonly environmentInfo: EnvironmentInfo =
    inject(ENVIRONMENT_INFO);

  protected readonly loginUserService = inject(LoginUserService);
  protected readonly userState = inject(UserState);
  protected readonly lookupDataState = inject(LookupDataState);
  protected readonly documentsApiService = inject(DocumentsApiService);
  protected currentUser$ = this.loginUserService.currentUser$;
  protected readonly EMPTY_GUID = EMPTY_GUID;

  protected isDownloading: boolean = false;
  protected currentDownloads: ResourceLinkSlimModel[] = [];

  private readonly templateFinderDialogService = inject(
    TemplateFinderDialogService,
  );

  private readonly router = inject(Router);
  private readonly snackbarService = inject(SnackbarService);
  private readonly destroyRef = inject(DestroyRef);

  isCurrentDownloading(resource?: ResourceLinkSlimModel): boolean {
    return (
      this.currentDownloads.find((el) => resource?.id === el.id) !== undefined
    );
  }

  protected resourceClick(resource: ResourceLinkSlimModel | undefined): void {
    if (!resource?.isDocument) {
      return;
    }

    this.downloadSingleDocument(resource);
  }

  protected downloadSingleDocument(resource: ResourceLinkSlimModel): void {
    if (this.isCurrentDownloading(resource)) {
      return;
    }

    this.isDownloading = true;
    this.currentDownloads = [...this.currentDownloads, resource];

    this.documentsApiService
      .getResourceDownloadUrl$(resource.id)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res) => {
        this.isDownloading = false;
        this.currentDownloads = this.currentDownloads.filter(
          (el) => el.id !== resource.id,
        );

        if (res) {
          window.location.href = res;
        }
      });
  }

  protected logoutSubstituteUser(): void {
    this.loginUserService.stopImpersonatingUser();

    this.snackbarService.showInfoSnackbar({
      text: 'Returned to login user.',
    });

    window.location.reload();
  }

  protected showTemplateFinder(): void {
    this.templateFinderDialogService.openTemplateFinderDialog();
  }

  protected addProject(): void {
    this.router
      .navigate(['/'], { skipLocationChange: true })
      .then(() => this.router.navigate(['/PROJ/add']));
  }

  protected addRequestForRfq(): void {
    this.router
      .navigate(['/'], { skipLocationChange: true })
      .then(() => this.router.navigate(['/RFRFQ/add']));
  }

  protected logout(): void {
    // eslint-disable-next-line rxjs/no-ignored-observable
    this.loginUserService.logout$();
  }

  protected toggleMenu(): void {
    this.isExpanded.update((state) => !state);
  }
}
