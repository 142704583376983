import { DateString, nullable } from '@abb-procure/api';
import * as t from 'io-ts';
import { EntityStatus } from './entity-status.type';
import { GuidNameCode } from './guid-name-code.type';
import { IdName } from './id-name.type';

const UserLocalDivision = t.type(
  {
    unit: GuidNameCode,
    role: IdName,
    reviewStatus: nullable(EntityStatus),
    reviewed: nullable(DateString),
    nextReview: nullable(DateString),
  },
  'UserLocalDivision',
);

type UserLocalDivision = t.TypeOf<typeof UserLocalDivision>;
export { UserLocalDivision };
