import * as t from 'io-ts';

const UserPermissions = t.type(
  {
    canManageUsers: t.boolean,
    canManageOrganization: t.boolean,
    canManageSuppliers: t.boolean,
    canManageNotifications: t.boolean,
    canManageResources: t.boolean,
    canManageTemplates: t.boolean,
    canAddRequestForRequestForQuotation: t.boolean,
    canAddPurchaseRequisition: t.boolean,
    canAddProject: t.boolean,
    canAddRequestForRequestForQutoationFromTemplate: t.boolean,
    canViewAdminView: t.boolean,
    canViewOrganisation: t.boolean,
    canManageCategories: t.boolean,
  },
  'UserPermissions',
);

type UserPermissions = t.TypeOf<typeof UserPermissions>;
export { UserPermissions };
