import { UserSlim } from '../types/user-slim.type';
import { IdNameModel } from './id-name.model';

export class UserSlimModel extends IdNameModel implements UserSlim {
  email: string | null = null;
  avatarUrl: string | null = null;

  constructor(userSlim: UserSlim) {
    super(userSlim);

    this.email = userSlim.email;
    this.avatarUrl = userSlim.avatarUrl;
  }

  getDisplay(): string {
    return `${this.name}`;
  }
}
