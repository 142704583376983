<button
  class="menu-child"
  data-testid="item"
  matTooltipPosition="right"
  [class.menu-child--last]="last()"
  [class.menu-child--show]="show()"
  [class.menu-child--expanded]="expanded()"
  [matTooltip]="!expanded() ? tooltip() || '' : ''"
>
  @if (route(); as route) {
    <a
      class="menu-child__link"
      [class.menu-child__link--expanded]="expanded()"
      [routerLink]="route"
      [class.menu-child__content--active]="active()"
    >
      @if (expanded()) {
        <ng-container *ngTemplateOutlet="content" />
      }
    </a>
  }
  @if (view(); as view) {
    <div
      class="menu-child__content"
      [class.menu-child__content--active]="active()"
      (click)="navigate()"
    >
      @if (expanded()) {
        <ng-container *ngTemplateOutlet="content" />
      }
    </div>
  }
</button>
<div
  class="menu-child__tree-indicator"
  [class.menu-child__tree-indicator--expanded]="expanded()"
>
  <div
    class="menu-child__vertical-line"
    [class.menu-child__vertical-line--last]="last()"
    [class.menu-child__vertical-line--first]="first()"
    [class.menu-child__vertical-line--expanded]="expanded()"
  ></div>
  <div
    class="menu-child__horizontal-line"
    [class.menu-child__horizontal-line--last]="last()"
    [class.menu-child__horizontal-line--first]="first()"
    [class.menu-child__horizontal-line--show]="show()"
    [class.menu-child__horizontal-line--expanded]="expanded()"
  ></div>
</div>

<ng-template #content>
  <ng-content />
</ng-template>
