import { Injectable } from '@angular/core';
import { v4 as uuid } from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class FingerprintService {
  get fingerprint(): string {
    return this._fingerprint;
  }

  private _fingerprint: string;

  constructor() {
    let fingerprint = localStorage.getItem('fingerprint');

    if (!fingerprint) {
      const id = `${Number(new Date())}-${uuid()}`;

      localStorage.setItem('fingerprint', id);
      fingerprint = id;
    }

    this._fingerprint = fingerprint;
  }
}
