import { PreferencesState } from '@abb-procure/data';
import { Pipe, PipeTransform, inject } from '@angular/core';
import { format } from 'date-fns';

export const formatDate = (
  date: Date | null,
  transform: string | null,
  dateFormat: string | null,
): string | null => {
  if (!date || !transform) {
    return null;
  }

  switch (transform) {
    case 'short':
      if (dateFormat) {
        return format(date, `${dateFormat} HH:mm`);
      }

      return new Intl.DateTimeFormat(undefined, {
        hour: 'numeric',
        minute: 'numeric',
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      }).format(date);
    case 'shortDate':
      if (dateFormat) {
        return format(date, dateFormat);
      }

      return new Intl.DateTimeFormat().format(date);
    default:
      return format(date, transform);
  }
};

@Pipe({
  name: 'date',
  standalone: true,
})
export class DatePipe implements PipeTransform {
  private readonly dateFormat = inject(PreferencesState).dateFormat;

  transform(
    date: unknown,
    transform: string | null = 'shortDate',
    dateFormat = this.dateFormat(),
  ): string | null {
    if (!date) {
      return null;
    }

    try {
      return formatDate(
        typeof date === 'string' ? new Date(date) : (date as Date),
        transform,
        dateFormat,
      );
    } catch {
      return null;
    }
  }
}
