import { nullable } from '@abb-procure/api';
import * as t from 'io-ts';

const IdName = t.type(
  {
    id: t.number,
    name: nullable(t.string),
  },
  'IdName',
);

type IdName = t.TypeOf<typeof IdName>;
export { IdName };
