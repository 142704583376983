import { fetchData } from '@abb-procure/api';
import {
  EntityBadgeComponent,
  EntityUtility,
  SearchHighlightPipe,
} from '@abb-procure/common';
import { EntityIdAliasPrefixes } from '@abb-procure/constants';
import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
  inject,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  MatAutocompleteModule,
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Router, RouterLink } from '@angular/router';
import { QuicklinkDirective } from 'ngx-quicklink';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { SearchDocumentResponseModel } from '../../models/search-document-response.model';
import {
  SEARCH_ALL_SYMBOL,
  SearchApiService,
} from '../../services/search-api.service';

interface QuickSearch {
  quickSearch: FormControl<string>;
}

type SearchTerm = {
  number: string;
  id: string;
};

export const filter = (opt: readonly string[], value: string): string[] => {
  const filterValue = value.toLowerCase();
  return opt.filter((item) => item.toLowerCase().startsWith(filterValue));
};

const MIN_INPUT_LENGTH = 3;
const DEBOUNCE_MS = 500;

/**
 * @title Option groups autocomplete
 */
@Component({
  selector: 'procure-quick-search',
  templateUrl: './quick-search.component.html',
  styleUrls: ['./quick-search.component.scss'],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    MatIconModule,
    MatProgressSpinnerModule,
    NgTemplateOutlet,
    RouterLink,
    MatOptionModule,
    EntityBadgeComponent,
    AsyncPipe,
    SearchHighlightPipe,
    QuicklinkDirective,
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuickSearchComponent {
  @ViewChild(MatAutocompleteTrigger)
  readonly autocomplete!: MatAutocompleteTrigger;

  readonly form: FormGroup<QuickSearch>;
  searchResult: SearchDocumentResponseModel | null = null;
  isFakePannelVisible: boolean = false;
  getEntityName = EntityUtility.getEntityName;
  EntityIdAliasPrefixes = EntityIdAliasPrefixes;
  hintFocusTrapped: boolean = false;
  isFocused: boolean = false;

  get hasResults(): boolean {
    return this.searchResult !== null;
  }

  get isEmpty(): boolean {
    return (
      this.searchResult !== null &&
      !this.searchResult.projects?.count &&
      !this.searchResult.purchaseRequisitions?.count &&
      !this.searchResult.requestForQuotations?.count &&
      !this.searchResult.requestForRequestForQuotations?.count &&
      !this.searchResult.suppliers?.count
    );
  }

  protected readonly isSearching = signal(false);

  private searchedTerm: string = '';

  private readonly searchApiService = inject(SearchApiService);
  private readonly changeRef = inject(ChangeDetectorRef);
  private readonly router = inject(Router);
  private readonly renderer = inject(Renderer2);
  private readonly formBuilder = inject(FormBuilder);
  private readonly destroyRef = inject(DestroyRef);

  constructor() {
    this.form = this.formBuilder.nonNullable.group<QuickSearch>({
      quickSearch: this.formBuilder.nonNullable.control(''),
    });

    this.getFormControl('quickSearch')
      .valueChanges.pipe(
        map((term) => (typeof term === 'string' && term ? term.trim() : '')),
        debounceTime(DEBOUNCE_MS),
        distinctUntilChanged(),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((value) => {
        this.search(value);
      });
  }

  openFakePanel(): void {
    this.isFakePannelVisible = true;
    this.isFocused = true;
  }

  closeFakePanel(): void {
    this.isFakePannelVisible = false;
    this.isFocused = false;
  }

  closePanel(): void {
    this.autocomplete.closePanel();
  }

  onMouseDown(event: MouseEvent): void {
    const target = event.target as HTMLElement;

    if (target.tagName === 'A') {
      target.click();
    }
  }

  /**
   * Starts a search with the input
   */
  onSubmit(): void {
    this.search(this.getFormControl('quickSearch').value, true);
  }

  /**
   * Navigates to a given search result.
   */
  onSelect(value: string | SearchTerm): void {
    this.getFormControl('quickSearch').setValue('');

    this.renderer.selectRootElement('#searchInput').blur();

    if (typeof value !== 'string') {
      this.searchApiService.addSearchActivity({
        searchTerm: this.searchedTerm,
        entityId: value.id,
        entityNumber: value.number,
      });

      this.router.navigate([value.number]);
    } else {
      this.router.navigate([value]);
    }

    this.closeFakePanel();
  }

  getFormControl = (name: keyof QuickSearch): FormControl =>
    this.form.get(name) as FormControl;

  private search(term: string, force: boolean = false): void {
    if (
      term.length < MIN_INPUT_LENGTH &&
      term !== SEARCH_ALL_SYMBOL &&
      !force
    ) {
      this.searchResult = null;
      this.changeRef.markForCheck();

      return;
    }

    fetchData(
      this.searchApiService.quickSearch(term),
      this.isSearching,
      (response) => {
        this.searchResult = response;
        this.searchedTerm = term;
        this.changeRef.markForCheck();
      },
    );
  }
}
