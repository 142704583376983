import { DestroyRef, Injectable, inject } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DEFAULT_MIDDLE_DIALOG_WIDTH } from '@abb-procure/constants';
import { Overlay } from '@angular/cdk/overlay';
import type { SessionsDialogComponent } from '../components/sessions-dialog/sessions-dialog.component';
import type { RenewDialogComponent } from '../components/renew-dialog/renew-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class SessionsDialogService {
  private sessionsDialogPromise?: Promise<MatDialogRef<SessionsDialogComponent, void>>;
  private renewDialogPromise?: Promise<MatDialogRef<RenewDialogComponent, void>>;
  private readonly dialog = inject(MatDialog);
  private readonly destroyRef = inject(DestroyRef);
  private readonly overlay = inject(Overlay);

  async openSessionsDialog(): Promise<MatDialogRef<SessionsDialogComponent, void>> {
    if (this.sessionsDialogPromise) {
      return this.sessionsDialogPromise;
    }

    this.sessionsDialogPromise = (async (): Promise<MatDialogRef<SessionsDialogComponent, void>> => {
      const module = await import('../components/sessions-dialog/sessions-dialog.component');

      const dialog = this.dialog
        .open<SessionsDialogComponent, null, void>(
        module.SessionsDialogComponent,
        {
          panelClass: ['sessions-dialog'],
          width: DEFAULT_MIDDLE_DIALOG_WIDTH,
          scrollStrategy: this.overlay.scrollStrategies.block(),
        },
      );

      dialog.afterClosed()
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(() => {
          this.sessionsDialogPromise = undefined;
        });

      return dialog;
    })();

    return this.sessionsDialogPromise;
  }

  async openRenewDialog(): Promise<MatDialogRef<RenewDialogComponent, void>> {
    if (this.renewDialogPromise) {
      return this.renewDialogPromise;
    }

    this.renewDialogPromise = (async (): Promise<MatDialogRef<RenewDialogComponent, void>> => {
      const module = await import('../components/renew-dialog/renew-dialog.component');

      const dialog = this.dialog
        .open<RenewDialogComponent, null, void>(
        module.RenewDialogComponent,
        {
          panelClass: ['renew-dialog'],
          scrollStrategy: this.overlay.scrollStrategies.block(),
        },
      );

      dialog.afterClosed()
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(() => {
          this.renewDialogPromise = undefined;
        });

      return dialog;
    })();

    return this.renewDialogPromise;
  }
}
