import {
  LocaleService,
  ProcureDateAdapter,
  ProcureTitleStrategy,
} from '@abb-procure/common';
import {
  DEFAULT_DIALOG_WIDTH,
  ENVIRONMENT_CONFIG,
  ENVIRONMENT_INFO,
} from '@abb-procure/constants';
import { GlobalErrorHandlerService } from '@abb-procure/error-handling';
import {
  CloseScrollStrategy,
  Overlay,
  ScrollStrategyOptions,
} from '@angular/cdk/overlay';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, LOCALE_ID, Provider } from '@angular/core';
import { MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS } from '@angular/material/button-toggle';
import { DateAdapter } from '@angular/material/core';
import { MAT_DATEPICKER_SCROLL_STRATEGY } from '@angular/material/datepicker';
import {
  MAT_DIALOG_DEFAULT_OPTIONS,
  MAT_DIALOG_SCROLL_STRATEGY,
} from '@angular/material/dialog';
import { MAT_SELECT_SCROLL_STRATEGY } from '@angular/material/select';
import { MAT_TABS_CONFIG } from '@angular/material/tabs';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { TitleStrategy } from '@angular/router';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalService,
} from '@azure/msal-angular';
import {
  BrowserCacheLocation,
  IPublicClientApplication,
  InteractionType,
  LogLevel,
  PublicClientApplication,
} from '@azure/msal-browser';
import { environment } from '../environments/environment';

const httpInterceptorProviders: Provider[] = [
  { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
];

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.msalConfig.clientId,
      authority: environment.msalConfig.instance,
      redirectUri: environment.msalConfig.redirectUri ?? '/',
      postLogoutRedirectUri:
        environment.msalConfig.postLogoutRedirectUri ?? '/',
      navigateToLoginRequestUrl: true,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: false,
    },
    system: {
      loggerOptions: {
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false,
        loggerCallback: environment.msalLoggingEnabled
          ? (
              logLevel: LogLevel,
              message: string,
              containsPii: boolean,
            ): void => {
              if (containsPii) {
                return;
              }

              switch (logLevel) {
                case LogLevel.Error:
                  // eslint-disable-next-line no-console
                  console.error(message);
                  return;
                case LogLevel.Info:
                  // eslint-disable-next-line no-console
                  console.info(message);
                  return;
                case LogLevel.Verbose:
                  // eslint-disable-next-line no-console
                  console.debug(message);
                  return;
                case LogLevel.Warning:
                  // eslint-disable-next-line no-console
                  console.warn(message);
                  return;
                default:
                  // eslint-disable-next-line no-console
                  console.log(message);
              }
            }
          : undefined,
      },
    },
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, string[]>();
  protectedResourceMap.set(`${environment.apiEndpoint}`, [
    `api://${environment.msalConfig.apiEndpointId}/read_as_user`,
  ]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    authRequest: {
      scopes: ['user.read'],
    },
    interactionType: InteractionType.Redirect,
  };
}

export function scrollFactory(overlay: Overlay): () => CloseScrollStrategy {
  return (): CloseScrollStrategy => overlay.scrollStrategies.close();
}

export const APP_PROVIDERS: Provider[] = [
  {
    provide: ErrorHandler,
    useClass: GlobalErrorHandlerService,
  },
  {
    provide: MSAL_INSTANCE,
    useFactory: MSALInstanceFactory,
  },
  {
    provide: MSAL_GUARD_CONFIG,
    useFactory: MSALGuardConfigFactory,
  },
  {
    provide: MSAL_INTERCEPTOR_CONFIG,
    useFactory: MSALInterceptorConfigFactory,
  },
  MsalService,
  MsalBroadcastService,
  MsalGuard,
  {
    provide: MAT_DIALOG_DEFAULT_OPTIONS,
    useValue: {
      width: DEFAULT_DIALOG_WIDTH,
      disableClose: true,
      autoFocus: false,
      hasBackdrop: true,
    },
  },
  {
    provide: LOCALE_ID,
    useFactory: (localeService: LocaleService) => localeService.getLocale(),
    deps: [LocaleService],
  },
  {
    provide: MAT_DATEPICKER_SCROLL_STRATEGY,
    deps: [Overlay],
    useFactory: scrollFactory,
  },
  {
    provide: MAT_SELECT_SCROLL_STRATEGY,
    deps: [Overlay],
    useFactory: scrollFactory,
  },
  {
    provide: ENVIRONMENT_CONFIG,
    useValue: environment,
  },
  {
    provide: ENVIRONMENT_INFO,
    useValue: {
      id: environment.title.toLowerCase(),
      title: environment.title,
      version: APP_VERSION,
      buildNumber: BUILD_NUMBER,
    },
  },
  {
    provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
    useValue: {
      disableTooltipInteractivity: true,
    },
  },
  {
    provide: MAT_DIALOG_SCROLL_STRATEGY,
    useFactory: (options: ScrollStrategyOptions) => options.block,
    deps: [ScrollStrategyOptions],
  },
  {
    provide: DateAdapter,
    useClass: ProcureDateAdapter,
  },
  {
    provide: MAT_TABS_CONFIG,
    useValue: {
      animationDuration: '350ms',
      stretchTabs: false,
    },
  },
  {
    provide: TitleStrategy,
    useClass: ProcureTitleStrategy,
  },
  {
    provide: MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS,
    useValue: {
      hideSingleSelectionIndicator: true,
      hideMultipleSelectionIndicator: true,
    },
  },
  ...httpInterceptorProviders,
];
