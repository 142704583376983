import { PaginatedResponseModel } from '@abb-procure/data';
import { NotificationInboxViewResponse } from '../types/notification-inbox-view-response.type';
import { NotificationInboxMessageModel } from './notification-inbox-message.model';

export class NotificationInboxViewResponseModel
  extends PaginatedResponseModel
  implements NotificationInboxViewResponse {
  items: NotificationInboxMessageModel[] | null = null;
  readonly filteredCount: number;
  readonly unreadCount: number;

  constructor(notificationInboxViewResponse: NotificationInboxViewResponse) {
    super(notificationInboxViewResponse);

    this.items = notificationInboxViewResponse.items
      ? notificationInboxViewResponse.items.map((message) => new NotificationInboxMessageModel(message))
      : null;

    this.filteredCount = notificationInboxViewResponse.filteredCount;
    this.unreadCount = notificationInboxViewResponse.unreadCount;
  }
}
