import { nullable } from '@abb-procure/api';
import * as t from 'io-ts';

const PaginatedResponse = t.type(
  {
    totalCount: t.number,
    pageIndex: t.number,
    pageSize: t.number,
    orderBy: nullable(t.string),
    orderIsAscending: t.boolean,
  },
  'PaginatedResponse',
);

type PaginatedResponse = t.TypeOf<typeof PaginatedResponse>;
export { PaginatedResponse };
