import {
  CustomHttpErrorResponse,
  IoTsTypeError,
} from '@abb-procure/error-handling';
import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpEventType,
  HttpHeaders,
  HttpResponse,
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Either, isRight } from 'fp-ts/Either';
import { ArrayC, Errors, IntersectionC, Type, TypeC, UnionC } from 'io-ts';
import { PathReporter } from 'io-ts/PathReporter';
import { Observable, lastValueFrom, throwError } from 'rxjs';
import { catchError, filter, map, take } from 'rxjs/operators';
import { CustomHttpParams } from '../helpers/custom-http-params';
import { EnvironmentState } from '../state/environment.state';
import { FileResponse } from './api.service';

/* eslint-disable @typescript-eslint/no-explicit-any */
type IoTsTypes =
  | Type<any>
  | TypeC<any>
  | ArrayC<any>
  | IntersectionC<any>
  | UnionC<any>;
/* eslint-enable @typescript-eslint/no-explicit-any */

export const formatStatus = (
  statusCode: number,
  statusText: string,
): string => {
  const code = statusCode > 0 ? `${statusCode} ` : '';
  return `${code}${statusText}`;
};

/**
 * HTTP defines a set of request methods to indicate the desired action to be performed for a given resource.
 */
export type HttpRequestMethod =
  | 'GET'
  | 'HEAD'
  | 'POST'
  | 'PUT'
  | 'DELETE'
  | 'CONNECT'
  | 'OPTIONS'
  | 'TRACE'
  | 'PATCH';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getErrorMessages = (error: any): string[] => {
  if (!error?.error) {
    return [];
  }

  const allErrors = [];
  const errorResponse = error.error;

  if (typeof errorResponse === 'string') {
    allErrors.push(errorResponse);
  } else if (typeof errorResponse === 'object') {
    if ('statusCode' in errorResponse && 'message' in errorResponse) {
      allErrors.push(errorResponse.message);
    } else {
      // eslint-disable-next-line no-restricted-syntax
      for (const errorProperty in errorResponse) {
        // eslint-disable-next-line max-depth
        if (
          Object.prototype.hasOwnProperty.call(errorResponse, errorProperty) &&
          Array.isArray(errorResponse[errorProperty])
        ) {
          allErrors.push(...errorResponse[errorProperty]);
        }
      }
    }
  }

  return allErrors;
};

export const catchHttpError = (error: HttpErrorResponse): Observable<never> => {
  let statusCode: number = error.status;
  const { statusText } = error;

  if (
    error.error &&
    typeof error.error === 'object' &&
    error.error.statusCode
  ) {
    statusCode = error.error.statusCode;
  }

  const customStatusText = formatStatus(statusCode, statusText);
  const errorMessage = getErrorMessages(error).join(' \n');

  return throwError(
    () =>
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      new CustomHttpErrorResponse(customStatusText, errorMessage, error as any),
  );
};

@Injectable({
  providedIn: 'root',
})
export abstract class HttpApi {
  private httpClient = inject(HttpClient);
  private readonly environmentState = inject(EnvironmentState);

  get$<Response>(
    pathName: string,
    ioTsType: IoTsTypes,
    queryParameters?: CustomHttpParams,
    headers?: HttpHeaders,
  ): Observable<Response | null> {
    const request$ = this.request$<Response>(
      'GET',
      pathName,
      null,
      queryParameters,
      headers,
    );

    return this.getHttpResponseBody$<Response>(request$, ioTsType);
  }

  async get<Response>(
    pathName: string,
    ioTsType: IoTsTypes,
    queryParameters?: CustomHttpParams,
    headers?: HttpHeaders,
  ): Promise<Response | null> {
    return lastValueFrom(
      this.get$<Response>(pathName, ioTsType, queryParameters, headers),
    );
  }

  /**
   * Constructs a POST request.
   */
  post$<Response, Request>(
    pathName: string,
    ioTsType: IoTsTypes,
    body?: Request,
    queryParameters?: CustomHttpParams,
    headers?: HttpHeaders,
  ): Observable<Response | null> {
    const request$ = this.request$<Response>(
      'POST',
      pathName,
      body,
      queryParameters,
      headers,
    );

    return this.getHttpResponseBody$<Response>(request$, ioTsType);
  }

  post<Response, Request>(
    pathName: string,
    ioTsType: IoTsTypes,
    body?: Request,
    queryParameters?: CustomHttpParams,
    headers?: HttpHeaders,
  ): Promise<Response | null> {
    return lastValueFrom(
      this.post$<Response, Request>(
        pathName,
        ioTsType,
        body,
        queryParameters,
        headers,
      ),
    );
  }

  /**
   * Constructs a PUT request
   */
  put$<Response, Request>(
    pathName: string,
    ioTsType: IoTsTypes,
    body?: Request,
    queryParameters?: CustomHttpParams,
    headers?: HttpHeaders,
  ): Observable<Response | null> {
    const request$ = this.request$<Response>(
      'PUT',
      pathName,
      body,
      queryParameters,
      headers,
    );

    return this.getHttpResponseBody$<Response>(request$, ioTsType);
  }

  put<Response, Request>(
    pathName: string,
    ioTsType: IoTsTypes,
    body?: Request,
    queryParameters?: CustomHttpParams,
    headers?: HttpHeaders,
  ): Promise<Response | null> {
    return lastValueFrom(
      this.put$<Response, Request>(
        pathName,
        ioTsType,
        body,
        queryParameters,
        headers,
      ),
    );
  }

  /**
   * Constructs a DELETE request
   */
  delete$<Response, Request>(
    pathName: string,
    ioTsType: IoTsTypes,
    body?: Request,
    queryParameters?: CustomHttpParams,
    headers?: HttpHeaders,
  ): Observable<Response | null> {
    const request$ = this.request$<Response>(
      'DELETE',
      pathName,
      body,
      queryParameters,
      headers,
    );

    return this.getHttpResponseBody$<Response>(request$, ioTsType);
  }

  delete<Response, Request>(
    pathName: string,
    ioTsType: IoTsTypes,
    body?: Request,
    queryParameters?: CustomHttpParams,
    headers?: HttpHeaders,
  ): Promise<Response | null> {
    return lastValueFrom(
      this.delete$<Response, Request>(
        pathName,
        ioTsType,
        body,
        queryParameters,
        headers,
      ),
    );
  }

  /**
   * Fetches details for a blob download.
   */
  downloadBlob$(
    pathName: string,
    queryParameters?: CustomHttpParams,
    headers?: HttpHeaders,
    fullUrl?: boolean,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    body?: any,
  ): Observable<FileResponse> {
    const params = queryParameters ? queryParameters.toHttpParams() : {};

    const endpointUrl = fullUrl ? pathName : this.getEndpointUrl(pathName);

    if (body) {
      return this.httpClient
        .put(endpointUrl, body, {
          responseType: 'blob',
          headers,
          params,
          observe: 'response',
        })
        .pipe(catchError(catchHttpError));
    }

    return this.httpClient
      .get(endpointUrl, {
        responseType: 'blob',
        headers,
        params,
        observe: 'response',
      })
      .pipe(catchError(catchHttpError));
  }

  async downloadBlob(
    pathName: string,
    queryParameters?: CustomHttpParams,
    headers?: HttpHeaders,
    fullUrl?: boolean,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    body?: any,
  ): Promise<FileResponse> {
    return lastValueFrom(
      this.downloadBlob$(pathName, queryParameters, headers, fullUrl, body),
    );
  }

  /**
   * Returns the response body of a HttpEvent sequence.
   */
  private getHttpResponseBody$<Response>(
    observable$: Observable<HttpEvent<Response>>,
    ioTsType?: IoTsTypes,
  ): Observable<Response | null> {
    return observable$.pipe(
      filter(
        (event): event is HttpResponse<Response> =>
          event.type === HttpEventType.Response,
      ),
      take(1),
      map((response) => response.body),
      map((response) => {
        if (ioTsType) {
          const res = ioTsType.decode(response);

          if (this.hasErrorByRuntime(res)) {
            return null;
          }

          if (isRight(res)) {
            return res.right;
          }
        }

        return response;
      }),
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private hasErrorByRuntime(decodeRes: Either<Errors, any>): boolean {
    const errors = PathReporter.report(decodeRes);

    if (Array.isArray(errors)) {
      if (errors[0] === 'No errors!') {
        return false;
      }

      let parsedErrors: string[] = [];

      errors.forEach((error) => {
        const components = error.split('/');
        const key = components.join(' => ');

        if (!this.environmentState.isProduction()) {
          // eslint-disable-next-line no-console
          console.error(key);
        }

        parsedErrors = [...parsedErrors, key];
      });

      throw new IoTsTypeError('io-ts type error', parsedErrors.join('\n'));
    }

    return true;
  }

  /**
   * Constructs a HTTP request.
   */
  private request$<Response>(
    method: HttpRequestMethod,
    url: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    body?: any,
    queryParameters?: CustomHttpParams,
    headers?: HttpHeaders,
  ): Observable<HttpEvent<Response>> {
    const params = queryParameters ? queryParameters.toHttpParams() : {};

    const request$ = this.httpClient.request<Response>(
      method,
      this.getEndpointUrl(url),
      {
        body,
        headers,
        reportProgress: true,
        observe: 'events',
        params,
      },
    );

    return request$.pipe(catchError(catchHttpError));
  }

  /**
   * Builds the final URL depending on the url input
   *
   * @param url string which is used in the final url
   */
  protected abstract getEndpointUrl(url: string): string;
}
