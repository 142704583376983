@if (link()) {
  @if (!openInNewTab()) {
    <a
      data-testid="badge-with-internal-link"
      [routerLink]="link()"
      [queryParams]="{ view: view() }"
      [class]="class()"
      [id]="id()"
      [style.background-color]="backgroundColor() || null"
    >
      {{ label() }}
    </a>
  } @else {
    <a
      data-testid="badge-with-external-link"
      target="_blank"
      rel="noopener noreferrer"
      [href]="link()"
      [class]="class()"
      [id]="id()"
      [style.background-color]="backgroundColor() || null"
    >
      {{ label() }}
    </a>
  }
} @else {
  <div
    data-testid="badge-without-link"
    [class]="class()"
    [id]="id()"
    [style.background-color]="backgroundColor() || null"
  >
    {{ label() }}
  </div>
}
