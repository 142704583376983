import { UserPermissions } from '../types/user-permissions.type';

export class UserPermissionsModel implements UserPermissions {
  readonly canManageUsers: boolean;
  readonly canManageOrganization: boolean;
  readonly canManageSuppliers: boolean;
  readonly canManageNotifications: boolean;
  readonly canManageResources: boolean;
  readonly canManageTemplates: boolean;
  readonly canAddRequestForRequestForQuotation: boolean;
  readonly canAddPurchaseRequisition: boolean;
  readonly canAddProject: boolean;
  readonly canAddRequestForRequestForQutoationFromTemplate: boolean;
  readonly canViewAdminView: boolean;
  readonly canViewOrganisation: boolean;
  readonly canManageCategories: boolean;

  constructor(data: UserPermissions) {
    this.canManageUsers = data.canManageUsers;
    this.canManageOrganization = data.canManageOrganization;
    this.canManageSuppliers = data.canManageSuppliers;
    this.canManageNotifications = data.canManageNotifications;
    this.canManageResources = data.canManageResources;
    this.canManageTemplates = data.canManageTemplates;

    this.canAddRequestForRequestForQuotation =
      data.canAddRequestForRequestForQuotation;

    this.canAddPurchaseRequisition = data.canAddPurchaseRequisition;
    this.canAddProject = data.canAddProject;

    this.canAddRequestForRequestForQutoationFromTemplate =
      data.canAddRequestForRequestForQutoationFromTemplate;

    this.canViewAdminView = data.canViewAdminView;
    this.canViewOrganisation = data.canViewOrganisation;
    this.canManageCategories = data.canManageCategories;
  }
}
