import { BaseModel } from '@abb-procure/data';
import { PurchaseRequisitionSearchIndexDefinitionSearchDocumentResult } from '../types/purchase-requisition-search-index-definition-search-document-result.type';
import { PurchaseRequisitionSearchIndexDefinitionSearchDocumentItemResultModel } from './purchase-requisition-search-index-definition-search-document-item-result.model';

export class PurchaseRequisitionSearchIndexDefinitionSearchDocumentResultModel extends BaseModel {
  readonly count: number | null;
  readonly values: PurchaseRequisitionSearchIndexDefinitionSearchDocumentItemResultModel[];

  constructor(
    result: PurchaseRequisitionSearchIndexDefinitionSearchDocumentResult,
  ) {
    super();

    this.count = result.count;

    this.values = result.values
      .map((value) => new PurchaseRequisitionSearchIndexDefinitionSearchDocumentItemResultModel(value));
  }
}
