@if (!initializationInProgress) {
  <header
    procure-app-header
    [isEnvironmentBannerEnabled]="isEnvironmentBannerEnabled"
  ></header>
  <main
    #main
    class="app__main"
  >
    <nav procure-app-navigation></nav>
    <div class="app__content">
      @if (!isIframe) {
        <router-outlet />
      }
    </div>
  </main>
  <procure-loading-indicator
    delay="long"
    [active]="isLoading"
  />
  <procure-app-notifications />
} @else {
  <div
    class="loader"
    [@simpleFadeAnimation]="'in'"
  >
    <img
      class="loader__img"
      src="./assets/images/logo-procure-flat.svg"
      alt="Procure App Logo"
      [@contentFadeAnimation]
    />
  </div>
}
