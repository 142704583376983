import { nullable } from '@abb-procure/api';
import * as t from 'io-ts';

const MdfCode = t.type(
  {
    id: t.number,
    name: nullable(t.string),
    code: nullable(t.string),
    count: t.union([t.number, t.undefined]),
  },
  'MdfCode',
);

type MdfCode = t.TypeOf<typeof MdfCode>;
export { MdfCode };
