import { DialogType } from '@abb-procure/constants';
import { AdditionalInfoModel, LookupDataState } from '@abb-procure/data';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  OnInit,
  ViewEncapsulation,
  inject,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Observable, of } from 'rxjs';
import { take } from 'rxjs/operators';
import { FormRowDirective } from '../../directives/form-row.directive';
import { escapeDialog } from '../../utilities/dialog.util';
import { ButtonComponent } from '../button/button.component';
import { CardComponent } from '../card/card.component';
import { ExpansionPanelComponent } from '../expansion-panel/expansion-panel.component';
import { ShowFieldComponent } from '../show-field/show-field.component';
import { TagListComponent } from '../tag-list/tag-list.component';
import { TagComponent } from '../tag/tag.component';

export interface DialogData {
  readonly type: DialogType;
  readonly title: string;
  readonly text: string;
  readonly base?: boolean;
  readonly info?: string;
  readonly blackout?: boolean;
  readonly primaryAction?: string;
  readonly secondaryAction?: string;
  readonly additionalInfo?: AdditionalInfoModel | null;
  readonly closeCallback?: () => void;
  readonly confirmationCallback?: () => Observable<boolean | void>;
}

@Component({
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatDialogModule,
    MatIconModule,
    ButtonComponent,
    CardComponent,
    ShowFieldComponent,
    ExpansionPanelComponent,
    TagComponent,
    TagListComponent,
    FormRowDirective,
    MatTooltipModule,
  ],
  encapsulation: ViewEncapsulation.None,
})
export class DialogComponent implements OnInit {
  readonly isConfirming = signal(false);
  readonly dialogType: typeof DialogType = DialogType;

  protected readonly data: DialogData = inject(MAT_DIALOG_DATA);
  protected readonly lookupDataState = inject(LookupDataState);

  private readonly dialogRef = inject(MatDialogRef);
  private readonly destroyRef = inject(DestroyRef);

  constructor() {
    if (this.data.blackout) {
      this.dialogRef.disableClose = true;
    }
  }

  /** @inheritdoc */
  ngOnInit(): void {
    if (this.data.blackout) {
      return;
    }

    escapeDialog(this.dialogRef, this.destroyRef, () => {
      if (this.data.type !== DialogType.PROMPT) {
        this.data.closeCallback?.();
      }

      this.dialogRef.close(this.data.type !== DialogType.PROMPT);
    });
  }

  /**
   * Confirm prompt dialogs and execute the confirmation callback. After
   * execution, the dialog is closed.
   */
  confirm(): void {
    this.isConfirming.set(true);

    this.getConfirmCallback$()
      .pipe(take(1))
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((successful?: boolean) => {
        this.isConfirming.set(false);
        this.dialogRef.close(successful !== false);
      });
  }

  close(): void {
    if (this.data.blackout) {
      return;
    }

    this.data.closeCallback?.();
  }

  private getConfirmCallback$(): Observable<boolean | void> {
    if (this.data.confirmationCallback != null) {
      return this.data.confirmationCallback();
    }

    // Default callback
    return of(true);
  }
}
