import { DOCUMENT } from '@angular/common';
import { InjectionToken, inject } from '@angular/core';
import { EnvironmentConfig, EnvironmentInfo } from './environment';

export const WINDOW = new InjectionToken<Window>(
  'An abstraction over the global window object',
  {
    factory: (): Window => {
      const { defaultView } = inject(DOCUMENT);

      if (!defaultView) {
        throw new Error('Window is not available');
      }

      return defaultView;
    },
  },
);

export const ENVIRONMENT_INFO = new InjectionToken<EnvironmentInfo>('ENVIRONMENT_INFO');
export const ENVIRONMENT_CONFIG = new InjectionToken<EnvironmentConfig>('ENVIRONMENT_CONFIG');
