import { User } from '../types/user.type';
import { BaseModel } from './base.model';

export class UserModel extends BaseModel implements User {
  readonly id: number;
  readonly email: string | null;
  readonly name: string | null;
  readonly isActiveInEntraID: boolean;
  readonly isEnabled: boolean;
  readonly avatarUrl: string | null;

  constructor(user: User) {
    super();

    this.id = user.id;
    this.email = user.email;
    this.name = user.name;
    this.isActiveInEntraID = user.isActiveInEntraID;
    this.isEnabled = user.isEnabled;
    this.avatarUrl = user.avatarUrl;
  }

  getDisplay(): string {
    return `${this.name}`;
  }
}
