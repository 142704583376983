import { ResourceVariables } from '../../../types/management/resources/resource-variables.type';
import { BaseModel } from '../../base.model';

export class ResourceVariablesModel
  extends BaseModel
  implements ResourceVariables
{
  readonly id: number;
  readonly uniqueCode: string;
  readonly value: string;
  readonly name: string | null;

  constructor(data: ResourceVariables) {
    super();

    this.id = data.id;
    this.uniqueCode = data.uniqueCode;
    this.value = data.value;
    this.name = data.name;
  }
}
