import { Injectable, inject } from '@angular/core';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { TitleService } from '../services/title.service';

@Injectable({
  providedIn: 'root',
})
export class ProcureTitleStrategy extends TitleStrategy {
  private readonly titleService = inject(TitleService);

  override updateTitle(snapshot: RouterStateSnapshot): void {
    const title = this.buildTitle(snapshot);

    if (!title) {
      return;
    }

    this.titleService.setTitle(title);
  }
}
