import { ResourceResponse } from '../types/resource-response.type';
import { ResourceLinkSlimModel } from './resource-link-slim.model';

export class ResourceResponseModel implements ResourceResponse {
  readonly resourceLinks: ResourceLinkSlimModel[] | null = null;

  constructor(data: ResourceResponse) {
    if (data.resourceLinks) {
      this.resourceLinks = data.resourceLinks.map(
        (item) => new ResourceLinkSlimModel(item),
      );
    }
  }
}
