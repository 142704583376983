import {
  Component, DestroyRef, EventEmitter, Input, Output, ViewEncapsulation, booleanAttribute, inject,
} from '@angular/core';
import {
  trigger, style, transition, animate,
} from '@angular/animations';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ApplicationNotificationModel, NotificationApiService } from '@abb-procure/data';
import { MatIconModule } from '@angular/material/icon';
import { ButtonComponent } from '../button/button.component';

@Component({
  selector: 'procure-global-notification',
  templateUrl: './global-notification.component.html',
  styleUrls: ['./global-notification.component.scss'],
  standalone: true,
  imports: [
    MatIconModule,
    ButtonComponent,
  ],
  animations: [
    trigger('position', [
      transition(':enter', [
        style({ transform: 'translateY(100%)' }),
        animate(200, style({ transform: 'translateY(0)' })),
      ]),
      transition(':leave', [animate(200, style({ transform: 'translateY(100%)' }))]),
    ]),
  ],
  encapsulation: ViewEncapsulation.None,
})
export class GlobalNotificationComponent {
  @Input() notification?: ApplicationNotificationModel | null = null;
  @Input({ transform: booleanAttribute }) preview: boolean = false;

  @Output() readonly acceptNotification = new EventEmitter<void>();

  private readonly notificationApiService = inject(NotificationApiService);
  private readonly destroyRef = inject(DestroyRef);

  onAccept(): void {
    if (!this.notification) {
      return;
    }

    if (this.preview) {
      this.acceptNotification.emit();
      return;
    }

    this.notificationApiService.confirmNotification$(this.notification.notificationId)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((response) => {
        if (!response || !this.notification) {
          return;
        }

        this.notification.display = false;
      });
  }
}
