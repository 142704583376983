/* eslint-disable @typescript-eslint/no-explicit-any */

import * as t from 'io-ts';

export const nullable = <T>(
  type: t.Type<T, any, any>,
): t.UnionC<[t.Type<T, any, any>, t.NullC]> => t.union([type, t.null]);

export const optional = <T>(
  type: t.Type<T, any, any>,
): t.UnionC<[t.Type<T, any, any>, t.UndefinedC]> => t.union([type, t.undefined]);
