import { nullable } from '@abb-procure/api';
import * as t from 'io-ts';

const ResourceVariables = t.type(
  {
    id: t.number,
    uniqueCode: t.string,
    value: t.string,
    name: nullable(t.string),
  },
  'ResourceVariables',
);

type ResourceVariables = t.TypeOf<typeof ResourceVariables>;
export { ResourceVariables };
