import { ApiService, CustomHttpParams } from '@abb-procure/api';
import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { string as ioString } from 'io-ts';
import { Observable } from 'rxjs';
import { StorageUploadSettingsModel } from '../models/storage-upload-settings.model';
import { StorageUploadSettings } from '../types/storage-upload-settings.type';

@Injectable({
  providedIn: 'root',
})
export class DocumentsApiService extends ApiService {
  getDownloadUrl$(documentId: string): Observable<string | null> {
    return this.get$<string>(`Document/${documentId}/Download`, ioString).pipe(
      this.catchError$(
        'There was an error retrieving the document download URL.',
      ),
      takeUntilDestroyed(this.destroyRef),
    );
  }

  async getDownloadUrl(documentId: string): Promise<string | null> {
    try {
      const response = await this.get<string>(
        `Document/${documentId}/Download`,
        ioString,
      );

      return response;
    } catch (error) {
      return this.catchError(
        error,
        'There was an error retrieving the document download URL.',
      );
    }
  }

  getUploadSettings$(): Observable<StorageUploadSettingsModel | null> {
    return this.get$<StorageUploadSettings>(
      'Document/Settings',
      StorageUploadSettings,
    ).pipe(
      this.catchError$('There was an error retrieving upload settings.'),
      takeUntilDestroyed(this.destroyRef),
    );
  }

  downloadAll$(
    entityTypeString: string,
    entityId: string,
    fieldName: string,
    suffix: string,
  ): Observable<string | null> {
    const queryParams = new CustomHttpParams({
      filenameSuffix: suffix,
    });

    return this.get$<string>(
      `Document/${entityTypeString}/${entityId}/${fieldName}/Download`,
      ioString,
      queryParams,
    ).pipe(
      this.catchError$('There was an issue downloading the documents.'),
      takeUntilDestroyed(this.destroyRef),
    );
  }

  getResourceDownloadUrl$(resourceId: number): Observable<string | null> {
    return this.get$<string>(`Document/Resource/${resourceId}`, ioString).pipe(
      this.catchError$(
        'There was an error retrieving the resource download URL.',
      ),
      takeUntilDestroyed(this.destroyRef),
    );
  }
}
