import { BaseModel, UserSlimModel } from '@abb-procure/data';
import { NotificationInboxMessage } from '../types/notification-inbox-message.type';

export class NotificationInboxMessageModel extends BaseModel implements NotificationInboxMessage {
  readonly id: string | null;
  readonly title: string | null;
  readonly message: string | null;
  readonly data: Record<string, string | null>;
  readonly createdOn: Date;
  readonly sender: UserSlimModel | null = null;
  isRead: boolean;
  isArchived: boolean;

  constructor(notificationInboxMessage: NotificationInboxMessage) {
    super();

    this.id = notificationInboxMessage.id;
    this.title = notificationInboxMessage.title;
    this.message = notificationInboxMessage.message;
    this.data = notificationInboxMessage.data;
    this.createdOn = notificationInboxMessage.createdOn;

    if (notificationInboxMessage.sender) {
      this.sender = new UserSlimModel(notificationInboxMessage.sender);
    }

    this.isRead = notificationInboxMessage.isRead;
    this.isArchived = notificationInboxMessage.isArchived;
  }
}
