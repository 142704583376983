import { EntityBadgeComponent } from '@abb-procure/common';
import { EntityIdAliasPrefixes } from '@abb-procure/constants';
import { UserState } from '@abb-procure/data';
import { TemplateFinderDialogService } from '@abb-procure/template-finder';
import { Component, ViewChild, inject } from '@angular/core';
import { MatMenu, MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';

@Component({
  selector: 'procure-create-menu',
  templateUrl: './create-menu.component.html',
  styleUrl: './create-menu.component.scss',
  standalone: true,
  imports: [MatMenuModule, EntityBadgeComponent, MatTooltipModule],
})
export class CreateMenuComponent {
  @ViewChild(MatMenu, { static: true }) menu!: MatMenu;

  protected readonly userState = inject(UserState);
  protected readonly EntityIdAliasPrefixes = EntityIdAliasPrefixes;

  private readonly router = inject(Router);

  private readonly templateFinderDialogService = inject(
    TemplateFinderDialogService,
  );

  protected addProject(): void {
    this.router
      .navigate(['/'], { skipLocationChange: true })
      .then(() => this.router.navigate(['/PROJ/add']));
  }

  protected addRequestForRfq(): void {
    this.router
      .navigate(['/'], { skipLocationChange: true })
      .then(() => this.router.navigate(['/RFRFQ/add']));
  }

  protected showTemplateFinder(): void {
    this.templateFinderDialogService.openTemplateFinderDialog();
  }
}
