<div
  class="snackbar"
  data-testid="Snackbar"
  >
  @if (data.type) {
    <div [attr.class]="'snackbar__icon-container snackbar__icon-container--' + icon">
      <mat-icon
        class="snackbar__icon"
        data-testid="icon"
      >
        {{ icon }}
      </mat-icon>
    </div>
  }
  <div class="snackbar__content">
    @if (data.title) {
      <div class="snackbar__title">
        {{ data.title }}
      </div>
    }
    @if (data.text) {
      <div class="snackbar__text">
        {{ data.text }}
      </div>
    }
  </div>
</div>
