import { nullable } from '@abb-procure/api';
import * as t from 'io-ts';
import { ResourceLinkSlim } from './resource-link-slim.type';

const ResourceResponse = t.type(
  {
    resourceLinks: nullable(t.array(ResourceLinkSlim)),
  },
  'ResourceResponse',
);

type ResourceResponse = t.TypeOf<typeof ResourceResponse>;
export { ResourceResponse };
