@if (notification?.display) {
  <div
    class="global-notification"
    [@position]="'in'"
  >
    <div class="global-notification__content">
      <mat-icon class="global-notification__icon">info</mat-icon>
      <div
        class="global-notification__text"
        [innerHTML]="notification?.value"
      ></div>
      <div class="global-notification__buttons">
        <button
          procure-button
          class="global-notification__button"
          data-testid="accept-button"
          (click)="onAccept()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
  </div>
}
