import { nullable } from '@abb-procure/api';
import * as t from 'io-ts';
import { SupplierSearchIndexDefinition } from './supplier-search-index-definition.type';

const SupplierSearchIndexDefinitionSearchDocumentItemResult = t.type({
  value: SupplierSearchIndexDefinition,
  highlights: nullable(t.record(t.string, t.array(t.string))),
  searchScore: nullable(t.number),
}, 'SupplierSearchIndexDefinitionSearchDocumentItemResult');

type SupplierSearchIndexDefinitionSearchDocumentItemResult = t.TypeOf<
  typeof SupplierSearchIndexDefinitionSearchDocumentItemResult
>;
export { SupplierSearchIndexDefinitionSearchDocumentItemResult };
