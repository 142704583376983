import { Component, inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { SnackbarType } from '../../enums/snackbar-type.enum';
import { SnackbarData } from '../../models/snackbar-data.model';

@Component({
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss'],
  standalone: true,
  imports: [MatIconModule],
})
export class SnackbarComponent {
  get icon(): string | undefined {
    switch (this.data.type) {
      case SnackbarType.INFO:
        return 'info';
      case SnackbarType.ERROR:
        return 'error';
      case SnackbarType.SUCCESS:
        return 'check_circle';
      default:
        return undefined;
    }
  }

  protected readonly data: SnackbarData = inject(MAT_SNACK_BAR_DATA);
}
