import { PurchaseRequisitionSearchIndexDefinition } from '../types/purchase-requisition-search-index-definition.type';

export class PurchaseRequisitionSearchIndexDefinitionModel {
  readonly id: string | null;
  readonly projectID: string | null;
  readonly isClosed: boolean;
  readonly entityNumber: number;
  readonly creator: string | null;
  readonly statusUpdater: string | null;
  readonly purchaser: string | null;
  readonly requester: string | null;
  readonly statusName: string | null;
  readonly poNumber: string | null;
  readonly projectName: string | null;
  readonly sapCostAccount: string | null;
  readonly sapSdPosition: string | null;
  readonly sapCostCenter: string | null;
  readonly sapCostElement: string | null;
  readonly sapProjectNumber: string | null;
  readonly sapPspElement: string | null;
  readonly poDocument: string | null;
  readonly poComment: string | null;
  readonly requiredScope: string | null;
  readonly supplierInvoicingInstructions: string | null;
  readonly deliveryDestinationRelatedComments: string | null;
  readonly specialTermsAndConditionsAndAnnexes: string | null;
  readonly attachmentsToPO: string | null;
  readonly supplier: string | null;
  readonly supplierContact: string | null;
  readonly supplierContactEmail: string | null;
  readonly deliveryDestination: string | null;
  readonly comment: string | null;
  readonly internalUseComment: string | null;
  readonly endUseCountry: string | null;
  readonly endUseCountryCode: string | null;
  readonly requiredSubmittalsToBeReturned: string | null;
  readonly returnedSubmittals: string | null;
  readonly projectLUI: string | null;
  readonly number: string | null;
  readonly projectCountry: string | null;
  readonly projectCountryCode: string | null;
  readonly costAllocation: string | null;
  readonly businessUnit: string | null;
  readonly mdfCodeName: string | null;
  readonly mdfCode: string | null;
  readonly goodsCountryOfOrigin: string | null;
  readonly goodsCountryOfOriginCode: string | null;
  readonly forFurtherDeliveryTo: string | null;
  readonly deliveryAddressName: string | null;
  readonly deliveryAddressLine: string | null;
  readonly deliveryAddressCity: string | null;
  readonly deliveryAddressCountry: string | null;
  readonly deliveryAddressCountryCode: string | null;
  readonly incotermsComments: string | null;
  readonly personAndDepartment: string | null;
  readonly supplierSuggestions: string | null;
  readonly additionalEditors: string | null;
  readonly projectSCM: string | null;

  // eslint-disable-next-line max-lines-per-function
  constructor(definition: PurchaseRequisitionSearchIndexDefinition) {
    this.id = definition.id;
    this.projectID = definition.projectID;
    this.isClosed = definition.isClosed;
    this.entityNumber = definition.entityNumber;
    this.creator = definition.creator;
    this.statusUpdater = definition.statusUpdater;
    this.purchaser = definition.purchaser;
    this.requester = definition.requester;
    this.statusName = definition.statusName;
    this.poNumber = definition.poNumber;
    this.projectName = definition.projectName;
    this.sapCostAccount = definition.sapCostAccount;
    this.sapSdPosition = definition.sapSdPosition;
    this.sapCostCenter = definition.sapCostCenter;
    this.sapCostElement = definition.sapCostElement;
    this.sapProjectNumber = definition.sapProjectNumber;
    this.sapPspElement = definition.sapPspElement;
    this.poDocument = definition.poDocument;
    this.poComment = definition.poComment;
    this.requiredScope = definition.requiredScope;

    this.supplierInvoicingInstructions =
      definition.supplierInvoicingInstructions;

    this.deliveryDestinationRelatedComments =
      definition.deliveryDestinationRelatedComments;

    this.specialTermsAndConditionsAndAnnexes =
      definition.specialTermsAndConditionsAndAnnexes;

    this.attachmentsToPO = definition.attachmentsToPO;
    this.supplier = definition.supplier;
    this.supplierContact = definition.supplierContact;
    this.supplierContactEmail = definition.supplierContactEmail;
    this.deliveryDestination = definition.deliveryDestination;
    this.comment = definition.comment;
    this.internalUseComment = definition.internalUseComment;
    this.endUseCountry = definition.endUseCountry;
    this.endUseCountryCode = definition.endUseCountryCode;

    this.requiredSubmittalsToBeReturned =
      definition.requiredSubmittalsToBeReturned;

    this.returnedSubmittals = definition.returnedSubmittals;
    this.projectLUI = definition.projectLUI;
    this.number = definition.number;
    this.projectCountry = definition.projectCountry;
    this.projectCountryCode = definition.projectCountryCode;
    this.costAllocation = definition.costAllocation;
    this.businessUnit = definition.businessUnit;
    this.mdfCodeName = definition.mdfCodeName;
    this.mdfCode = definition.mdfCode;
    this.goodsCountryOfOrigin = definition.goodsCountryOfOrigin;
    this.goodsCountryOfOriginCode = definition.goodsCountryOfOriginCode;
    this.forFurtherDeliveryTo = definition.forFurtherDeliveryTo;
    this.deliveryAddressName = definition.deliveryAddressName;
    this.deliveryAddressLine = definition.deliveryAddressLine;
    this.deliveryAddressCity = definition.deliveryAddressCity;
    this.deliveryAddressCountry = definition.deliveryAddressCountry;
    this.deliveryAddressCountryCode = definition.deliveryAddressCountryCode;
    this.incotermsComments = definition.incotermsComments;
    this.personAndDepartment = definition.personAndDepartment;
    this.supplierSuggestions = definition.supplierSuggestions;
    this.additionalEditors = definition.additionalEditors;
    this.projectSCM = definition.projectSCM;
  }
}
