import { nullable } from '@abb-procure/api';
import * as t from 'io-ts';

const User = t.type(
  {
    id: t.number,
    email: nullable(t.string),
    name: nullable(t.string),
    isActiveInEntraID: t.boolean,
    isEnabled: t.boolean,
    avatarUrl: nullable(t.string),
  },
  'User',
);

type User = t.TypeOf<typeof User>;
export { User };
