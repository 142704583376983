import { ApiService, CustomHttpParams } from '@abb-procure/api';
import { Injectable } from '@angular/core';
import { boolean as ioBoolean } from 'io-ts';
import { NotificationInboxViewResponseModel } from '../models/notification-inbox-view-response.model';
import { NotificationInboxRequest } from '../types/notification-inbox-request.type';
import { NotificationInboxViewResponse } from '../types/notification-inbox-view-response.type';

@Injectable({
  providedIn: 'root',
})
export class NotificationApiService extends ApiService {
  async getNotifications(
    request: NotificationInboxRequest,
  ): Promise<NotificationInboxViewResponseModel | null> {
    const params = new CustomHttpParams(request);

    try {
      const response = await this.get<NotificationInboxViewResponse>(
        'Notification/Inbox',
        NotificationInboxViewResponse,
        params,
      );

      return response != null
        ? new NotificationInboxViewResponseModel(response)
        : null;
    } catch (error) {
      return this.catchError(
        error,
        'There was an issue while retrieving the notifications.',
      );
    }
  }

  async markAsRead(notificationId: string): Promise<boolean | null> {
    try {
      return await this.put<boolean, null>(
        `Notification/${notificationId}/Read`,
        ioBoolean,
      );
    } catch (error) {
      return this.catchError(
        error,
        'There was an issue while marking the notification as read.',
      );
    }
  }

  async markAllAsRead(): Promise<boolean | null> {
    try {
      return await this.put<boolean, null>('Notification/Read', ioBoolean);
    } catch (error) {
      return this.catchError(
        error,
        'There was an issue while marking all notifications as read.',
      );
    }
  }

  async markAsUnread(notificationId: string): Promise<boolean | null> {
    try {
      return await this.put<boolean, null>(
        `Notification/${notificationId}/Unread`,
        ioBoolean,
      );
    } catch (error) {
      return this.catchError(
        error,
        'There was an issue while marking the notification as unread.',
      );
    }
  }

  async archive(notificationId: string): Promise<boolean | null> {
    try {
      return await this.put<boolean, null>(
        `Notification/${notificationId}/Archive`,
        ioBoolean,
      );
    } catch (error) {
      return this.catchError(
        error,
        'There was an issue while archiving the notification.',
      );
    }
  }

  async unarchive(notificationId: string): Promise<boolean | null> {
    try {
      return await this.put<boolean, null>(
        `Notification/${notificationId}/Unarchive`,
        ioBoolean,
      );
    } catch (error) {
      return this.catchError(
        error,
        'There was an issue while unarchiving the notification.',
      );
    }
  }
}
