import { UserDetails } from '../types/user-details.type';
import { GuidNameModel } from './guid-name.model';
import { OrganisationReviewModel } from './organisation-review.model';
import { UserLocalDivisionModel } from './user-local-division.model';
import { UserModel } from './user.model';

export class UserDetailsModel extends UserModel implements UserDetails {
  readonly firstName: string | null;
  readonly lastName: string | null;
  readonly company: string | null;
  readonly department: string | null;
  readonly telephone: string | null;
  readonly mobile: string | null;
  readonly permissions: GuidNameModel[] | null = null;
  readonly assignments: UserLocalDivisionModel[] | null = null;
  readonly divisionManagers: OrganisationReviewModel[] | null = null;
  readonly hubManagers: OrganisationReviewModel[] | null = null;
  readonly localDivisionManagers: OrganisationReviewModel[] | null = null;

  constructor(userDetails: UserDetails) {
    super(userDetails);

    this.firstName = userDetails.firstName;
    this.lastName = userDetails.lastName;
    this.company = userDetails.company;
    this.department = userDetails.department;
    this.telephone = userDetails.telephone;
    this.mobile = userDetails.mobile;

    if (userDetails.assignments) {
      this.assignments = userDetails.assignments.map(
        (item) => new UserLocalDivisionModel(item),
      );
    }

    if (userDetails.permissions) {
      this.permissions = userDetails.permissions.map(
        (item) => new GuidNameModel(item),
      );
    }

    if (userDetails.divisionManagers) {
      this.divisionManagers = userDetails.divisionManagers.map(
        (item) => new OrganisationReviewModel(item),
      );
    }

    if (userDetails.hubManagers) {
      this.hubManagers = userDetails.hubManagers.map(
        (item) => new OrganisationReviewModel(item),
      );
    }

    if (userDetails.localDivisionManagers) {
      this.localDivisionManagers = userDetails.localDivisionManagers.map(
        (item) => new OrganisationReviewModel(item),
      );
    }
  }
}
