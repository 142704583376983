<button
  procure-button
  variant="ghost"
  color="white"
  animateBadgeChange
  matTooltip="Notifications"
  [badge]="count()"
  [matMenuTriggerFor]="inbox"
>
  <mat-icon>notifications</mat-icon>
</button>
<mat-menu
  #inbox
  class="inbox__panel"
  backdropClass="inbox__backdrop"
>
  <procure-loading-indicator [active]="isLoading()" />
  <div class="inbox__header">
    <div class="inbox__title">Notifications</div>
    <procure-input-switch
      class="inbox__switch"
      labelPosition="before"
      [control]="unreadOnly"
      (click)="$event.stopPropagation()"
    >
      Only show unread
    </procure-input-switch>
    @if (notifications().length) {
      <button
        procure-button
        variant="ghost"
        color="black"
        matTooltip="Mark all as read"
        (click)="markAllAsRead($event)"
      >
        <mat-icon>done_all</mat-icon>
      </button>
    }
    <a
      procure-button
      variant="ghost"
      color="black"
      matTooltip="Show more"
      [routerLink]="['/notifications']"
    >
      <mat-icon>visibility</mat-icon>
    </a>
  </div>
  @if (!notifications().length) {
    <div class="inbox__empty">
      You have no{{ unreadOnly.value ? ' unread ' : ' ' }}notifications.
    </div>
  }
  @for (message of notifications(); track message.id) {
    <div
      mat-menu-item
      class="inbox__item"
      (click)="toggleRead($event, message, false)"
    >
      <procure-notification-message
        class="inbox__message"
        condensed
        [message]="message"
        (toggleRead)="toggleRead($event, message, true)"
        (toggleArchive)="toggleArchive($event, message)"
      />
    </div>
  }
  @if (hasMore()) {
    <div
      class="inbox__item"
      (click)="$event.stopPropagation()"
    >
      <button
        class="inbox__display-more-button"
        procure-button
        variant="stroked"
        (click)="displayMore($event)"
      >
        Display more
      </button>
    </div>
  }
</mat-menu>
