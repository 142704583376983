import { nullable } from '@abb-procure/api';
import * as t from 'io-ts';
import { PurchaseRequisitionSearchIndexDefinition } from './purchase-requisition-search-index-definition.type';

const PurchaseRequisitionSearchIndexDefinitionSearchDocumentItemResult = t.type({
  value: PurchaseRequisitionSearchIndexDefinition,
  highlights: nullable(t.record(t.string, t.array(t.string))),
  searchScore: nullable(t.number),
}, 'PurchaseRequisitionSearchIndexDefinitionSearchDocumentItemResult');

type PurchaseRequisitionSearchIndexDefinitionSearchDocumentItemResult = t.TypeOf<
  typeof PurchaseRequisitionSearchIndexDefinitionSearchDocumentItemResult
>;
export { PurchaseRequisitionSearchIndexDefinitionSearchDocumentItemResult };
