@if (
  {
    currentUser: currentUser$ | async
  };
  as $
) {
  <div class="app-nav">
    <div class="app-nav__primary">
      <div class="app-nav__items">
        @if (
          userState.current()?.permissions?.canAddRequestForRequestForQuotation ||
          userState.current()?.permissions?.canAddProject ||
          userState.current()?.permissions?.canAddRequestForRequestForQutoationFromTemplate
        ) {
          <procure-menu>
            <procure-menu-item
              primary
              icon="add_circle"
              label="Create"
              [matMenuTriggerFor]="createMenu.menu"
            />
          </procure-menu>
          <procure-create-menu #createMenu />
        }
        <procure-menu>
          <procure-menu-item
            icon="home"
            label="Dashboard"
            [link]="['/']"
          />
          <procure-menu-item
            partialMatch
            icon="local_shipping"
            label="Suppliers"
            [link]="['/suppliers']"
          />
          @if (userState.current()?.permissions?.canManageCategories) {
            <procure-menu-item
              partialMatch
              icon="widgets"
              label="Category Management"
              [link]="['/categories']"
            />
          }
          @if (userState.current()?.permissions?.canManageTemplates) {
            <procure-menu-item
              icon="move_down"
              label="Sourcing Templates"
              [link]="['/templates']"
            />
          }
        </procure-menu>
        <router-outlet name="aside" />
      </div>
      <div
        class="app-nav__toggle"
        [matTooltip]="preferences.isNavigationExpanded() ? 'Collapse Navigation' : 'Expand Navigation'"
        (click)="toggle()"
      >
        <button
          class="app-nav__toggle-button"
          procure-button
          color="white"
          condensed
        >
          <mat-icon class="app-nav__toggle-icon">{{
            preferences.isNavigationExpanded() ? "chevron_left" : "chevron_right"
          }}</mat-icon>
        </button>
      </div>
    </div>

    <div class="app-nav__secondary">
      <div class="app-nav__items">
        <router-outlet name="aside-secondary" />
      </div>
      <div
        class="app-nav__toggle"
        [matTooltip]="preferences.isSecondaryNavigationExpanded() ? 'Collapse Navigation' : 'Expand Navigation'"
        (click)="toggleSecondary()"
      >
        <button
          class="app-nav__toggle-button"
          procure-button
          color="white"
          condensed
        >
          <mat-icon class="app-nav__toggle-icon">{{
            preferences.isSecondaryNavigationExpanded() ? "chevron_left" : "chevron_right"
          }}</mat-icon>
        </button>
      </div>
    </div>
  </div>
}
