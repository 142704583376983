import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchHighlight',
  standalone: true,
})
export class SearchHighlightPipe implements PipeTransform {
  /**
   * Highlights the seachTerm in the result
   *
   * @param result search result
   * @param searchTerm seach term keywords like "and or" will be handled here
   */
  transform(result: string = '', searchTerm: string = ''): string {
    if (searchTerm === '*') {
      return result;
    }

    // Escape special RegEx characters. Mostly used to treat [] from entity IDs
    // as regular strings.
    const escaped = searchTerm.replace(/[-[\]{}()*+?.,\\^$|#]/g, '\\$&');
    const re = /( and | or | AND | OR )/g;

    const searchTerms = escaped.split(re)
      .filter((txt) => !txt.match(re))
      .map((txt) => txt.trim());

    if (searchTerms.length === 0 || !result) {
      return result;
    }

    const reText = new RegExp(`(${searchTerms.join('|')})`, 'gi');

    return result.replace(reText, '<mark>$&</mark>');
  }
}
