import { Injectable, computed, signal } from '@angular/core';

export interface EnvironmentStateShape {
  isProduction: boolean;
  isEnvironmentBannerEnabled: boolean;
}

const initialState: EnvironmentStateShape = {
  isProduction: false,
  isEnvironmentBannerEnabled: false,
};

@Injectable({
  providedIn: 'root',
})
export class EnvironmentState {
  readonly isProduction = computed(() => this.state().isProduction);
  readonly isEnvironmentBannerEnabled = computed(
    () => this.state().isEnvironmentBannerEnabled,
  );

  private readonly state = signal({ ...initialState });

  setIsProduction(isProduction: boolean): void {
    this.state.update((currentState) => ({
      ...currentState,
      isProduction,
    }));
  }

  setIsEnvironmentBannerEnabled(isEnvironmentBannerEnabled: boolean): void {
    this.state.update((currentState) => ({
      ...currentState,
      isEnvironmentBannerEnabled,
    }));
  }
}
