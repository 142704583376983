import {
  ButtonComponent,
  MenuComponent,
  MenuItemComponent,
  TagListComponent,
} from '@abb-procure/common';
import {
  CurrentUser,
  LoginUserService,
  PreferencesState,
  UserState,
} from '@abb-procure/data';
import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterLink, RouterOutlet } from '@angular/router';
import { QuicklinkDirective } from 'ngx-quicklink';
import { Observable } from 'rxjs';
import { CreateMenuComponent } from '../create-menu/create-menu.component';

@Component({
  selector: '[procure-app-navigation]',
  templateUrl: './app-navigation.component.html',
  styleUrl: './app-navigation.component.scss',
  standalone: true,
  imports: [
    ButtonComponent,
    RouterLink,
    MatMenuModule,
    AsyncPipe,
    MatMenuModule,
    AppNavigationComponent,
    MenuComponent,
    MenuItemComponent,
    RouterOutlet,
    MatIconModule,
    MatTooltipModule,
    QuicklinkDirective,
    CreateMenuComponent,
    TagListComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppNavigationComponent {
  protected readonly currentUser$: Observable<CurrentUser | null>;
  protected preferences = inject(PreferencesState);
  protected readonly userState = inject(UserState);

  private readonly loginUserService = inject(LoginUserService);

  constructor() {
    this.currentUser$ = this.loginUserService.currentUser$;
  }

  protected toggle(): void {
    this.preferences.toggleNavigation();
  }

  protected toggleSecondary(): void {
    this.preferences.toggleSecondaryNavigation();
  }
}
