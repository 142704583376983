import * as t from 'io-ts';
import { DateString, nullable } from '@abb-procure/api';
import { UserSlim } from '@abb-procure/data';

const NotificationInboxMessage = t.type({
  id: nullable(t.string),
  title: nullable(t.string),
  message: nullable(t.string),
  data: t.record(t.string, nullable(t.string)),
  createdOn: DateString,
  sender: nullable(UserSlim),
  isRead: t.boolean,
  isArchived: t.boolean,
}, 'NotificationInboxMessage');

type NotificationInboxMessage = t.TypeOf<typeof NotificationInboxMessage>;
export { NotificationInboxMessage };
