<header class="dialog__header">
  <h2 class="dialog__title">{{ data.title }}</h2>
</header>
<mat-dialog-content>
  @if (data.base) {
    <div formRow>
      <div class="dialog__base-info">
        Dear user<br /><br />
        You are currently not authorized to interact with ABB Procure. Please contact in the subsequent order:<br /><br />
        <ol>
          <li>The Local Procurement Manager of your Division and / or</li>
          <li>Local key user for ABB Procure and / or</li>
          <li>ABB Procure Support: 
              @if (lookupDataState.gisLink(); as gisLink) {
                <a
                  class="dialog__link text-link"
                  target="_blank"
                  rel="noopener noreferrer"
                  [matTooltip]="gisLink.description ?? ''"
                  [href]="gisLink.url"
                >
                  {{ gisLink.name }}
                </a>
              }
          </li>
        </ol>
      </div>
    </div>
  }
  @if (data.text) {
    <div formRow>
      <procure-show-field label="Information">
        {{ data.text }}
      </procure-show-field>
    </div>
  }
  @if (data.info) {
    <div
      class="dialog__info"
      formRow
    >
      <mat-icon class="dialog__info-icon">info</mat-icon>

      {{ data.info }}
    </div>
  }
  @if (data.type === dialogType.ADDITIONAL_INFO) {
    <div class="dialog__additional-info">
      @if (data.additionalInfo?.localDivisionAssignments; as info) {
        <procure-expansion-panel
          label="Expired Local Division Assignments"
          flat
        >
          @for (unitInfo of data.additionalInfo?.localDivisionAssignments; track unitInfo?.unit?.id) {
            <div formRow>
              <procure-show-field label="Unit">
                <procure-tag> {{ unitInfo?.unit?.name }} </procure-tag>
              </procure-show-field>
              <procure-show-field label="Managers">
                @if (unitInfo.managers?.length) {
                  <procure-tag-list
                    [users]="unitInfo.managers"
                    [maxVisible]="5"
                  />
                } @else {
                  -
                }
              </procure-show-field>
            </div>
          }
        </procure-expansion-panel>
      }
      @if (data.additionalInfo?.localDivisionManagerAssignments; as info) {
        <procure-expansion-panel
          label="Expired Local Division Manager Assignments"
          flat
        >
          @for (unitInfo of data.additionalInfo?.localDivisionManagerAssignments; track unitInfo?.unit?.id) {
            <div formRow>
              <procure-show-field label="Unit">
                <procure-tag> {{ unitInfo?.unit?.name }} </procure-tag>
              </procure-show-field>
              <procure-show-field label="Managers">
                @if (unitInfo.managers?.length) {
                  <procure-tag-list [users]="unitInfo.managers" />
                } @else {
                  -
                }
              </procure-show-field>
            </div>
          }
        </procure-expansion-panel>
      }
      @if (data.additionalInfo?.hubManagerAssignments; as info) {
        <procure-expansion-panel
          label="Expired Hub Manager Assignments"
          flat
        >
          @for (unitInfo of data.additionalInfo?.hubManagerAssignments; track unitInfo?.unit?.id) {
            <div formRow>
              <procure-show-field label="Unit">
                <procure-tag> {{ unitInfo?.unit?.name }} </procure-tag>
              </procure-show-field>
              <procure-show-field label="Managers">
                @if (unitInfo.managers?.length) {
                  <procure-tag-list [users]="unitInfo.managers" />
                } @else {
                  -
                }
              </procure-show-field>
            </div>
          }
        </procure-expansion-panel>
      }
      @if (data.additionalInfo?.divisionManagerAssignments; as info) {
        <procure-expansion-panel
          label="Expired Division Manager Assignments"
          flat
        >
          @for (unitInfo of data.additionalInfo?.divisionManagerAssignments; track unitInfo?.unit?.id) {
            <div formRow>
              <procure-show-field label="Unit">
                <procure-tag> {{ unitInfo?.unit?.name }} </procure-tag>
              </procure-show-field>
              <procure-show-field label="Managers">
                @if (unitInfo.managers?.length) {
                  <procure-tag-list [users]="unitInfo.managers" />
                } @else {
                  -
                }
              </procure-show-field>
            </div>
          }
        </procure-expansion-panel>
      }
    </div>
  }
</mat-dialog-content>
<footer class="dialog__footer">
  @if (data.type === dialogType.PROMPT) {
    <button
      id="dialog-no"
      type="button"
      procure-button
      variant="stroked"
      [mat-dialog-close]="false"
      [disabled]="isConfirming()"
    >
      {{ data.secondaryAction || "No" }}
    </button>
  }
  @if (data.type === dialogType.PROMPT) {
    <button
      id="dialog-yes"
      type="button"
      procure-button
      color="primary"
      [loading]="isConfirming()"
      (click)="confirm()"
    >
      {{ data.primaryAction || "Yes" }}
    </button>
  }
  @if (data.type !== dialogType.PROMPT) {
    <button
      id="dialog-ok"
      type="button"
      procure-button
      color="primary"
      [mat-dialog-close]="!data.blackout"
      (click)="close()"
    >
      {{ data.primaryAction || "OK" }}
    </button>
  }
</footer>
