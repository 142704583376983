import {
  Component, EventEmitter, Input, Output, booleanAttribute,
} from '@angular/core';
import {
  AvatarComponent,
  ButtonComponent,
  EntityBadgeComponent,
  SanitizePipe,
  DatePipe,
} from '@abb-procure/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { NotificationInboxMessageModel } from '../../models/notification-inbox-message.model';

@Component({
  selector: 'procure-notification-message',
  templateUrl: './message.component.html',
  styleUrl: './message.component.scss',
  standalone: true,
  imports: [
    AvatarComponent,
    DatePipe,
    ButtonComponent,
    MatTooltipModule,
    MatIconModule,
    SanitizePipe,
    EntityBadgeComponent,
  ],
})
export class MessageComponent {
  @Input({ required: true }) protected message!: NotificationInboxMessageModel;
  @Input({ transform: booleanAttribute }) protected condensed: boolean = false;

  @Output() protected readonly toggleRead = new EventEmitter<MouseEvent>();
  @Output() protected readonly toggleArchive = new EventEmitter<MouseEvent>();
}
