import { nullable } from '@abb-procure/api';
import * as t from 'io-ts';

const SupplierSearchIndexDefinition = t.type(
  {
    id: nullable(t.string),
    companyName: nullable(t.string),
    supplierSource: nullable(t.string),
    guid: nullable(t.string),
    addressLine: nullable(t.string),
    zipCode: nullable(t.string),
    city: nullable(t.string),
    state: nullable(t.string),
    country: nullable(t.string),
  },
  'SupplierSearchIndexDefinition',
);

type SupplierSearchIndexDefinition = t.TypeOf<
  typeof SupplierSearchIndexDefinition
>;
export { SupplierSearchIndexDefinition };
