import { SnackbarService } from '@abb-procure/snackbar';
import { Injectable, inject } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ClipboardService {
  private readonly snackbarService = inject(SnackbarService);

  copy(value: string, copyAsUrl: boolean = false): void {
    navigator.clipboard.writeText(
      copyAsUrl
        ? new URL(`${window.location.origin}${value}`).toString()
        : value,
    );

    this.snackbarService.showSuccessSnackbar(
      {
        text: 'Copied to the clipboard.',
      },
      {
        duration: 3000,
      },
    );
  }
}
