import { OrderedValue } from '../types/ordered-value.type';
import { BaseModel } from './base.model';

export class OrderedValueModel extends BaseModel implements OrderedValue {
  readonly orderIndex: number;
  readonly value: string | null;
  readonly id: number;

  constructor(orderedValue: OrderedValue) {
    super();

    this.orderIndex = orderedValue.orderIndex;
    this.value = orderedValue.value;
    this.id = orderedValue.orderIndex;
  }

  getDisplay(): string {
    return `${this.value}`;
  }
}
