import { ApplicationNotification } from '../types/application-notification.type';

export class ApplicationNotificationModel implements ApplicationNotification {
  readonly notificationId: string;
  value: string | null = null;
  readonly validFrom: Date;
  readonly validUntil: Date;
  readonly enabled: boolean;
  display: boolean;

  constructor(applicationNotification: ApplicationNotification) {
    this.notificationId = applicationNotification.notificationId || '';
    this.value = applicationNotification.value ?? '';
    this.validFrom = applicationNotification.validFrom;
    this.validUntil = applicationNotification.validUntil;
    this.enabled = applicationNotification.enabled || false;
    this.display = applicationNotification.display || false;
  }
}
