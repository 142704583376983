import { OrganisationReview } from '../types/organisation-review.type';
import { EntityStatusModel } from './entity-status.model';
import { GuidNameCodeModel } from './guid-name-code.model';

export class OrganisationReviewModel implements OrganisationReview {
  readonly reviewStatus: EntityStatusModel | null = null;
  readonly reviewed: Date | null = null;
  readonly nextReview: Date | null = null;
  readonly unit: GuidNameCodeModel | null = null;

  constructor(organisationReview: OrganisationReview) {
    if (organisationReview.reviewStatus) {
      this.reviewStatus = new EntityStatusModel(
        organisationReview.reviewStatus,
      );
    }

    if (organisationReview.reviewed) {
      this.reviewed = organisationReview.reviewed;
    }

    if (organisationReview.nextReview) {
      this.nextReview = organisationReview.nextReview;
    }

    if (organisationReview.unit) {
      this.unit = new GuidNameCodeModel(organisationReview.unit);
    }
  }
}
