import { Snippet } from '@microsoft/applicationinsights-web';
import { IChannelControls, ITelemetryPlugin } from '@microsoft/applicationinsights-core-js';
import { DistributedTracingModes } from '@microsoft/applicationinsights-common';

type AppInsightsConfig = Snippet['config'];

export class AngularApplicationInsightsConfig implements AppInsightsConfig {
  disableAngularRouteTracking?: boolean;
  instrumentationKey?: string;
  connectionString?: string;
  diagnosticLogInterval?: number;
  maxMessageLimit?: number;
  loggingLevelConsole?: number;
  loggingLevelTelemetry?: number;
  enableDebugExceptions?: boolean;
  endpointUrl?: string;
  extensionConfig?: Record<string, unknown>;
  extensions?: ITelemetryPlugin[];
  channels?: IChannelControls[][];
  emitLineDelimitedJson?: boolean;
  accountId?: string;
  sessionRenewalMs?: number;
  sessionExpirationMs?: number;
  maxBatchSizeInBytes?: number;
  maxBatchInterval?: number;
  enableDebug?: boolean;
  disableExceptionTracking?: boolean;
  disableTelemetry?: boolean;
  samplingPercentage?: number;
  autoTrackPageVisitTime?: boolean;
  enableAutoRouteTracking?: boolean;
  disableAjaxTracking?: boolean;
  disableFetchTracking?: boolean;
  overridePageViewDuration?: boolean;
  maxAjaxCallsPerView?: number;
  disableDataLossAnalysis?: boolean;
  disableCorrelationHeaders?: boolean;
  distributedTracingMode?: DistributedTracingModes;
  correlationHeaderExcludedDomains?: string[];
  disableFlushOnBeforeUnload?: boolean;
  disableFlushOnUnload?: boolean;
  enableSessionStorageBuffer?: boolean;
  isCookieUseDisabled?: boolean;
  cookieDomain?: string;
  isRetryDisabled?: boolean;
  url?: string;
  isStorageUseDisabled?: boolean;
  isBeaconApiDisabled?: boolean;
  sdkExtension?: string;
  isBrowserLinkTrackingEnabled?: boolean;
  appId?: string;
  enableCorsCorrelation?: boolean;
  namePrefix?: string;
  enableRequestHeaderTracking?: boolean;
  enableResponseHeaderTracking?: boolean;
  enableAjaxErrorStatusText?: boolean;
  onunloadDisableBeacon?: boolean;
}
