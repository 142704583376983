import { EntityStatus } from '../types/entity-status.type';
import { BaseModel } from './base.model';

export class EntityStatusModel extends BaseModel implements EntityStatus {
  readonly id: number;
  readonly name: string;
  readonly color: string;

  constructor(data: EntityStatus) {
    super();

    this.id = data.id;
    this.name = data.name;
    this.color = data.color;
  }

  getDisplay(): string {
    return `${this.name}`;
  }
}
