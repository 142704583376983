import { nullable } from '@abb-procure/api';
import * as t from 'io-ts';
import { SupplierSearchIndexDefinitionSearchDocumentItemResult } from './supplier-search-index-definition-search-document-item-result.type';

const SupplierSearchIndexDefinitionSearchDocumentResult = t.type({
  count: nullable(t.number),
  values: t.array(SupplierSearchIndexDefinitionSearchDocumentItemResult),
}, 'SupplierSearchIndexDefinitionSearchDocumentResult');

type SupplierSearchIndexDefinitionSearchDocumentResult = t.TypeOf<
  typeof SupplierSearchIndexDefinitionSearchDocumentResult
>;
export { SupplierSearchIndexDefinitionSearchDocumentResult };
