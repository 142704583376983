import { nullable } from '@abb-procure/api';
import * as t from 'io-ts';

const ProjectSearchIndexDefinition = t.type(
  {
    id: nullable(t.string),
    projectID: nullable(t.string),
    isTest: t.boolean,
    isClosed: t.boolean,
    entityNumber: t.number,
    projectName: nullable(t.string),
    creator: nullable(t.string),
    statusUpdater: nullable(t.string),
    purchaser: nullable(t.string),
    projectResponsible: nullable(t.string),
    technicalResponsible: nullable(t.string),
    statusName: nullable(t.string),
    customer: nullable(t.string),
    salesforceID: nullable(t.string),
    ipmProjectId: nullable(t.string),
    erpProjectId: nullable(t.string),
    scope: nullable(t.string),
    termsAndConditions: nullable(t.string),
    generalInformationComment: nullable(t.string),
    projectDescriptionComment: nullable(t.string),
    projectCountry: nullable(t.string),
    projectCountryCode: nullable(t.string),
    number: nullable(t.string),
    internalConsortium: nullable(t.string),
    additionalEditors: nullable(t.string),
    projectSCM: nullable(t.string),
  },
  'ProjectSearchIndexDefinition',
);

type ProjectSearchIndexDefinition = t.TypeOf<
  typeof ProjectSearchIndexDefinition
>;
export { ProjectSearchIndexDefinition };
