import { ApiService, CustomHttpParams } from '@abb-procure/api';
import { EntityIdAliasPrefixes } from '@abb-procure/constants';
import { Injectable } from '@angular/core';
import { boolean as ioBoolean } from 'io-ts';
import { SearchDocumentResponseModel } from '../models/search-document-response.model';
import { AddSearchActivityRequest } from '../types/add-search-activity-request.type';
import { SearchDocumentResponse } from '../types/search-document-response.type';

export const SEARCH_ALL_SYMBOL: string = '*';

export type SearchOptions = {
  highlightPreTag: string;
  highlightPostTag: string;
  searchTerm?: string;
  type?: EntityIdAliasPrefixes;
  top?: number;
  skip?: number;
  showInactive?: boolean;
  showClosed?: boolean;
  includeTotalCount?: boolean;
};

@Injectable({
  providedIn: 'root',
})
export class SearchApiService extends ApiService {
  async search(
    options?: SearchOptions,
  ): Promise<SearchDocumentResponseModel | null> {
    const params = new CustomHttpParams(options);

    try {
      const response = await this.get<SearchDocumentResponse>(
        'Search',
        SearchDocumentResponse,
        params,
      );

      return response != null
        ? new SearchDocumentResponseModel(response)
        : null;
    } catch (error) {
      return this.catchError(
        error,
        'There was an issue retrieving the search results.',
      );
    }
  }

  async quickSearch(
    searchTerm: string,
  ): Promise<SearchDocumentResponseModel | null> {
    const params = new CustomHttpParams({
      searchTerm,
    });

    try {
      const response = await this.get<SearchDocumentResponse>(
        'Search/Quick',
        SearchDocumentResponse,
        params,
      );

      return response != null
        ? new SearchDocumentResponseModel(response)
        : null;
    } catch (error) {
      return this.catchError(
        error,
        'There was an issue retrieving the search results.',
      );
    }
  }

  async addSearchActivity(
    request: AddSearchActivityRequest,
  ): Promise<boolean | null> {
    try {
      return await this.post<boolean, AddSearchActivityRequest>(
        'Search',
        ioBoolean,
        request,
      );
    } catch (error) {
      return this.catchError(
        error,
        'There was an issue adding the search activity.',
      );
    }
  }
}
