import { BaseModel } from '@abb-procure/data';
import { SupplierSearchIndexDefinitionSearchDocumentItemResult } from '../types/supplier-search-index-definition-search-document-item-result.type';
import { SupplierSearchIndexDefinitionModel } from './supplier-search-index-definition.model';

export class SupplierSearchIndexDefinitionSearchDocumentItemResultModel extends BaseModel {
  readonly value: SupplierSearchIndexDefinitionModel;
  readonly highlights: Record<string, string[]> | null;
  readonly searchScore: number | null;

  constructor(
    result: SupplierSearchIndexDefinitionSearchDocumentItemResult,
  ) {
    super();

    this.value = new SupplierSearchIndexDefinitionModel(result.value);
    this.highlights = result.highlights;
    this.searchScore = result.searchScore;
  }
}
