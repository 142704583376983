import { BaseModel } from '@abb-procure/data';
import { RequestForQuotationSearchIndexDefinitionSearchDocumentItemResult } from '../types/request-for-quotation-search-index-definition-search-document-item-result.type';
import { RequestForQuotationSearchIndexDefinitionModel } from './request-for-quotation-search-index-definition.model';

export class RequestForQuotationSearchIndexDefinitionSearchDocumentItemResultModel extends BaseModel {
  readonly value: RequestForQuotationSearchIndexDefinitionModel;
  readonly highlights: Record<string, string[]> | null;
  readonly searchScore: number | null;

  constructor(
    result: RequestForQuotationSearchIndexDefinitionSearchDocumentItemResult,
  ) {
    super();

    this.value = new RequestForQuotationSearchIndexDefinitionModel(result.value);
    this.highlights = result.highlights;
    this.searchScore = result.searchScore;
  }
}
