import { DefaultCriteriaDimension } from '../types/default-criteria-dimension.type';
import { BaseModel } from './base.model';

export class DefaultCriteriaDimensionModel
  extends BaseModel
  implements DefaultCriteriaDimension
{
  readonly id: number;
  readonly name: string | null;
  readonly weight: number;
  readonly order: number;

  constructor(data: DefaultCriteriaDimension) {
    super();

    this.id = data.id;
    this.name = data.name;
    this.weight = data.weight;
    this.order = data.order;
  }
}
