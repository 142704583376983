import { UNREAD_NOTIFICATIONS_LIMIT } from '@abb-procure/constants';
import {
  Component,
  ContentChild,
  ElementRef,
  HostBinding,
  Input,
  ViewChild,
  booleanAttribute,
} from '@angular/core';
import { MatBadge, MatBadgeModule } from '@angular/material/badge';
import { MatIcon, MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

type ButtonColors =
  | 'primary'
  | 'secondary'
  | 'black'
  | 'white'
  | 'info'
  | 'grey';

type ButtonVariants = 'flat' | 'stroked' | 'ghost';

@Component({
  selector: '[procure-button]',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  standalone: true,
  imports: [MatProgressSpinnerModule, MatIconModule, MatBadgeModule],
})
export class ButtonComponent {
  @ViewChild(MatBadge, { static: false, read: ElementRef })
  readonly badgeItem?: ElementRef;

  @ContentChild(MatIcon, { static: false }) readonly icon?: MatIcon;

  @HostBinding('class')
  get classes(): string {
    return [
      'button',
      `button--${this.color}`,
      `button--${this.variant}`,
      this.icon ? 'button--icon' : '',
      this.condensed || this.condensedIcon ? 'button--condensed' : '',
      this.elevated ? 'button--elevated' : '',
      this.wide ? 'button--wide' : '',
      this.filter ? 'button--filter' : '',
    ]
      .filter(Boolean)
      .join(' ');
  }

  @HostBinding('attr.disabled')
  get isDisabled(): boolean | null {
    return this.loading === true || this.disabled === true || null;
  }

  @Input() color: ButtonColors = 'secondary';
  @Input() variant: ButtonVariants = 'flat';
  @Input() prefix?: string;
  @Input() suffix?: string;
  @Input() condensedIcon?: string;
  @Input() badgeLimit = UNREAD_NOTIFICATIONS_LIMIT;
  @Input({ transform: booleanAttribute }) loading = false;
  @Input({ transform: booleanAttribute }) disabled = false;
  @Input({ transform: booleanAttribute }) active = false;
  @Input({ transform: booleanAttribute }) condensed = false;
  @Input({ transform: booleanAttribute }) elevated = false;
  @Input({ transform: booleanAttribute }) animateBadgeChange = false;
  @Input({ transform: booleanAttribute }) wide = false;
  @Input({ transform: booleanAttribute }) filter = false;

  @Input()
  get badge(): number | null {
    return this._badge;
  }

  set badge(value: number | null) {
    const previous = this._badge;

    this._badge = value;

    if (
      this._badge === null ||
      this._badge <= (previous || 0) ||
      !this.animateBadgeChange
    ) {
      return;
    }

    this.badgeItem?.nativeElement.classList.remove('button__badge--animated');

    setTimeout(() => {
      this.badgeItem?.nativeElement.classList.add('button__badge--animated');
    }, 0);
  }

  private _badge: number | null = null;
}
