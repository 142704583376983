import { CurrentUser } from '../types/current-user.type';
import { UserPermissions } from '../types/user-permissions.type';
import { PreferenceModel } from './preference.model';
import { UserGuideTourModel } from './user-guide-tour.model';
import { UserPermissionsModel } from './user-permissions.model';
import { UserModel } from './user.model';

export class CurrentUserModel extends UserModel implements CurrentUser {
  readonly guideTours: UserGuideTourModel[] | null = null;
  readonly mystiqueKey: string;
  readonly permissions: UserPermissions;
  readonly preferences: PreferenceModel[] | null = null;
  readonly additionalData: Record<string, boolean>;

  constructor(data: CurrentUser) {
    super(data);

    if (data.guideTours) {
      this.guideTours = data.guideTours.map(
        (item) => new UserGuideTourModel(item),
      );
    }

    this.mystiqueKey = data.mystiqueKey;
    this.permissions = new UserPermissionsModel(data.permissions);

    if (data.preferences) {
      this.preferences = data.preferences.map(
        (item) => new PreferenceModel(item),
      );
    }

    this.additionalData = data.additionalData;
  }
}
