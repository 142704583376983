import { MdfCode } from '../types/mdf-code.type';
import { BaseModel } from './base.model';

export class MdfCodeModel extends BaseModel implements MdfCode {
  readonly id: number;
  readonly name: string | null;
  readonly code: string | null;
  readonly count: number | undefined;

  constructor(data: MdfCode) {
    super();

    this.id = data.id;
    this.name = data.name;
    this.code = data.code;
    this.count = data.count;
  }

  /**
   * Returns the display value for an MDF code.
   */
  getDisplay(): string {
    return `${this.code} - ${this.name}`;
  }
}
