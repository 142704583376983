import { fetchData } from '@abb-procure/api';
import {
  ButtonComponent,
  InboxService,
  InputSwitchComponent,
  LoadingIndicatorComponent,
  TitleService,
} from '@abb-procure/common';
import { SignalrService } from '@abb-procure/sessions';
import { Component, effect, inject, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router, RouterLink } from '@angular/router';
import { QuicklinkDirective } from 'ngx-quicklink';
import { distinctUntilChanged } from 'rxjs';
import { NotificationInboxMessageModel } from '../../models/notification-inbox-message.model';
import { NotificationApiService } from '../../services/notification-api.service';
import { MessageComponent } from '../message/message.component';

@Component({
  selector: 'procure-inbox',
  templateUrl: './inbox.component.html',
  styleUrls: ['./inbox.component.scss'],
  standalone: true,
  imports: [
    ButtonComponent,
    MatIconModule,
    MatMenuModule,
    RouterLink,
    MessageComponent,
    LoadingIndicatorComponent,
    MatTooltipModule,
    QuicklinkDirective,
    ButtonComponent,
    InputSwitchComponent,
  ],
})
export class InboxComponent {
  protected readonly notifications = signal<NotificationInboxMessageModel[]>(
    [],
  );

  protected readonly count = signal<number | null>(null);
  protected readonly totalCount = signal<number>(0);
  protected readonly displayCount = signal<number>(1);
  protected readonly isLoading = signal(false);
  protected readonly unreadOnly = new FormControl(false);

  private readonly notificationApiService = inject(NotificationApiService);
  private readonly router = inject(Router);
  private readonly signalrService = inject(SignalrService);
  private readonly inboxService = inject(InboxService);
  private readonly titleService = inject(TitleService);

  constructor() {
    this.fetchData();

    effect(
      () => {
        this.titleService.setUnread(this.count());
      },
      { allowSignalWrites: true },
    );

    this.signalrService.messages$
      .pipe(distinctUntilChanged(), takeUntilDestroyed())
      .subscribe((message) => {
        if (message !== 'Notifications') {
          return;
        }

        this.fetchData();
      });

    this.inboxService.refresh$.pipe(takeUntilDestroyed()).subscribe(() => {
      this.fetchData();
    });

    this.unreadOnly.valueChanges.pipe(takeUntilDestroyed()).subscribe(() => {
      this.displayCount.set(1);
      this.fetchData();
    });
  }

  protected async toggleRead(
    event: MouseEvent,
    message: NotificationInboxMessageModel,
    stopPropagation: boolean,
  ): Promise<void> {
    if (stopPropagation) {
      event.stopPropagation();
    } else if (message.data.url) {
      try {
        const path = new URL(message.data.url).pathname;
        this.router.navigate([path]);
      } catch (_e) {
        return;
      }
    }

    if (!message.id) {
      return;
    }

    const request = message.isRead
      ? this.notificationApiService.markAsUnread(message.id)
      : this.notificationApiService.markAsRead(message.id);

    const response = await request;

    if (!response) {
      return;
    }

    this.inboxService.clear();

    this.notifications.update((notifications) => {
      if (this.unreadOnly.value && !message.isRead) {
        return (
          notifications.filter(
            (notification) => notification.id !== message.id,
          ) || []
        );
      }

      return notifications.map((notification) => {
        if (notification.id !== message.id) {
          return notification;
        }

        return new NotificationInboxMessageModel({
          ...notification,
          isRead: !message.isRead,
        });
      });
    });

    this.updateCount(message.isRead ? 1 : -1);
  }

  protected async toggleArchive(
    event: MouseEvent,
    message: NotificationInboxMessageModel,
  ): Promise<void> {
    event.stopPropagation();

    if (!message.id) {
      return;
    }

    const request = message.isArchived
      ? this.notificationApiService.unarchive(message.id)
      : this.notificationApiService.archive(message.id);

    const response = await request;

    if (!response) {
      return;
    }

    this.notifications.update((notifications) =>
      notifications.map((notification) => {
        if (notification.id !== message.id) {
          return notification;
        }

        return new NotificationInboxMessageModel({
          ...notification,
          isArchived: !message.isArchived,
        });
      }),
    );
  }

  protected markAllAsRead(event: MouseEvent): void {
    event.stopPropagation();

    fetchData(
      this.notificationApiService.markAllAsRead(),
      this.isLoading,
      () => {
        this.inboxService.clear();
        this.fetchData();
      },
    );
  }

  protected displayMore(event: MouseEvent): void {
    event.stopPropagation();
    this.displayCount.update((count) => (count ?? 1) + 1);
    this.fetchData();
  }

  protected hasMore(): boolean {
    return (
      this.displayCount() * 10 <
      (this.unreadOnly.value ? this.count() ?? 0 : this.totalCount())
    );
  }

  private fetchData(): void {
    fetchData(
      this.notificationApiService.getNotifications({
        isRead: this.unreadOnly.value ? false : null,
        pageIndex: 0,
        pageSize: this.displayCount() * 10,
        orderBy: 'createdOn',
        orderIsAscending: false,
      }),
      this.isLoading,
      (response) => {
        this.totalCount.set(response.totalCount);
        this.count.set(response.unreadCount);
        this.notifications.set(response.items ?? []);
      },
    );
  }

  private updateCount(delta: number): void {
    this.count.update((count) => (count ?? 0) + delta);
  }
}
