import { BaseModel } from '@abb-procure/data';
import { SupplierSearchIndexDefinitionSearchDocumentResult } from '../types/supplier-search-index-definition-search-document-result.type';
import { SupplierSearchIndexDefinitionSearchDocumentItemResultModel } from './supplier-search-index-definition-search-document-item-result.model';

export class SupplierSearchIndexDefinitionSearchDocumentResultModel extends BaseModel {
  readonly count: number | null;
  readonly values: SupplierSearchIndexDefinitionSearchDocumentItemResultModel[];

  constructor(
    result: SupplierSearchIndexDefinitionSearchDocumentResult,
  ) {
    super();

    this.count = result.count;

    this.values = result.values
      .map((value) => new SupplierSearchIndexDefinitionSearchDocumentItemResultModel(value));
  }
}
