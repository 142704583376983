import { BaseModel } from '@abb-procure/data';
import { RequestForRequestForQuotationSearchIndexDefinitionSearchDocumentItemResult } from '../types/request-for-request-for-quotation-search-index-definition-search-document-item-result.type';
import { RequestForRequestForQuotationSearchIndexDefinitionModel } from './request-for-request-for-quotation-search-index-definition.model';

export class RequestForRequestForQuotationSearchIndexDefinitionSearchDocumentItemResultModel extends BaseModel {
  readonly value: RequestForRequestForQuotationSearchIndexDefinitionModel;
  readonly highlights: Record<string, string[]> | null;
  readonly searchScore: number | null;

  constructor(
    result: RequestForRequestForQuotationSearchIndexDefinitionSearchDocumentItemResult,
  ) {
    super();

    this.value = new RequestForRequestForQuotationSearchIndexDefinitionModel(result.value);
    this.highlights = result.highlights;
    this.searchScore = result.searchScore;
  }
}
