import { ENVIRONMENT_CONFIG, EnvironmentConfig } from '@abb-procure/constants';
import { Injectable, inject } from '@angular/core';
import { HttpApi } from './http-api';

@Injectable({
  providedIn: 'root',
})
export class BaseApiService extends HttpApi {
  private readonly environmentConfig: EnvironmentConfig =
    inject(ENVIRONMENT_CONFIG);

  /**
   * @inheritdoc
   */
  getEndpointUrl(url: string): string {
    return this.environmentConfig.apiEndpoint + url;
  }
}
