import { TermsAndConditions } from '../types/terms-and-conditions.type';
import { IdNameModel } from './id-name.model';

export class TermsAndConditionsModel
  extends IdNameModel
  implements TermsAndConditions
{
  readonly isCustomized: boolean;
  readonly url: string | null;

  constructor(data: TermsAndConditions) {
    super(data);

    this.isCustomized = data.isCustomized;
    this.url = data.url;
  }
}
