import {
  ErrorHandler, Inject, Injectable, Injector, inject,
} from '@angular/core';
import { EnvironmentConfig, ENVIRONMENT_CONFIG } from '@abb-procure/constants';
import { SnackbarService } from '@abb-procure/snackbar';
import { ApplicationInsightsService } from './application-insights.service';

@Injectable({
  providedIn: 'root',
})
export class GlobalErrorHandlerService extends ErrorHandler {
  private readonly injector = inject(Injector);
  private readonly appInsightsService = inject(ApplicationInsightsService);

  constructor(
    @Inject(ENVIRONMENT_CONFIG) private readonly environmentConfig: EnvironmentConfig,
  ) {
    super();
  }

  /**
   * Display an error notification and track the error through Application
   * Insights.
   */
  handleError(error: Error): void {
    super.handleError(error);

    this.appInsightsService.trackException({ exception: error });

    if (this.environmentConfig.production) {
      return;
    }

    const snackbarService = this.injector.get(SnackbarService);
    const title = error.name !== 'Error' ? `: ${error.name}` : '';

    snackbarService.showErrorSnackbar({
      title: `Unhandled exception${title}`,
      text: error.message,
    });
  }
}
