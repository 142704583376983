import { BooleanInput } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  booleanAttribute,
  input,
} from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

type IndicatorPosition = 'top' | 'center';
type IndicatorDelay = 'short' | 'long';

@Component({
  selector: 'procure-loading-indicator',
  templateUrl: './loading-indicator.component.html',
  styleUrls: ['./loading-indicator.component.scss'],
  standalone: true,
  imports: [MatProgressSpinnerModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingIndicatorComponent {
  @HostBinding('class')
  get class(): string {
    return `loading-indicator loading-indicator--${this.position()} loading-indicator--${this.delay()}`;
  }

  @HostBinding('class.loading-indicator--active')
  get activeClass(): boolean {
    return this.active();
  }

  @HostBinding('class.loading-indicator--inset')
  get insetClass(): boolean {
    return this.inset();
  }

  @HostBinding('class.loading-indicator--opaque')
  get opaqueClass(): boolean {
    return this.opaque();
  }

  @HostBinding('class.loading-indicator--cover')
  get coverClass(): boolean {
    return this.cover();
  }

  active = input<boolean, BooleanInput>(false, {
    transform: booleanAttribute,
  });

  opaque = input<boolean, BooleanInput>(false, {
    transform: booleanAttribute,
  });

  cover = input<boolean, BooleanInput>(false, {
    transform: booleanAttribute,
  });

  inset = input<boolean, BooleanInput>(false, {
    transform: booleanAttribute,
  });

  diameter = input<number>(40);
  position = input<IndicatorPosition>('center');
  delay = input<IndicatorDelay>('short');
}
