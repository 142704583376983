import {
  HttpEvent, HttpHandlerFn, HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';
import { FingerprintService } from '../services/fingerprint.service';

export const fingerprintInterceptor = (
  request: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> => {
  const { fingerprint } = inject(FingerprintService);

  return next(request.clone({
    headers: request.headers.append('Fingerprint', fingerprint),
  }));
};
