import { EnvironmentConfig } from '@abb-procure/constants';
import { AuthenticationType } from 'azure-maps-control';

const apiEndpoint =
  API_ENDPOINT || 'https://apis-dvaeh9gyf4gjewd7.z01.azurefd.net/';
const instrumentationKey =
  APPINSIGHTS_INSTRUMENTATIONKEY || '032a82b2-2d8e-4cb0-bf59-c1dc96c42e4e';
const msalApiEndpointId =
  MSAL_API_ENDPOINT_ID || '82c713c0-717e-4c61-ad20-4ae0dd52abd3';
const msalClientId = MSAL_CLIENT_ID || '82c713c0-717e-4c61-ad20-4ae0dd52abd3';
const azureMapsSubscriptionKey =
  MAPS_SUBSCRIPTION_ID || 'njYXKVLwwSrK7WKzOZxCn1xkv6_2Qk5-nA9LiyujBPE';

/**
 * This is the environment configuration for the 'staging' context of the project.
 *
 * The following contexts or environments are available:
 *  - development (environment.ts): For local development.
 *  - develop (environment.develop.ts): First deploy after merges. Development built for production.
 *  - staging (environment.staging.ts): Staging environment before production (acceptance tests).
 *  - production (environment.production.ts): Final stage for live application.
 */
export const environment: EnvironmentConfig = {
  production: true,
  title: 'Staging',
  isEnvironmentBannerEnabled: true,
  apiEndpoint,
  msalApiEndpointResource: 'https://graph.windows.net',
  msalConfig: {
    apiEndpointId: msalApiEndpointId,
    clientId: msalClientId,
    instance:
      'https://login.microsoftonline.com/372ee9e0-9ce0-4033-a64a-c07073a91ecd/',
    cacheLocation: 'localStorage',
  },
  msalLoggingEnabled: false,
  signalrLoggingEnabled: true,
  instrumentationKey,
  mapOptions: {
    authOptions: {
      authType: AuthenticationType.subscriptionKey,
      subscriptionKey: azureMapsSubscriptionKey,
    },
    baseUrl: 'https://atlas.microsoft.com/',
    endpoints: {
      addressSearch: `search/address/json?&subscription-key=${azureMapsSubscriptionKey}&api-version=1.0&language=de-CH&query=`,
    },
  },
};
