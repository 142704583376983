import { BaseModel } from '@abb-procure/data';
import { ProjectSearchIndexDefinitionSearchDocumentResult } from '../types/project-search-index-definition-search-document-result.type';
import { ProjectSearchIndexDefinitionSearchDocumentItemResultModel } from './project-search-index-definition-search-document-item-result.model';

export class ProjectSearchIndexDefinitionSearchDocumentResultModel extends BaseModel {
  readonly count: number | null;
  readonly values: ProjectSearchIndexDefinitionSearchDocumentItemResultModel[];

  constructor(result: ProjectSearchIndexDefinitionSearchDocumentResult) {
    super();

    this.count = result.count;

    this.values = result.values
      .map((value) => new ProjectSearchIndexDefinitionSearchDocumentItemResultModel(value));
  }
}
