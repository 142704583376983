import { WritableSignal, effect } from '@angular/core';

export enum StorageStrategy {
  None,
  LocalStorage,
  SessionStorage,
}

export class StateStorage<T> {
  constructor(
    private state: WritableSignal<T>,
    private key: string,
    private storageStrategy: StorageStrategy,
    private afterDeserialize?: (data: unknown) => T,
  ) {
    this.getStateFromStorage();

    effect(() => {
      this.updateStorage(this.state());
    });
  }

  static init<T>(
    state: WritableSignal<T>,
    key: string,
    storageStrategy: StorageStrategy,
    afterDeserialize?: (data: unknown) => T,
  ): StateStorage<T> {
    return new StateStorage(state, key, storageStrategy, afterDeserialize);
  }

  private getStateFromStorage(): void {
    let stored;

    switch (this.storageStrategy) {
      case StorageStrategy.LocalStorage:
        stored = localStorage.getItem(this.key);
        break;
      case StorageStrategy.SessionStorage:
        stored = sessionStorage.getItem(this.key);
        break;
      default:
        stored = null;
    }

    if (!stored) {
      return;
    }

    const data = JSON.parse(stored);

    if (!data) {
      return;
    }

    if (this.afterDeserialize) {
      this.state.set(this.afterDeserialize(data));
    } else {
      this.state.set(data);
    }
  }

  private updateStorage(state: T): void {
    try {
      switch (this.storageStrategy) {
        case StorageStrategy.LocalStorage:
          localStorage.setItem(this.key, JSON.stringify(state));
          break;
        case StorageStrategy.SessionStorage:
          sessionStorage.setItem(this.key, JSON.stringify(state));
          break;
        default:
          break;
      }
    } catch (_e) {
      // ignore
    }
  }
}
