import {
  Route, UrlMatcher, UrlMatchResult, UrlSegment, UrlSegmentGroup,
} from '@angular/router';

/**
 * Matches an URL against a entity ID alias.
 * @internal
 */
function createMatcher(entityIdPrefix: string, includeRevision: boolean): UrlMatcher {
  return (
    segments: UrlSegment[],
    _segmentGroup: UrlSegmentGroup,
    _route: Route,
  ): UrlMatchResult | null => {
    if (segments.length === 0) {
      return null;
    }

    const consumed: UrlSegment[] = [];
    const posParams: Record<string, UrlSegment> = {};

    const segment = segments[0];
    const segmentPathUpperCase = segment.path.toUpperCase();
    const normalizedEntityIdPrefix = `${entityIdPrefix.toUpperCase()}-`;
    const entityId = segment.path.slice(normalizedEntityIdPrefix.length);
    const hasRevision = entityId.split('-').length > 1;

    if (!segmentPathUpperCase.startsWith(normalizedEntityIdPrefix) || entityId === '' || (includeRevision && !hasRevision)) {
      return null;
    }

    const sanitizedSegment = new UrlSegment(`${normalizedEntityIdPrefix}${entityId}`, segment.parameters);
    consumed.push(sanitizedSegment);
    posParams.entityId = new UrlSegment(`${entityId}`, segment.parameters);

    return { consumed, posParams };
  };
}

/**
 * Matches an URL against a entity ID alias.
 */
export function entityIdMatcher(entityIdPrefix: string, includeRevision: boolean = false): UrlMatcher {
  // Wrapper to enforce correct typing until underlying Angular type issues are solved.
  return createMatcher(entityIdPrefix, includeRevision);
}
