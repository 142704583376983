import { nullable } from '@abb-procure/api';
import * as t from 'io-ts';

const RequestForRequestForQuotationSearchIndexDefinition = t.type(
  {
    id: nullable(t.string),
    projectID: nullable(t.string),
    isClosed: t.boolean,
    entityNumber: t.number,
    requiredScope: nullable(t.string),
    creator: nullable(t.string),
    statusName: nullable(t.string),
    requester: nullable(t.string),
    purchaser: nullable(t.string),
    statusUpdater: nullable(t.string),
    requiredSubmittalsToBeReturned: nullable(t.string),
    projectName: nullable(t.string),
    suppliers: nullable(t.string),
    supplierContacts: nullable(t.string),
    supplierContactEmails: nullable(t.string),
    number: nullable(t.string),
    projectCountry: nullable(t.string),
    projectCountryCode: nullable(t.string),
    mdfCodeName: nullable(t.string),
    mdfCode: nullable(t.string),
    wbsCode: nullable(t.string),
    businessUnit: nullable(t.string),
    comments: nullable(t.string),
    internalUseComment: nullable(t.string),
    finalDestinationCountry: nullable(t.string),
    finalDestinationCountryCode: nullable(t.string),
    specificationAndQuantities: nullable(t.string),
    supplierSuggestions: nullable(t.string),
    projectResponsible: nullable(t.string),
    technicalResponsible: nullable(t.string),
    additionalEditors: nullable(t.string),
    projectSCM: nullable(t.string),
  },
  'RequestForRequestForQuotationSearchIndexDefinition',
);

type RequestForRequestForQuotationSearchIndexDefinition = t.TypeOf<
  typeof RequestForRequestForQuotationSearchIndexDefinition
>;
export { RequestForRequestForQuotationSearchIndexDefinition };
