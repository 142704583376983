@if (expanded() && title()) {
  <div class="menu__title">
    @if (entityNumber()) {
      <procure-entity-badge
        [label]="entityNumber()"
        [linked]="false"
      />
      <procure-copy-button
        class="menu__copy"
        tooltip="Copy link"
        isUrl
        [value]="'/' + entityNumber()"
      />
    } @else {
      {{ title() }}
    }
  </div>
}
<div
  class="menu"
  [class.menu--expanded]="expanded() && !icons()"
  [class.menu--bottom]="bottom()"
>
  <procure-loading-indicator
    inset
    [active]="isLoading()"
  />
  <div class="menu__menu">
    <ng-content />
  </div>
</div>
