import { EnvironmentState } from '@abb-procure/api';
import {
  DialogService,
  LoadingIndicatorComponent,
  pxToRem,
} from '@abb-procure/common';
import { UserState } from '@abb-procure/data';
import { SignalrService } from '@abb-procure/sessions';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  Component,
  DestroyRef,
  ElementRef,
  HostBinding,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  effect,
  inject,
  viewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  ActivationStart,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  Router,
  RouterOutlet,
} from '@angular/router';
import {
  MSAL_GUARD_CONFIG,
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService,
} from '@azure/msal-angular';
import {
  AuthenticationResult,
  BrowserUtils,
  EventMessage,
  EventType,
  InteractionStatus,
  RedirectRequest,
} from '@azure/msal-browser';
import { filter } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { AppHeaderComponent } from './components/app-header/app-header.component';
import { AppNavigationComponent } from './components/app-navigation/app-navigation.component';
import { AppNotificationsComponent } from './components/app-notifications/app-notifications.component';

@Component({
  selector: 'procure',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  animations: [
    // the fade-in/fade-out animation.
    trigger('simpleFadeAnimation', [
      // the "in" style determines the "resting" state of the element when it is visible.
      state('in', style({ opacity: 1 })),
      // fade out when destroyed. this could also be written as transition('void => *')
      transition(':leave', animate(600, style({ opacity: 0 }))),
    ]),
    trigger('contentFadeAnimation', [
      // fade in when created. this could also be written as transition('void => *')
      transition(':enter', [style({ opacity: 0 }), animate(300)]),
    ]),
  ],
  standalone: true,
  imports: [
    RouterOutlet,
    AppHeaderComponent,
    LoadingIndicatorComponent,
    AppNotificationsComponent,
    AppNavigationComponent,
  ],
})
export class AppComponent implements OnInit, OnDestroy {
  static readonly version: string = APP_VERSION;
  static readonly buildNumber: string = BUILD_NUMBER;

  @HostBinding('class.procure') readonly hostClass: boolean = true;

  mainRef = viewChild('main', { read: ElementRef });

  isLoading: boolean = false;
  initializationInProgress: boolean = true;
  isIframe: boolean = false;
  loginDisplay: boolean = false;

  readonly isEnvironmentBannerEnabled: boolean =
    environment.isEnvironmentBannerEnabled;

  private readonly signalrService = inject(SignalrService);
  private readonly router = inject(Router);
  private readonly authService = inject(MsalService);
  private readonly msalBroadcastService = inject(MsalBroadcastService);
  private readonly dialogService = inject(DialogService);
  private readonly destroyRef = inject(DestroyRef);
  private readonly userState = inject(UserState);
  private readonly environmentState = inject(EnvironmentState);

  constructor(
    @Inject(MSAL_GUARD_CONFIG)
    private readonly msalGuardConfig: MsalGuardConfiguration,
  ) {
    this.clearCachesOnNewDeploy();

    this.environmentState.setIsProduction(environment.production);

    this.environmentState.setIsEnvironmentBannerEnabled(
      environment.isEnvironmentBannerEnabled,
    );

    effect(() => {
      if (!this.userState.isForbidden()) {
        return;
      }

      const message = this.userState.additionalInfoMessage() ?? '';

      this.dialogService.showOkDialog({
        title: 'Account Error',
        text: message,
        base: true,
        blackout: true,
        primaryAction: 'Logout',
        additionalInfo: this.userState.additionalInfo(),
        closeCallback: () => {
          // eslint-disable-next-line rxjs/no-ignored-observable
          this.authService.logout({
            account: this.authService.instance.getActiveAccount(),
          });
        },
      });
    });
  }

  @HostListener('window:scroll')
  updateTopOffset(): void {
    const mainRef = this.mainRef();

    if (!mainRef) {
      return;
    }

    const topOffset = pxToRem(
      mainRef.nativeElement.getBoundingClientRect().top,
    );

    document.body.style.setProperty(
      '--procure-main-top-offset',
      `${topOffset > 0 ? topOffset : 0}rem`,
    );
  }

  ngOnInit(): void {
    this.initializationInProgress = true;

    this.isIframe = BrowserUtils.isInIframe() || BrowserUtils.isInPopup();

    this.showBrowserInfo();

    this.router.events
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((event) => {
        const endLoading =
          event instanceof NavigationEnd ||
          event instanceof NavigationCancel ||
          event instanceof NavigationError;

        if (event instanceof ActivationStart) {
          this.isLoading = true;
        } else if (endLoading) {
          this.isLoading = false;
          this.initAuthentication();
        }
      });
  }

  ngOnDestroy(): void {
    this.signalrService.disconnectHub();
  }

  login(): void {
    // eslint-disable-next-line rxjs/no-ignored-observable
    this.authService.loginRedirect({
      ...this.msalGuardConfig.authRequest,
    } as RedirectRequest);
  }

  private setLoginDisplay(): void {
    this.loginDisplay = this.authService.instance.getAllAccounts().length === 0;
  }

  private showBrowserInfo(): void {
    const versionAndBuild = `Version ${AppComponent.version} | Build ${AppComponent.buildNumber}`;
    const url = 'https://yoo.digital';

    const args = [
      `\n%cABB Procure\n\n%c${versionAndBuild}\n\nMade with %c♥%c | ${url}\n`,
      'color: #ff000f; font-weight: 700; font-size: 2em; text-shadow: 1px 1px 0px white, 1px -1px 0px white, -1px 1px 0px white, -1px -1px 0px white;',
      'color: #262626; background: #fff',
      'color: #ff2424; background: #fff',
      'color: #262626; background: #fff',
    ];

    window.console.log(...args);
  }

  /**
   * If no active account set but there are accounts signed in, sets first account to active account
   * To use active account set here, subscribe to inProgress$ first in your component
   */
  private checkAndSetActiveAccount(): void {
    const activeAccount = this.authService.instance.getActiveAccount();

    if (
      !activeAccount &&
      this.authService.instance.getAllAccounts().length === 0
    ) {
      return;
    }

    const accounts = this.authService.instance.getAllAccounts();
    this.authService.instance.setActiveAccount(accounts[0]);

    this.initializationInProgress = false;
    this.signalrService.connect();
  }

  private clearCachesOnNewDeploy(): void {
    if (!environment.production || !localStorage || !caches) {
      return;
    }

    const key = `${AppComponent.version}-${AppComponent.buildNumber}`;

    if (localStorage.getItem('version') === key) {
      return;
    }

    localStorage.removeItem('lookupData');
    localStorage.removeItem('preferences');

    caches.keys().then((keys) => {
      keys.forEach((item) => {
        caches.delete(item);
      });
    });

    localStorage.setItem('version', key);

    window.location.reload();
  }

  private initAuthentication(): void {
    if (!this.initializationInProgress) {
      return;
    }

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS,
        ),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        this.authService.instance.setActiveAccount(payload.account);
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None,
        ),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();

        if (!this.loginDisplay) {
          return;
        }

        this.login();
      });
  }
}
