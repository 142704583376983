import { BaseModel } from '@abb-procure/data';
import { RequestForRequestForQuotationSearchIndexDefinitionSearchDocumentResult } from '../types/request-for-request-for-quotation-search-index-definition-search-document-result.type';
import { RequestForRequestForQuotationSearchIndexDefinitionSearchDocumentItemResultModel } from './request-for-request-for-quotation-search-index-definition-search-document-item-result.model';

export class RequestForRequestForQuotationSearchIndexDefinitionSearchDocumentResultModel extends BaseModel {
  readonly count: number | null;
  readonly values: RequestForRequestForQuotationSearchIndexDefinitionSearchDocumentItemResultModel[];

  constructor(
    result: RequestForRequestForQuotationSearchIndexDefinitionSearchDocumentResult,
  ) {
    super();

    this.count = result.count;

    this.values = result.values
      .map((value) => new RequestForRequestForQuotationSearchIndexDefinitionSearchDocumentItemResultModel(value));
  }
}
