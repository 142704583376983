import { ErrorHandlerService } from '@abb-procure/error-handling';
import { HttpResponse } from '@angular/common/http';
import { DestroyRef, Injectable, inject } from '@angular/core';
import { OperatorFunction, catchError, of, throwError } from 'rxjs';
import { BaseApiService } from './base-api.service';

export type FileResponse = string | Blob | HttpResponse<Blob> | null;

@Injectable({
  providedIn: 'root',
})
export abstract class ApiService {
  protected readonly baseApiService = inject(BaseApiService);
  protected readonly errorHandlerService = inject(ErrorHandlerService);
  protected readonly destroyRef = inject(DestroyRef);

  protected get$: typeof this.baseApiService.get$ =
    this.baseApiService.get$.bind(this.baseApiService);

  protected get: typeof this.baseApiService.get = this.baseApiService.get.bind(
    this.baseApiService,
  );

  protected post$: typeof this.baseApiService.post$ =
    this.baseApiService.post$.bind(this.baseApiService);

  protected post: typeof this.baseApiService.post =
    this.baseApiService.post.bind(this.baseApiService);

  protected put$: typeof this.baseApiService.put$ =
    this.baseApiService.put$.bind(this.baseApiService);

  protected put: typeof this.baseApiService.put = this.baseApiService.put.bind(
    this.baseApiService,
  );

  protected delete$: typeof this.baseApiService.delete$ =
    this.baseApiService.delete$.bind(this.baseApiService);

  protected delete: typeof this.baseApiService.delete =
    this.baseApiService.delete.bind(this.baseApiService);

  protected downloadBlob$: typeof this.baseApiService.downloadBlob$ =
    this.baseApiService.downloadBlob$.bind(this.baseApiService);

  protected downloadBlob: typeof this.baseApiService.downloadBlob =
    this.baseApiService.downloadBlob.bind(this.baseApiService);

  protected catchError$ = <T>(
    text: string,
    propagateErrors: boolean = false,
  ): OperatorFunction<T, T | null> =>
    catchError((error: unknown) => {
      if (propagateErrors) {
        return throwError(() => error);
      }

      this.errorHandlerService.handleError(error, {
        text,
      });

      return of(null);
    });

  protected catchError = <T>(
    error: Error,
    text: string,
    propagateErrors: boolean = false,
  ): T | null => {
    if (propagateErrors) {
      throw error;
    }

    this.errorHandlerService.handleError(error, {
      text,
    });

    return null;
  };
}
