import { nullable } from '@abb-procure/api';
import * as t from 'io-ts';
import { RequestForRequestForQuotationSearchIndexDefinition } from './request-for-request-for-quotation-search-index-definition.type';

const RequestForRequestForQuotationSearchIndexDefinitionSearchDocumentItemResult = t.type({
  value: RequestForRequestForQuotationSearchIndexDefinition,
  highlights: nullable(t.record(t.string, t.array(t.string))),
  searchScore: nullable(t.number),
}, 'RequestForRequestForQuotationSearchIndexDefinitionSearchDocumentItemResult');

type RequestForRequestForQuotationSearchIndexDefinitionSearchDocumentItemResult = t.TypeOf<
  typeof RequestForRequestForQuotationSearchIndexDefinitionSearchDocumentItemResult
>;
export { RequestForRequestForQuotationSearchIndexDefinitionSearchDocumentItemResult };
