import { nullable } from '@abb-procure/api';
import * as t from 'io-ts';
import { ContractualMilestone } from '../contractual-milestone';
import { ContractualMilestoneStatus } from '../contractual-milestone-status';
import { CostAllocation } from '../cost-allocation.type';
import { DefaultCriteriaDimension } from '../default-criteria-dimension.type';
import { DeliveryDestination } from '../delivery-destination.type';
import { EntityStatus } from '../entity-status.type';
import { IdNameCode } from '../id-name-code.type';
import { IdNameDescription } from '../id-name-description.type';
import { IdName } from '../id-name.type';
import { ResourceVariables } from '../management/resources/resource-variables.type';
import { OfferedLeadTime } from '../offered-lead-time.type';
import { OrderAcknowledgementReview } from '../order-acknowledgement-review.type';
import { RequirementFields } from '../requirements-fields.type';
import { SupplierSendType } from '../supplier-send-type.type';
import { SupplierType } from '../supplier-type.type';
import { TermsAndConditionsCategory } from '../terms-and-conditions-category.type';

const LookupDataResponse = t.type(
  {
    eTag: t.string,
    countries: nullable(t.array(IdNameCode)),
    currencies: nullable(t.array(IdNameCode)),
    incotermsTypes: nullable(t.array(IdNameCode)),
    paymentTerms: nullable(t.array(IdName)),
    termsAndConditionCategories: nullable(t.array(TermsAndConditionsCategory)),
    wbsCodes: nullable(t.array(IdName)),
    offeredLeadTimes: nullable(t.array(OfferedLeadTime)),
    deliveryDestinationTypes: nullable(t.array(DeliveryDestination)),
    furtherDeliveryDestinationTypes: nullable(t.array(DeliveryDestination)),
    costAllocationTypes: nullable(t.array(CostAllocation)),
    supplierSendTypes: nullable(t.array(SupplierSendType)),
    orderAcknowledgementReviews: nullable(t.array(OrderAcknowledgementReview)),
    projectProcurementTypes: nullable(t.array(IdName)),
    projectClassifications: nullable(t.array(IdName)),
    projectTypeSupplies: nullable(t.array(IdName)),
    projectStatuses: nullable(t.array(EntityStatus)),
    projectInExecutionSubstatuses: nullable(t.array(IdName)),
    requestForRequestForQuotationStatuses: nullable(t.array(EntityStatus)),
    templateRequestForRequestForQuotationStatuses: nullable(
      t.array(EntityStatus),
    ),
    categoryStatuses: nullable(t.array(EntityStatus)),
    requestForQuotationStatuses: nullable(t.array(EntityStatus)),
    quotationStatuses: nullable(t.array(EntityStatus)),
    purchaseRequisitionStatuses: nullable(t.array(EntityStatus)),
    contractualMilestones: nullable(t.array(ContractualMilestone)),
    contractualMilestoneStatuses: nullable(t.array(ContractualMilestoneStatus)),
    supplierTypes: nullable(t.array(SupplierType)),
    requestForRequestForQuotationClassifications: nullable(t.array(IdName)),
    requestForRequestForQuotationSubstatuses: nullable(t.array(IdName)),
    quotationSubstatuses: nullable(t.array(IdName)),
    requestForQuotationSubstatuses: nullable(t.array(IdName)),
    requirementFields: nullable(RequirementFields),
    notificationReminderExecutionPeriod: nullable(t.array(IdName)),
    categoryDimensionTaskStatuses: nullable(t.array(EntityStatus)),
    strategyDimensionLevers: nullable(t.array(IdNameDescription)),
    mdfCodes: nullable(t.array(IdNameCode)),
    defaultDimensions: nullable(t.array(DefaultCriteriaDimension)),
    resourceVariables: nullable(t.array(ResourceVariables)),
    supplierEvaluationEvaluatorRoles: nullable(t.array(IdName)),
    supplierPerformanceEvaluationStatuses: nullable(t.array(EntityStatus)),
  },
  'LookupDataResponse',
);

type LookupDataResponse = t.TypeOf<typeof LookupDataResponse>;
export { LookupDataResponse };
