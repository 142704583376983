import { nullable } from '@abb-procure/api';
import * as t from 'io-ts';
import { Requirement } from './requirement.type';

const RequirementFields = t.type(
  {
    commercial: nullable(t.array(Requirement)),
    logistical: nullable(t.array(Requirement)),
    technical: nullable(t.array(Requirement)),
  },
  'RequirementFields',
);

type RequirementFields = t.TypeOf<typeof RequirementFields>;
export { RequirementFields };
