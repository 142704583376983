import {
  DestroyRef, Injectable, inject,
} from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { ErrorHandlerService } from '@abb-procure/error-handling';
import { DialogService, FingerprintService } from '@abb-procure/common';
import { SnackbarService } from '@abb-procure/snackbar';
import { MsalService } from '@azure/msal-angular';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ENVIRONMENT_CONFIG } from '@abb-procure/constants';
import { SilentRequest } from '@azure/msal-browser';
import {
  BehaviorSubject, firstValueFrom, map, take,
} from 'rxjs';
import { SessionsDialogService } from './sessions-dialog.service';

const LOGOUT_TIMEOUT = 10000;

enum MessageKeys {
  NotificationsKey = 'Notifications',
  TerminatedSessionsKey = 'TerminatedSessions',
  ConcurrentSessionsKey = 'ConcurrentSessions',
  ExpiredSessionsKey = 'ExpiredSessions',
  WarningExpirationSessionsKey = 'WarningExpirationSessions',
}

@Injectable({
  providedIn: 'root',
})
export class SignalrService {
  messages$;

  private hub?: signalR.HubConnection;
  private userIsNavigatingAway: boolean = false;
  private messagesSubject$ = new BehaviorSubject<string | null>(null);
  private readonly environmentConfig = inject(ENVIRONMENT_CONFIG);
  private readonly errorHandlerService = inject(ErrorHandlerService);
  private readonly accessService = inject(MsalService);
  private readonly snackbarService = inject(SnackbarService);
  private readonly sessionsDialogService = inject(SessionsDialogService);
  private readonly dialogService = inject(DialogService);
  private readonly fingerprintService = inject(FingerprintService);
  private readonly destroyRef = inject(DestroyRef);

  constructor() {
    this.messages$ = this.messagesSubject$.asObservable();
  }

  connect(): void {
    if (!this.environmentConfig.production) {
      return;
    }

    this.hub = new signalR.HubConnectionBuilder()
      .configureLogging(signalR.LogLevel.Error)
      .withUrl(`${this.environmentConfig.apiEndpoint}application?Fingerprint=${this.fingerprintService.fingerprint}`, {
        accessTokenFactory: () => this.getToken(),
        headers: {
          Fingerprint: this.fingerprintService.fingerprint,
        },
      })
      .withAutomaticReconnect()
      .build();

    this.connectHub();

    this.hub.onreconnected(() => {
      this.log('Reconnected');
    });

    this.hub.onclose(() => {
      if (this.userIsNavigatingAway) {
        return;
      }

      this.connect();

      this.log('Disconnected, attempting to reconnect');
    });
  }

  async connectHub(): Promise<void> {
    try {
      await this.hub?.start();

      this.log('Connection started');
      this.listen();
    } catch (err) {
      this.errorHandlerService.trackError(err);
    }
  }

  async disconnectHub(): Promise<void> {
    this.userIsNavigatingAway = true;

    try {
      await this.hub?.stop();
      this.log('Connection stopped');
    } catch (err) {
      this.errorHandlerService.trackError(err);
    }
  }

  private listen(): void {
    if (!this.hub) {
      return;
    }

    this.hub.on(MessageKeys.NotificationsKey, () => this.handleNotifications());
    this.hub.on(MessageKeys.ConcurrentSessionsKey, () => this.handleConcurrentSessions());
    this.hub.on(MessageKeys.ExpiredSessionsKey, () => this.handleExpiredSessions());
    this.hub.on(MessageKeys.TerminatedSessionsKey, () => this.handleTerminatedSessions());
    this.hub.on(MessageKeys.WarningExpirationSessionsKey, () => this.handleWarningExpirationSessions());
  }

  private handleNotifications(): void {
    this.handleMessage(MessageKeys.NotificationsKey);
  }

  private handleTerminatedSessions(): void {
    this.handleMessage(MessageKeys.TerminatedSessionsKey);

    const dialog = this.dialogService.showOkDialog({
      title: 'Session Terminated',
      text: 'Your session has been terminated since there is another active session.',
    });

    dialog.afterClosed()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.logout();
      });

    setTimeout(() => {
      this.logout();
    }, LOGOUT_TIMEOUT);
  }

  private handleExpiredSessions(): void {
    this.handleMessage(MessageKeys.ExpiredSessionsKey);

    const dialog = this.dialogService.showOkDialog({
      title: 'Session Expired',
      text: 'Your session has expired. Please login again.',
    });

    dialog.afterClosed()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.logout();
      });

    setTimeout(() => {
      this.logout();
    }, LOGOUT_TIMEOUT);
  }

  private handleConcurrentSessions(): void {
    this.handleMessage(MessageKeys.ConcurrentSessionsKey);

    this.snackbarService.showInfoSnackbar({
      text: 'You have logged in from another device.',
    });
  }

  private handleWarningExpirationSessions(): void {
    this.handleMessage(MessageKeys.WarningExpirationSessionsKey);
    this.sessionsDialogService.openRenewDialog();
  }

  private logout(): void {
    // eslint-disable-next-line rxjs/no-ignored-observable
    this.accessService.logout({ account: this.accessService.instance.getActiveAccount() });
  }

  private getToken(): Promise<string> {
    return firstValueFrom(
      this.accessService.acquireTokenSilent({
        account: this.accessService.instance.getActiveAccount(),
        scopes: [`api://${this.environmentConfig.msalConfig.apiEndpointId}/read_as_user`],
      } as SilentRequest)
        .pipe(
          map((token) => token.accessToken),
          take(1),
          takeUntilDestroyed(this.destroyRef),
        ),
    );
  }

  private handleMessage(message: string): void {
    this.messagesSubject$.next(message);
    this.log(`New message "${message}"`);
  }

  private log(message: string): void {
    if (!this.environmentConfig.signalrLoggingEnabled) {
      return;
    }

    // eslint-disable-next-line no-console
    console.log(`SignalR: ${message}`);
  }
}
