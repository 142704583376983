import { nullable } from '@abb-procure/api';
import * as t from 'io-ts';
import { ApplicationNotification } from './application-notification.type';

const GlobalNotificationResponse = t.type(
  {
    globalNotification: nullable(ApplicationNotification),
    privacyNotification: ApplicationNotification,
  },
  'GlobalNotificationResponse',
);

type GlobalNotificationResponse = t.TypeOf<typeof GlobalNotificationResponse>;
export { GlobalNotificationResponse };
