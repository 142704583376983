import { nullable } from '@abb-procure/api';
import * as t from 'io-ts';
import { RequestForQuotationSearchIndexDefinition } from './request-for-quotation-search-index-definition.type';

const RequestForQuotationSearchIndexDefinitionSearchDocumentItemResult = t.type({
  value: RequestForQuotationSearchIndexDefinition,
  highlights: nullable(t.record(t.string, t.array(t.string))),
  searchScore: nullable(t.number),
}, 'RequestForQuotationSearchIndexDefinitionSearchDocumentItemResult');

type RequestForQuotationSearchIndexDefinitionSearchDocumentItemResult = t.TypeOf<
  typeof RequestForQuotationSearchIndexDefinitionSearchDocumentItemResult
>;
export { RequestForQuotationSearchIndexDefinitionSearchDocumentItemResult };
