import {
  accessGuard,
  caseInsensitiveMatcher,
  entityIdMatcher,
} from '@abb-procure/common';
import { EntityIdAliasPrefixes } from '@abb-procure/constants';
import { Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';

/* eslint-disable @typescript-eslint/explicit-function-return-type */

export const routes: Routes = [
  {
    path: '',
    canMatch: [MsalGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('@abb-procure/dashboard'),
      },
      {
        matcher: caseInsensitiveMatcher('search'),
        loadChildren: () => import('@abb-procure/entity-search'),
      },
      {
        matcher: entityIdMatcher(EntityIdAliasPrefixes.Project),
        data: {
          mode: 'show',
          entityType: EntityIdAliasPrefixes.Project,
        },
        loadChildren: () => import('@abb-procure/project'),
      },
      {
        matcher: caseInsensitiveMatcher(EntityIdAliasPrefixes.Project),
        children: [
          {
            matcher: caseInsensitiveMatcher('add'),
            data: {
              mode: 'add',
              entityType: EntityIdAliasPrefixes.Project,
            },
            canMatch: [accessGuard('canAddProject')],
            loadChildren: () => import('@abb-procure/project'),
          },
        ],
      },
      {
        matcher: entityIdMatcher(EntityIdAliasPrefixes.PurchaseRequisition),
        data: {
          mode: 'show',
          entityType: EntityIdAliasPrefixes.PurchaseRequisition,
        },
        loadChildren: () => import('@abb-procure/purchase-requisition'),
      },
      {
        matcher: entityIdMatcher(EntityIdAliasPrefixes.Rfq),
        data: {
          mode: 'show',
          entityType: EntityIdAliasPrefixes.Rfq,
        },
        loadChildren: () => import('@abb-procure/request-for-quotation'),
      },
      {
        matcher: entityIdMatcher(EntityIdAliasPrefixes.RfRFQ, true),
        data: {
          mode: 'show',
          entityType: EntityIdAliasPrefixes.RfRFQ,
        },
        loadChildren: () => import('@abb-procure/request-for-rfq'),
      },
      {
        matcher: caseInsensitiveMatcher(EntityIdAliasPrefixes.RfRFQ),
        children: [
          {
            matcher: caseInsensitiveMatcher('add'),
            data: {
              mode: 'add',
              entityType: EntityIdAliasPrefixes.RfRFQ,
            },
            canMatch: [accessGuard('canAddRequestForRequestForQuotation')],
            loadChildren: () => import('@abb-procure/request-for-rfq'),
          },
        ],
      },
      {
        matcher: entityIdMatcher(EntityIdAliasPrefixes.Category),
        data: {
          mode: 'show',
          entityType: EntityIdAliasPrefixes.Category,
        },
        loadChildren: () => import('@abb-procure/category'),
      },
      {
        matcher: caseInsensitiveMatcher(EntityIdAliasPrefixes.Category),
        children: [
          {
            matcher: caseInsensitiveMatcher('add'),
            data: {
              mode: 'add',
              entityType: EntityIdAliasPrefixes.Category,
            },
            canMatch: [accessGuard('canManageCategories')],
            loadChildren: () => import('@abb-procure/category'),
          },
        ],
      },
      {
        matcher: entityIdMatcher(EntityIdAliasPrefixes.TemplateRfrfq),
        data: {
          mode: 'show',
          entityType: EntityIdAliasPrefixes.TemplateRfrfq,
        },
        loadChildren: () => import('@abb-procure/template'),
      },
      {
        matcher: caseInsensitiveMatcher(EntityIdAliasPrefixes.TemplateRfrfq),
        children: [
          {
            matcher: caseInsensitiveMatcher('add'),
            data: {
              mode: 'add',
              entityType: EntityIdAliasPrefixes.TemplateRfrfq,
            },
            canMatch: [accessGuard('canManageTemplates')],
            loadChildren: () => import('@abb-procure/template'),
          },
        ],
      },
      {
        matcher: entityIdMatcher(EntityIdAliasPrefixes.Quotation, true),
        data: {
          mode: 'show',
          entityType: EntityIdAliasPrefixes.Quotation,
        },
        loadChildren: () => import('@abb-procure/quotation'),
      },
      {
        matcher: caseInsensitiveMatcher(EntityIdAliasPrefixes.Quotation),
        children: [
          {
            matcher: caseInsensitiveMatcher('add'),
            data: {
              mode: 'add',
              entityType: EntityIdAliasPrefixes.Quotation,
            },
            loadChildren: () => import('@abb-procure/quotation'),
          },
        ],
      },
      {
        matcher: entityIdMatcher(EntityIdAliasPrefixes.TemplateSPE),
        data: {
          mode: 'show',
          entityType: EntityIdAliasPrefixes.TemplateSPE,
        },
        loadChildren: () => import('@abb-procure/template-spe'),
      },
      {
        matcher: caseInsensitiveMatcher(EntityIdAliasPrefixes.TemplateSPE),
        children: [
          {
            matcher: caseInsensitiveMatcher('add'),
            data: {
              mode: 'add',
              entityType: EntityIdAliasPrefixes.TemplateSPE,
            },
            loadChildren: () => import('@abb-procure/template-spe'),
          },
        ],
      },
      {
        matcher: caseInsensitiveMatcher(`${EntityIdAliasPrefixes.GeneralTSPE}`),
        children: [
          {
            matcher: caseInsensitiveMatcher('add'),
            data: {
              mode: 'add',
              entityType: EntityIdAliasPrefixes.GeneralTSPE,
            },
            loadChildren: () => import('@abb-procure/template-spe'),
          },
        ],
      },
      {
        matcher: caseInsensitiveMatcher(
          `${EntityIdAliasPrefixes.GeneralTSPE}/:entityId`,
        ),
        data: {
          mode: 'show',
          entityType: EntityIdAliasPrefixes.GeneralTSPE,
        },
        loadChildren: () => import('@abb-procure/template-spe'),
      },
      {
        matcher: entityIdMatcher(EntityIdAliasPrefixes.Survey),
        data: {
          mode: 'show',
          entityType: EntityIdAliasPrefixes.Survey,
        },
        loadChildren: () => import('@abb-procure/survey'),
      },
      {
        matcher: caseInsensitiveMatcher(EntityIdAliasPrefixes.Survey),
        children: [
          {
            matcher: caseInsensitiveMatcher('add'),
            data: {
              mode: 'add',
              entityType: EntityIdAliasPrefixes.Survey,
            },
            loadChildren: () => import('@abb-procure/survey'),
          },
        ],
      },
      {
        matcher: entityIdMatcher(EntityIdAliasPrefixes.Survey),
        children: [
          {
            matcher: caseInsensitiveMatcher('survey-evaluation'),
            loadChildren: () => import('@abb-procure/survey-evaluation'),
          },
        ],
      },
      {
        matcher: entityIdMatcher(EntityIdAliasPrefixes.SupplierEvaluation),
        loadChildren: () => import('@abb-procure/evaluation'),
      },
      // {
      //   matcher: caseInsensitiveMatcher(EntityIdAliasPrefixes.PurchaseRequisition),
      //   children: [
      //     {
      //       matcher: caseInsensitiveMatcher('add'),
      //       data: {
      //         mode: 'add',
      //         entityType: EntityIdAliasPrefixes.PurchaseRequisition,
      //       },
      //       canMatch: [accessGuard('canAddPurchaseRequisition')],
      //       loadChildren: () => import('./route-modules/purchase-requisition/purchase-requisition.routing'),
      //     },
      //   ],
      // },
      {
        matcher: caseInsensitiveMatcher('user'),
        loadChildren: () => import('@abb-procure/user'),
      },
      {
        matcher: caseInsensitiveMatcher('management'),
        loadChildren: () => import('@abb-procure/management'),
      },
      {
        path: 'supplier/:entityId',
        redirectTo: 'suppliers/:entityId',
      },
      {
        matcher: caseInsensitiveMatcher('suppliers'),
        loadChildren: () => import('@abb-procure/suppliers'),
        canMatch: [accessGuard('canManageSuppliers')],
      },
      {
        matcher: caseInsensitiveMatcher('suppliers/:entityId'),
        loadChildren: () => import('@abb-procure/supplier'),
      },
      {
        matcher: caseInsensitiveMatcher('suppliers/:supplierId/strategies'),
        children: [
          {
            matcher: caseInsensitiveMatcher('add'),
            data: {
              mode: 'add',
            },
            loadChildren: () => import('@abb-procure/strategy'),
          },
          {
            matcher: caseInsensitiveMatcher(':strategyId'),
            data: {
              mode: 'show',
            },
            loadChildren: () => import('@abb-procure/strategy'),
          },
        ],
      },

      {
        matcher: caseInsensitiveMatcher('categories'),
        loadChildren: () => import('@abb-procure/categories'),
      },
      {
        matcher: caseInsensitiveMatcher('templates'),
        loadChildren: () => import('@abb-procure/templates'),
        canMatch: [accessGuard('canManageTemplates')],
      },
      {
        matcher: caseInsensitiveMatcher('admin'),
        loadChildren: () => import('@abb-procure/admin'),
      },
      {
        matcher: caseInsensitiveMatcher('monitoring'),
        loadChildren: () => import('@abb-procure/monitoring'),
      },
      {
        matcher: caseInsensitiveMatcher('notifications'),
        loadChildren: () => import('@abb-procure/notifications'),
      },
      {
        matcher: caseInsensitiveMatcher('organisation'),
        canMatch: [accessGuard('canViewOrganisation')],
        loadChildren: () => import('@abb-procure/organisation'),
      },
      {
        path: 'no-access',
        title: 'No Access',
        loadChildren: () => import('@abb-procure/no-access'),
      },
    ],
  },
  {
    path: '**',
    canActivate: [MsalGuard],
    title: 'Page Not Found',
    loadChildren: () => import('@abb-procure/not-found'),
  },
];
