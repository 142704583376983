import {
  ButtonComponent,
  CardComponent,
  GlobalNotificationComponent,
} from '@abb-procure/common';
import {
  ApplicationNotificationModel,
  GlobalNotificationApiService,
  NotificationApiService,
} from '@abb-procure/data';
import { animate, style, transition, trigger } from '@angular/animations';
import { Component, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { interval } from 'rxjs';

@Component({
  selector: 'procure-app-notifications',
  templateUrl: './app-notifications.component.html',
  styleUrl: './app-notifications.component.scss',
  standalone: true,
  imports: [GlobalNotificationComponent, ButtonComponent, CardComponent],
  animations: [
    trigger('privacy', [
      transition(':enter', animate(200, style({ opacity: 1 }))),
      transition(':leave', animate(200, style({ opacity: 0 }))),
    ]),
  ],
})
export class AppNotificationsComponent {
  protected privacyNotification?: ApplicationNotificationModel;
  protected globalNotification?: ApplicationNotificationModel | null = null;

  private readonly notificationApiService = inject(NotificationApiService);

  private readonly globalNotificationApiService = inject(
    GlobalNotificationApiService,
  );

  private readonly destroyRef = inject(DestroyRef);

  constructor() {
    this.globalNotificationApiService.notificationUpdated$
      .pipe(takeUntilDestroyed())
      .subscribe((response) => {
        if (!response) {
          return;
        }

        this.globalNotification = response;
      });

    this.fetchData();

    // Check notifications every minute.

    interval(60 * 1000)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.fetchData());
  }

  protected onAccept(): void {
    if (!this.privacyNotification) {
      return;
    }

    this.notificationApiService
      .confirmNotification$(this.privacyNotification.notificationId)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((response) => {
        if (!response || !this.privacyNotification) {
          return;
        }

        this.privacyNotification.display = false;
      });
  }

  private fetchData(): void {
    this.notificationApiService
      .getApplicationNotifications$()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((response) => {
        if (!response) {
          return;
        }

        this.privacyNotification = response.privacyNotification;
        this.globalNotification = response.globalNotification;
      });
  }
}
