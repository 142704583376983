import {
  Route,
  UrlMatcher,
  UrlMatchResult,
  UrlSegment,
  UrlSegmentGroup,
} from '@angular/router';

/**
 * Matches an URL case insensitive and enforces the originally defined case.
 * @internal
 */
function createMatcher(url: string): UrlMatcher {
  return (
    segments: UrlSegment[],
    _segmentGroup: UrlSegmentGroup,
    route: Route,
  ): UrlMatchResult | null => {
    const matchSegments = url.split('/');

    if (
      matchSegments.length > segments.length ||
      (matchSegments.length !== segments.length && route.pathMatch === 'full')
    ) {
      return null;
    }

    const consumed: UrlSegment[] = [];
    const posParams: Record<string, UrlSegment> = {};

    for (let index = 0; index < matchSegments.length; index += 1) {
      const matchSegment = matchSegments[index];

      if (matchSegment.startsWith(':')) {
        posParams[matchSegment.slice(1)] = segments[index];
        consumed.push(segments[index]);
      } else {
        const segment = segments[index];

        if (segment.path.toUpperCase() === matchSegment.toUpperCase()) {
          const sanitizedSegment = new UrlSegment(
            matchSegment,
            segment.parameters,
          );

          consumed.push(sanitizedSegment);
        } else {
          return null;
        }
      }
    }

    return { consumed, posParams };
  };
}

/**
 * Matches an URL case insensitive and enforces the originally defined case.
 */
export function caseInsensitiveMatcher(url: string): UrlMatcher {
  // Wrapper to enforce correct typing until underlying Angular type issues are solved.
  return createMatcher(url);
}
