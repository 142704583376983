import { nullable } from '@abb-procure/api';
import * as t from 'io-ts';
import { User } from './user.type';

const PeoplePickerUser = t.intersection(
  [
    User,
    t.type({
      department: nullable(t.string),
      avatarUrl: nullable(t.string),
    }),
  ],
  'PeoplePickerUser',
);

type PeoplePickerUser = t.TypeOf<typeof PeoplePickerUser>;
export { PeoplePickerUser };
