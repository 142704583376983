import { DestroyRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Observable } from 'rxjs';

type DialogRef = {
  keydownEvents: () => Observable<KeyboardEvent>;
  backdropClick: () => Observable<MouseEvent>;
};

export const escapeDialog = (
  ref: DialogRef,
  componentRef: DestroyRef,
  onCancel: () => void,
): void => {
  ref
    .keydownEvents()
    .pipe(takeUntilDestroyed(componentRef))
    .subscribe((event) => {
      if (event.key === 'Escape') {
        onCancel();
      }
    });

  ref
    .backdropClick()
    .pipe(takeUntilDestroyed(componentRef))
    .subscribe(() => {
      onCancel();
    });
};
