import { nullable } from '@abb-procure/api';
import * as t from 'io-ts';
import { RequestForQuotationSearchIndexDefinitionSearchDocumentItemResult } from './request-for-quotation-search-index-definition-search-document-item-result.type';

const RequestForQuotationSearchIndexDefinitionSearchDocumentResult = t.type({
  count: nullable(t.number),
  values: t.array(RequestForQuotationSearchIndexDefinitionSearchDocumentItemResult),
}, 'RequestForQuotationSearchIndexDefinitionSearchDocumentResult');

type RequestForQuotationSearchIndexDefinitionSearchDocumentResult = t.TypeOf<
  typeof RequestForQuotationSearchIndexDefinitionSearchDocumentResult
>;
export { RequestForQuotationSearchIndexDefinitionSearchDocumentResult };
