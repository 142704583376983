import * as t from 'io-ts';
import { PaginatedResponse } from '@abb-procure/data';
import { nullable } from '@abb-procure/api';
import { NotificationInboxMessage } from './notification-inbox-message.type';

const NotificationInboxViewResponse = t.intersection([
  PaginatedResponse,
  t.type({
    items: nullable(t.array(NotificationInboxMessage)),
    filteredCount: t.number,
    unreadCount: t.number,
  }),
], 'NotificationInboxViewResponse');

type NotificationInboxViewResponse = t.TypeOf<typeof NotificationInboxViewResponse>;
export { NotificationInboxViewResponse };
