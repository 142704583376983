import { IdName } from '../types/id-name.type';
import { BaseModel } from './base.model';

export class IdNameModel extends BaseModel implements IdName {
  readonly id: number;
  readonly name: string | null;

  constructor(data: IdName) {
    super();

    this.id = data.id;
    this.name = data.name;
  }

  getDisplay(): string {
    return `${this.name}`;
  }
}
