import { ApiService } from '@abb-procure/api';
import { Injectable } from '@angular/core';
import { boolean as ioBoolean } from 'io-ts';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApplicationNotificationModel } from '../models/application-notification.model';
import { ApplicationNotification } from '../types/application-notification.type';

@Injectable({
  providedIn: 'root',
})
export class GlobalNotificationApiService extends ApiService {
  readonly notificationUpdated$: Observable<ApplicationNotification | null>;

  private notificationUpdatedSubject$: BehaviorSubject<ApplicationNotification | null> =
    new BehaviorSubject(null);

  constructor() {
    super();

    this.notificationUpdated$ = this.notificationUpdatedSubject$.asObservable();
  }

  async getNotification(): Promise<ApplicationNotification | null> {
    try {
      const response = await this.get<ApplicationNotification>(
        'Management/Notification',
        ApplicationNotification,
      );

      return response != null
        ? new ApplicationNotificationModel(response)
        : null;
    } catch (error) {
      return this.catchError(
        error,
        'There was an issue while retrieving the notification data.',
      );
    }
  }

  async updateNotification(
    notification: ApplicationNotification,
  ): Promise<ApplicationNotificationModel | null> {
    try {
      await this.put<boolean | null, ApplicationNotification>(
        'Management/Notification',
        ioBoolean,
        notification,
      );

      const response = await this.getNotification();

      if (response) {
        this.notificationUpdatedSubject$.next(response);
      }

      return response != null
        ? new ApplicationNotificationModel(response)
        : null;
    } catch (error) {
      return this.catchError(
        error,
        'There was an issue while updating the notification data.',
      );
    }
  }
}
