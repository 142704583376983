import { nullable } from '@abb-procure/api';
import * as t from 'io-ts';

const Preference = t.type(
  {
    key: nullable(t.string),
    value: nullable(t.string),
  },
  'Preference',
);

type Preference = t.TypeOf<typeof Preference>;
export { Preference };
