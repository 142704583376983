import { DateString, nullable } from '@abb-procure/api';
import * as t from 'io-ts';

const ApplicationNotification = t.type(
  {
    notificationId: t.string,
    value: nullable(t.string),
    validFrom: DateString,
    validUntil: DateString,
    enabled: t.boolean,
    display: t.boolean,
  },
  'ApplicationNotification',
);

type ApplicationNotification = t.TypeOf<typeof ApplicationNotification>;
export { ApplicationNotification };
