import { DateString, nullable } from '@abb-procure/api';
import * as t from 'io-ts';
import { EntityStatus } from './entity-status.type';
import { GuidNameCode } from './guid-name-code.type';

const OrganisationReview = t.type(
  {
    unit: nullable(GuidNameCode),
    reviewStatus: nullable(EntityStatus),
    reviewed: nullable(DateString),
    nextReview: nullable(DateString),
  },
  'OrganisationReview',
);

type OrganisationReview = t.TypeOf<typeof OrganisationReview>;
export { OrganisationReview };
