import { UserState } from '@abb-procure/data';
import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';

export const impersonationInterceptor = (
  request: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> => {
  const state = inject(UserState);

  const isSubstitute = state.isSubstitute();
  const current = state.current();

  if (!isSubstitute || !current) {
    return next(request);
  }

  // Clone the request and replace the original headers with
  // cloned headers, updated with the new header params.
  const impersonatingRequest = request.clone({
    headers: request.headers.set('Mystique', current.mystiqueKey),
  });

  return next(impersonatingRequest);
};
