import { LoginUserService, UserState } from '@abb-procure/data';
import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { hasPermissions } from '../utilities/user-permissions.util';

export const accessGuard =
  (permissions: string | string[]): CanActivateFn =>
  () => {
    const loginUserService = inject(LoginUserService);
    const router = inject(Router);

    const user = inject(UserState).current();

    const user$ = user ? of(user) : loginUserService.getLoginUser$();

    return user$.pipe(
      switchMap((u) => {
        if (!u) {
          return of(false);
        }

        if (
          hasPermissions(
            u,
            typeof permissions === 'string' ? [permissions] : permissions,
          )
        ) {
          return of(true);
        }

        return of(router.createUrlTree(['/no-access'], {}));
      }),
      switchMap((result) =>
        typeof result === 'boolean'
          ? of(result)
          : of(false).pipe(
              tap(() => {
                router.navigateByUrl(result);
              }),
            ),
      ),
    );
  };
