import { DestroyRef, Injectable, OnDestroy, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { BehaviorSubject, Observable, Subject, combineLatest, of } from 'rxjs';
import {
  filter,
  finalize,
  map,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { CurrentUserModel } from '../models/current-user.model';
import { LookupDataState } from '../state/lookup-data.state';
import { UserState } from '../state/user.state';
import { CurrentUser } from '../types/current-user.type';
import { UserApiService } from './user-api.service';

@Injectable({
  providedIn: 'root',
})
export class LoginUserService implements OnDestroy {
  requestId: number = 0;
  readonly loginUser$: Observable<CurrentUser | null>;
  readonly substituteUser$: Observable<CurrentUser | null>;
  readonly currentUser$: Observable<CurrentUser | null>;
  readonly isAuthorized$: Observable<boolean>;
  readonly error: Error | undefined;

  get isImpersonatingUser(): boolean {
    return this.substituteUserSubject$.value != null;
  }

  private readonly substituteUserSubject$: BehaviorSubject<CurrentUser | null> =
    new BehaviorSubject(null);
  private readonly currentUserSubject$: BehaviorSubject<CurrentUser | null> =
    new BehaviorSubject(null);

  private readonly loginUserSubject$: BehaviorSubject<CurrentUserModel | null> =
    new BehaviorSubject(null);
  private loginUserRequest$: Observable<CurrentUserModel | null> | undefined;
  private readonly loginUserRequestAborter$: Subject<void> = new Subject();
  private readonly userService = inject(UserApiService);
  private readonly authService = inject(MsalService);
  private readonly msalBroadcastService = inject(MsalBroadcastService);
  private readonly userState = inject(UserState);
  private readonly destroyRef = inject(DestroyRef);
  private readonly lookupDataState = inject(LookupDataState);

  constructor() {
    this.loginUser$ = this.loginUserSubject$.asObservable();
    this.substituteUser$ = this.substituteUserSubject$.asObservable();
    this.currentUser$ = this.currentUserSubject$.asObservable();

    this.isAuthorized$ = this.loginUser$.pipe(map((user) => user != null));

    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None,
        ),
        switchMap(() => {
          if (this.authService.instance.getAllAccounts().length === 0) {
            return of(undefined);
          }

          return this.getLoginUser$();
        }),
        takeUntilDestroyed(),
      )
      .subscribe(() => {
        // nothing
      });

    combineLatest([this.loginUser$, this.substituteUser$])
      .pipe(
        map(([loginUser, substituteUser]) => substituteUser ?? loginUser),
        takeUntilDestroyed(),
      )
      .subscribe((currentUser) => {
        this.currentUserSubject$.next(currentUser);
      });
  }

  ngOnDestroy(): void {
    this.loginUserSubject$.complete();
    this.substituteUserSubject$.complete();
    this.currentUserSubject$.complete();
    this.loginUserRequestAborter$.complete();
  }

  /**
   * Tries to get the login user from the API, effectively checking for authorization.
   */
  getLoginUser$(): Observable<CurrentUserModel | null | undefined> {
    if (this.loginUserRequest$) {
      return this.loginUserRequest$;
    }

    const user = this.loginUserSubject$.value;

    if (user == null) {
      const loginUserRequest$ = this.authorizeLoginUser$().pipe(
        finalize(() => {
          this.loginUserRequest$ = undefined;
        }),
        takeUntilDestroyed(this.destroyRef),
      );

      this.loginUserRequest$ = loginUserRequest$;

      return this.loginUserRequest$;
    }

    return of(user);
  }

  /**
   * Logs into the application as a different user, replacing the current login user.
   */
  impersonateUser$(userId: number): Observable<CurrentUserModel | null> {
    if (
      this.loginUserSubject$.value &&
      this.loginUserSubject$.value.id === userId
    ) {
      // It's us. This would act as a logout...
      this.stopImpersonatingUser();
      return of(null);
    }

    return this.userService.getUser$(userId).pipe(
      tap((substituteUser) => {
        if (substituteUser == null) {
          this.stopImpersonatingUser();
          return;
        }

        this.setSubstituteUser(substituteUser);
      }),
      takeUntilDestroyed(this.destroyRef),
    );
  }

  /**
   * Returns to the previous login user.
   */
  stopImpersonatingUser(): void {
    this.clearSubstituteUser();
  }

  logout$(): Observable<void> {
    return this.authService.logout({
      account: this.authService.instance.getActiveAccount(),
    });
  }

  private authorizeLoginUser$(): Observable<CurrentUserModel | null> {
    const loginUserRequest$: Observable<CurrentUserModel | null> =
      this.userService.getUser$().pipe(
        map((user) => {
          if (user) {
            this.setLoginUser(user);
          } else {
            this.clearLoginUser();
          }

          this.lookupDataState.fetchSupportLink();

          return user;
        }),
      );

    return loginUserRequest$.pipe(takeUntil(this.loginUserRequestAborter$));
  }

  private setLoginUser(user: CurrentUserModel): void {
    this.loginUserSubject$.next(user);

    this.userState.setCurrentUser(user, false);
  }

  private clearLoginUser(): void {
    this.loginUserSubject$.next(null);

    if (this.substituteUserSubject$.value) {
      this.clearSubstituteUser();
    }
  }

  private setSubstituteUser(user: CurrentUserModel): void {
    this.substituteUserSubject$.next(user);

    this.userState.setCurrentUser(user, true);
  }

  private clearSubstituteUser(): void {
    this.substituteUserSubject$.next(null);

    this.userState.setCurrentUser(this.loginUserSubject$.value, false);
  }
}
