import {
  HttpErrorResponse,
  HttpEvent, HttpHandlerFn, HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';
import {
  Observable, catchError, throwError,
} from 'rxjs';
import { CustomHttpErrorResponse } from '@abb-procure/error-handling';
import { SessionsDialogService } from '../services/sessions-dialog.service';

export const concurrentSessionInterceptor = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> => {
  const sessionsDialogService = inject(SessionsDialogService);

  return next(req)
    .pipe(
      catchError((error: unknown) => {
        if (!(error instanceof HttpErrorResponse)) {
          return throwError(() => error);
        }

        if (error.status === 406) {
          sessionsDialogService.openSessionsDialog();
        }

        return throwError(() => new CustomHttpErrorResponse(error.name, error.message, {
          ...error,
          url: error.url ?? undefined,
        }));
      }),
    );
};
