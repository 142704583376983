<procure-global-notification [notification]="globalNotification" />
@if (privacyNotification?.display) {
  <div
    class="privacy"
    [@privacy]="'in'"
  >
    <procure-card class="privacy__content">
      <p
        class="privacy__text"
        [innerHTML]="privacyNotification?.value"
      ></p>
      <div class="privacy__buttons">
        <a
          class="privacy__button"
          href="https://global.abb/group/en"
          procure-button
        >
          Decline
        </a>
        <button
          class="privacy__button"
          procure-button
          color="primary"
          (click)="onAccept()"
        >
          Accept
        </button>
      </div>
    </procure-card>
  </div>
}
