import { AdditionalInfo } from '../types/additional-info.type';
import { UserAssignmentModel } from './user-assignment.model';

export class AdditionalInfoModel implements AdditionalInfo {
  readonly localDivisionAssignments: UserAssignmentModel[] | null = null;
  readonly localDivisionManagerAssignments: UserAssignmentModel[] | null = null;
  readonly hubManagerAssignments: UserAssignmentModel[] | null = null;
  readonly divisionManagerAssignments: UserAssignmentModel[] | null = null;

  constructor(additionalInfo: AdditionalInfo) {
    if (additionalInfo.localDivisionAssignments) {
      this.localDivisionAssignments =
        additionalInfo.localDivisionAssignments.map(
          (assignment) => new UserAssignmentModel(assignment),
        );
    }

    if (additionalInfo.localDivisionManagerAssignments) {
      this.localDivisionManagerAssignments =
        additionalInfo.localDivisionManagerAssignments.map(
          (assignment) => new UserAssignmentModel(assignment),
        );
    }

    if (additionalInfo.hubManagerAssignments) {
      this.hubManagerAssignments = additionalInfo.hubManagerAssignments.map(
        (assignment) => new UserAssignmentModel(assignment),
      );
    }

    if (additionalInfo.divisionManagerAssignments) {
      this.divisionManagerAssignments =
        additionalInfo.divisionManagerAssignments.map(
          (assignment) => new UserAssignmentModel(assignment),
        );
    }
  }
}
