<div class="tag-list">
  @if (users()) {
    @for (user of users(); track trackByFn($index, user)) {
      @if (user) {
        <procure-tag [user]="user">
          {{ getDisplay(user) }}
        </procure-tag>
      }
    }
  } @else {
    @for (tag of limitedTags(); track trackByFn($index, tag)) {
      <procure-tag
        [selectable]="selectable()"
        [clearable]="clearable()"
        [active]="isActive(tag)"
        [matTooltip]="!displayLabel() || isString(tag) ? '' : tag.getDisplay()"
        (clear)="clear.emit(tag)"
        (click)="selectActive.emit(tag)"
      >
        {{ getDisplay(tag) }}
      </procure-tag>
    }
    @if (ellipsis()) {
      <procure-tag [matTooltip]="(tags()?.length ?? 0) - limitedTags().length + ' more'"> … </procure-tag>
    }
  }
</div>
