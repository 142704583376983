import { SearchDocumentResponse } from '../types/search-document-response.type';
import { ProjectSearchIndexDefinitionSearchDocumentResultModel } from './project-search-index-definition-search-document-result.model';
import { PurchaseRequisitionSearchIndexDefinitionSearchDocumentResultModel } from './purchase-requisition-search-index-definition-search-document-result.model';
import { RequestForQuotationSearchIndexDefinitionSearchDocumentResultModel } from './request-for-quotation-search-index-definition-search-document-result.model';
import { RequestForRequestForQuotationSearchIndexDefinitionSearchDocumentResultModel } from './request-for-request-for-quotation-search-index-definition-search-document-result.model';
import { SupplierSearchIndexDefinitionSearchDocumentResultModel } from './supplier-search-index-definition-search-document-result.model';

export class SearchDocumentResponseModel {
  readonly projects: ProjectSearchIndexDefinitionSearchDocumentResultModel | null;
  // eslint-disable-next-line max-len
  readonly requestForRequestForQuotations: RequestForRequestForQuotationSearchIndexDefinitionSearchDocumentResultModel | null;
  readonly requestForQuotations: RequestForQuotationSearchIndexDefinitionSearchDocumentResultModel | null;
  readonly purchaseRequisitions: PurchaseRequisitionSearchIndexDefinitionSearchDocumentResultModel | null;
  readonly suppliers: SupplierSearchIndexDefinitionSearchDocumentResultModel | null;

  constructor(
    searchDocumentResponse: SearchDocumentResponse,
  ) {
    this.projects = searchDocumentResponse.projects ? new ProjectSearchIndexDefinitionSearchDocumentResultModel(
      searchDocumentResponse.projects,
    ) : null;

    this.requestForRequestForQuotations = searchDocumentResponse.requestForRequestForQuotations
      ? new RequestForRequestForQuotationSearchIndexDefinitionSearchDocumentResultModel(
        searchDocumentResponse.requestForRequestForQuotations,
      )
      : null;

    this.requestForQuotations = searchDocumentResponse.requestForQuotations
      ? new RequestForQuotationSearchIndexDefinitionSearchDocumentResultModel(
        searchDocumentResponse.requestForQuotations,
      )
      : null;

    this.purchaseRequisitions = searchDocumentResponse.purchaseRequisitions
      ? new PurchaseRequisitionSearchIndexDefinitionSearchDocumentResultModel(
        searchDocumentResponse.purchaseRequisitions,
      )
      : null;

    this.suppliers = searchDocumentResponse.suppliers ? new SupplierSearchIndexDefinitionSearchDocumentResultModel(
      searchDocumentResponse.suppliers,
    ) : null;
  }
}
