import {
  NgModule, ModuleWithProviders, Optional, SkipSelf, ɵstringify as stringify,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularApplicationInsightsConfig } from './config';
import { ApplicationInsightsService } from './services/application-insights.service';

@NgModule({
  imports: [CommonModule],
  declarations: [],
  exports: [],
})
export class ApplicationInsightsModule {
  constructor(
    applicationInsightsService: ApplicationInsightsService,
    @Optional() @SkipSelf() parentModule?: ApplicationInsightsModule,
  ) {
    if (parentModule) {
      throw new Error(`${stringify(ApplicationInsightsModule)} is already loaded. Import it in the AppModule only.`);
    }

    applicationInsightsService.init();
  }

  /**
   * Creates and configures the AppInsights module for the entire application.
   */
  static forRoot(config: AngularApplicationInsightsConfig): ModuleWithProviders<ApplicationInsightsModule> {
    return {
      ngModule: ApplicationInsightsModule,
      providers: [{ provide: AngularApplicationInsightsConfig, useValue: config }],
    };
  }
}
