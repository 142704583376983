import { DEFAULT_BIG_DIALOG_WIDTH } from '@abb-procure/constants';
import { Injectable, inject } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import type { TemplateFinderDialogComponent } from '../components/template-finder-dialog/template-finder-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class TemplateFinderDialogService {
  private readonly dialog = inject(MatDialog);

  async openTemplateFinderDialog(
    projectId?: string,
  ): Promise<MatDialogRef<TemplateFinderDialogComponent, null>> {
    const module = await import(
      '../components/template-finder-dialog/template-finder-dialog.component'
    );

    return this.dialog.open(module.TemplateFinderDialogComponent, {
      width: DEFAULT_BIG_DIALOG_WIDTH,
      data: {
        projectId,
      },
      panelClass: ['template-finder-dialog'],
    });
  }
}
