import { ApiService } from '@abb-procure/api';
import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { boolean as ioBoolean } from 'io-ts';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApplicationNotificationModel } from '../models/application-notification.model';
import { GlobalNotificationResponseModel } from '../models/global-notification-response.model';
import { ApplicationNotification } from '../types/application-notification.type';
import { GlobalNotificationResponse } from '../types/global-notification-response.type';

@Injectable({
  providedIn: 'root',
})
export class NotificationApiService extends ApiService {
  getNotification$(): Observable<ApplicationNotificationModel | null> {
    return this.get$<ApplicationNotificationModel>(
      'Notification/Admin',
      ApplicationNotification,
    ).pipe(
      this.catchError$('There was an issue retrieving notifications.'),
      map((response) =>
        response != null ? new ApplicationNotificationModel(response) : null,
      ),
      takeUntilDestroyed(this.destroyRef),
    );
  }

  getApplicationNotifications$(): Observable<GlobalNotificationResponseModel | null> {
    return this.get$<GlobalNotificationResponseModel>(
      'Notification',
      GlobalNotificationResponse,
    ).pipe(
      this.catchError$('There was an error retrieving notifications'),
      map((response) =>
        response != null ? new GlobalNotificationResponseModel(response) : null,
      ),
      takeUntilDestroyed(this.destroyRef),
    );
  }

  confirmNotification$(notificationId: string): Observable<boolean | null> {
    return this.put$<boolean, null>(
      `Notification/${notificationId}/Confirm`,
      ioBoolean,
    ).pipe(
      this.catchError$('There was an issue with confirming the notification'),
      takeUntilDestroyed(this.destroyRef),
    );
  }
}
