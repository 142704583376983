import { UserGuideTour } from '../types/user-guide-tour.type';

export class UserGuideTourModel implements UserGuideTour {
  readonly id: string;
  readonly confirmedCount: number;
  readonly markedAsCompleted: boolean;

  constructor(userGuideTour: UserGuideTour) {
    this.id = userGuideTour.id;
    this.confirmedCount = userGuideTour.confirmedCount;
    this.markedAsCompleted = userGuideTour.markedAsCompleted;
  }
}
