import { nullable } from '@abb-procure/api';
import * as t from 'io-ts';
import { RequestForRequestForQuotationSearchIndexDefinitionSearchDocumentItemResult } from './request-for-request-for-quotation-search-index-definition-search-document-item-result.type';

const RequestForRequestForQuotationSearchIndexDefinitionSearchDocumentResult = t.type({
  count: nullable(t.number),
  values: t.array(RequestForRequestForQuotationSearchIndexDefinitionSearchDocumentItemResult),
}, 'RequestForRequestForQuotationSearchIndexDefinitionSearchDocumentResult');

type RequestForRequestForQuotationSearchIndexDefinitionSearchDocumentResult = t.TypeOf<
  typeof RequestForRequestForQuotationSearchIndexDefinitionSearchDocumentResult
>;
export { RequestForRequestForQuotationSearchIndexDefinitionSearchDocumentResult };
