import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InboxService implements OnDestroy {
  refresh$;
  clear$;

  private refreshSignal$ = new Subject<void>();
  private clearSignal$ = new Subject<void>();

  constructor() {
    this.refresh$ = this.refreshSignal$.asObservable();
    this.clear$ = this.clearSignal$.asObservable();
  }

  ngOnDestroy(): void {
    this.refreshSignal$.complete();
  }

  refresh(): void {
    this.refreshSignal$.next();
  }

  clear(): void {
    this.clearSignal$.next();
  }
}
