import { PeoplePickerUser } from '../types/people-picker-user.type';
import { UserModel } from './user.model';

export class PeoplePickerUserModel
  extends UserModel
  implements PeoplePickerUser
{
  readonly department: string | null;
  readonly avatarUrl: string | null;

  constructor(data: PeoplePickerUser) {
    super(data);

    this.department = data.department;
    this.avatarUrl = data.avatarUrl;
  }
}
