import { PreferencesState } from '@abb-procure/data';
import { BooleanInput } from '@angular/cdk/coercion';
import { NgTemplateOutlet } from '@angular/common';
import { Component, booleanAttribute, inject, input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router, RouterLink } from '@angular/router';
import { QuicklinkDirective } from 'ngx-quicklink';
import { ViewNavigationService } from '../../services/view-navigation.service';

@Component({
  selector: 'procure-menu-child',
  templateUrl: './menu-child.component.html',
  styleUrls: ['./menu-child.component.scss'],
  standalone: true,
  imports: [
    MatIconModule,
    RouterLink,
    MatTooltipModule,
    QuicklinkDirective,
    RouterLink,
    NgTemplateOutlet,
  ],
})
export class MenuChildComponent {
  route = input<(string | null | undefined)[]>([]);
  parent = input<(string | null | undefined)[]>([]);
  view = input<string>();
  tooltip = input<string>();

  active = input<boolean, BooleanInput>(false, {
    transform: booleanAttribute,
  });

  last = input<boolean, BooleanInput>(false, {
    transform: booleanAttribute,
  });

  first = input<boolean, BooleanInput>(false, {
    transform: booleanAttribute,
  });

  show = input<boolean, BooleanInput>(false, {
    transform: booleanAttribute,
  });

  selected = input<boolean, BooleanInput>(false, {
    transform: booleanAttribute,
  });

  protected expanded = inject(PreferencesState).isNavigationExpanded;
  protected readonly viewNavigationService = inject(ViewNavigationService);

  private readonly router = inject(Router);

  navigate(): void {
    const parent = this.parent();
    const view = this.view();

    if (parent) {
      this.router.navigate(parent);
    }

    if (view) {
      this.viewNavigationService.setActiveView(view);

      const url = new URL(window.location.toString());
      url.searchParams.set('view', view);

      window.history.pushState({}, '', url);
    }
  }
}
