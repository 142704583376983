<div
  class="input-switch"
  [class.input-switch--condensed]="condensed()"
  [class.input-switch--show]="showMode()"
>
  @if (!showMode()) {
    <mat-slide-toggle
      [formControl]="control()"
      [labelPosition]="labelPosition()"
      [matTooltip]="tooltip()"
      [disableRipple]="true"
    >
      <ng-content *ngTemplateOutlet="content" />
    </mat-slide-toggle>
  } @else {
    <mat-slide-toggle
      [labelPosition]="labelPosition()"
      [matTooltip]="tooltip()"
      [disableRipple]="true"
      [disabled]="true"
      [checked]="control().value"
    >
      <ng-content *ngTemplateOutlet="content" />
    </mat-slide-toggle>
  }
</div>
<ng-template #content>
  <ng-content />
</ng-template>
