import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'initials',
  standalone: true,
})
export class InitialsPipe implements PipeTransform {
  /**
   * Transforms the input value.
   */
  transform(name: string | null): string {
    if (!name) {
      return '';
    }

    return name.split(' ').map((part) => part[0]).join('').slice(0, 2);
  }
}
