<mat-menu>
  @if (
    userState.current()?.permissions?.canAddRequestForRequestForQuotation ||
    userState.current()?.permissions?.canAddRequestForRequestForQutoationFromTemplate
  ) {
    <div
      mat-menu-item
      disabled
    >
      Sourcing Events
    </div>
  }
  @if (userState.current()?.permissions?.canAddRequestForRequestForQuotation) {
    <button
      class="app-nav__sub-item"
      mat-menu-item
      matTooltip="New Request for Request for Quotation"
      (click)="addRequestForRfq()"
    >
      New Sourcing Event
      <procure-entity-badge
        class="app-nav__badge"
        [label]="EntityIdAliasPrefixes.RfRFQ"
        [copyNumber]="false"
        [linked]="false"
      />
    </button>
  }
  @if (userState.current()?.permissions?.canAddRequestForRequestForQutoationFromTemplate) {
    <button
      class="app-nav__sub-item"
      mat-menu-item
      matTooltip="New Request for Request for Quotation from Template Request for RFQ"
      (click)="showTemplateFinder()"
    >
      New Sourcing Event from Template
      <procure-entity-badge
        class="app-nav__badge"
        [label]="EntityIdAliasPrefixes.TemplateRfrfq"
        [copyNumber]="false"
        [linked]="false"
      />
    </button>
  }
  @if (userState.current()?.permissions?.canAddProject) {
    <div
      mat-menu-item
      disabled
    >
      Projects
    </div>
    <button
      class="app-nav__sub-item"
      mat-menu-item
      (click)="addProject()"
    >
      New Project
    </button>
  }
</mat-menu>
