import {
  DEFAULT_MIDDLE_DIALOG_WIDTH,
  DEFAULT_SMALL_DIALOG_WIDTH,
  DialogType,
} from '@abb-procure/constants';
import { AdditionalInfoModel } from '@abb-procure/data';
import { DestroyRef, Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import {
  DialogComponent,
  DialogData,
} from '../components/dialog/dialog.component';

type DialogOptions = {
  readonly title?: string;
  readonly text?: string;
  readonly info?: string;
  readonly base?: boolean;
  readonly primaryAction?: string;
  readonly blackout?: boolean;
  readonly additionalInfo?: AdditionalInfoModel | null;
};

type OkDialogOptions = DialogOptions & {
  readonly closeCallback?: () => void;
};

type ErrorDialogOptions = DialogOptions & {
  readonly closeCallback?: () => void;
};

type ConfirmDialogOptions = DialogOptions & {
  readonly secondaryAction?: string;
  readonly confirmationCallback?: () => Observable<boolean | void>;
  readonly cancellationCallback?: () => void;
};

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  readonly defaultOkTitle: string = 'Success';
  readonly defaultOkText: string = 'The command was successfully executed';
  readonly defaultErrorTitle: string = 'Error';
  readonly defaultErrorText: string =
    'An error has occurred while saving your data. Please try again. If the problem persists, please contact your administrator.';
  readonly defaultConfirmTitle: string = 'Please Confirm';
  readonly defaultConfirmText: string =
    'Do you really want to cancel the current process? All unsaved data will be lost.';

  private readonly dialog = inject(MatDialog);
  private readonly componentRef = inject(DestroyRef);

  /**
   * Checks if there are any currently open dialogs.
   */
  hasOpenDialogs(): boolean {
    const { openDialogs } = this.dialog;

    return openDialogs != null && openDialogs.length > 0;
  }

  /**
   * Shows a basic informative dialog with an OK button.
   */
  showOkDialog(data?: OkDialogOptions): MatDialogRef<DialogComponent> {
    const dialogRef = this.dialog.open<DialogComponent, DialogData>(
      DialogComponent,
      {
        closeOnNavigation: false,
        width: data?.additionalInfo
          ? DEFAULT_MIDDLE_DIALOG_WIDTH
          : DEFAULT_SMALL_DIALOG_WIDTH,
        panelClass: ['dialog', 'dialog--info'],
        data: {
          ...data,
          title: data?.title ?? this.defaultOkTitle,
          text: data?.text ?? this.defaultOkText,
          base: data?.base,
          type: data?.additionalInfo
            ? DialogType.ADDITIONAL_INFO
            : DialogType.INFO,
          additionalInfo: data?.additionalInfo,
        },
        backdropClass: data?.blackout ? 'cdk-overlay-backdrop--blackout' : '',
      },
    );

    return dialogRef;
  }

  /**
   * Shows a basic error dialog with an OK button.
   */
  showErrorDialog(data?: ErrorDialogOptions): MatDialogRef<DialogComponent> {
    const dialogRef = this.dialog.open<DialogComponent, DialogData>(
      DialogComponent,
      {
        closeOnNavigation: false,
        width: DEFAULT_SMALL_DIALOG_WIDTH,
        panelClass: ['dialog', 'dialog--error'],
        data: {
          ...data,
          title: data?.title ?? this.defaultErrorTitle,
          text: data?.text ?? this.defaultErrorText,
          type: DialogType.ERROR,
        },
        backdropClass: data?.blackout ? 'cdk-overlay-backdrop--blackout' : '',
      },
    );

    return dialogRef;
  }

  /**
   * The confirmation callback will be executed once the dialog was confirmed.
   * If the confirmation callback completed, the dialog will be closed.
   */
  showConfirmDialog(
    data?: ConfirmDialogOptions,
  ): MatDialogRef<DialogComponent> {
    const dialogRef = this.dialog.open<DialogComponent, DialogData>(
      DialogComponent,
      {
        closeOnNavigation: false,
        width: DEFAULT_SMALL_DIALOG_WIDTH,
        panelClass: ['dialog', 'dialog--prompt'],
        data: {
          ...data,
          title: data?.title ?? this.defaultConfirmTitle,
          text: data?.text ?? this.defaultConfirmText,
          type: DialogType.PROMPT,
        },
        backdropClass: data?.blackout ? 'cdk-overlay-backdrop--blackout' : '',
      },
    );

    dialogRef
      .afterClosed()
      .pipe(takeUntilDestroyed(this.componentRef))
      .subscribe((result: boolean) => {
        // Result will be false, if confirmation was unsuccessful, or dialog was cancelled.
        if (!result && data?.cancellationCallback) {
          data.cancellationCallback();
        }
      });

    return dialogRef;
  }
}
