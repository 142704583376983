import { nullable } from '@abb-procure/api';
import * as t from 'io-ts';

const ResourceLinkSlim = t.type(
  {
    id: t.number,
    name: nullable(t.string),
    url: nullable(t.string),
    description: nullable(t.string),
    isDocument: nullable(t.boolean),
    icon: nullable(t.string),
  },
  'ResourceLinkSlim',
);

type ResourceLinkSlim = t.TypeOf<typeof ResourceLinkSlim>;
export { ResourceLinkSlim };
