import { SupplierSearchIndexDefinition } from '../types/supplier-search-index-definition.type';

export class SupplierSearchIndexDefinitionModel {
  readonly id: string | null;
  readonly companyName: string | null;
  readonly supplierSource: string | null;
  readonly guid: string | null;
  readonly addressLine: string | null;
  readonly zipCode: string | null;
  readonly city: string | null;
  readonly state: string | null;
  readonly country: string | null;

  constructor(definition: SupplierSearchIndexDefinition) {
    this.id = definition.id;
    this.companyName = definition.companyName;
    this.supplierSource = definition.supplierSource;
    this.guid = definition.guid;
    this.addressLine = definition.addressLine;
    this.zipCode = definition.zipCode;
    this.city = definition.city;
    this.state = definition.state;
    this.country = definition.country;
  }
}
