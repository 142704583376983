import { FormControl, FormGroup } from '@angular/forms';

const group = new FormGroup({
  selected: new FormControl(true),
  isEnabled: new FormControl(true),
  control: new FormControl(null),
});

export const isRequired = (control: FormControl): boolean => {
  if (!control?.validator) {
    return false;
  }

  const validator = control.validator(group.controls.control);

  return validator?.required || validator?.requiredIfEnabled || validator?.requiredIfSelected;
};
