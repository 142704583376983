import { UserModel, UserSlim } from '@abb-procure/data';
import { BooleanInput } from '@angular/cdk/coercion';
import { NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  booleanAttribute,
  input,
  output,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterLink } from '@angular/router';
import { QuicklinkDirective } from 'ngx-quicklink';
import { AvatarComponent } from '../avatar/avatar.component';

@Component({
  selector: 'procure-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss'],
  standalone: true,
  imports: [
    MatIconModule,
    AvatarComponent,
    RouterLink,
    NgTemplateOutlet,
    QuicklinkDirective,
    MatTooltipModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagComponent {
  clear = output<void>();

  id = input<string | null>(null);

  clearable = input<boolean, BooleanInput>(false, {
    transform: booleanAttribute,
  });

  selectable = input<boolean, BooleanInput>(false, {
    transform: booleanAttribute,
  });

  user = input<UserModel | UserSlim | null>();
  link = input<string | null>();
  tooltip = input<string | null>();

  active = input<boolean, BooleanInput>(false, {
    transform: booleanAttribute,
  });
}
