import { EntityIdAliasPrefixes } from '@abb-procure/constants';

const entityNames = new Map<EntityIdAliasPrefixes, string>([
  [EntityIdAliasPrefixes.PriceEntry, 'Price Entry'],
  [EntityIdAliasPrefixes.Project, 'Project'],
  [EntityIdAliasPrefixes.ProjectExpediting, 'Project Expediting'],
  [EntityIdAliasPrefixes.PurchaseRequisition, 'Purchase Requisition'],
  [EntityIdAliasPrefixes.Rfq, 'RFQ'],
  [EntityIdAliasPrefixes.RfRFQ, 'RfRFQ'],
  [EntityIdAliasPrefixes.Supplier, 'Supplier'],
  [EntityIdAliasPrefixes.Quotation, 'Quotation'],
  [EntityIdAliasPrefixes.TemplateRfrfq, 'Template RfRFQ'],
]);

export class EntityUtility {
  static getEntityName(
    entity: EntityIdAliasPrefixes,
    plural: boolean = false,
  ): string | null {
    return `${entityNames.get(entity)}${plural ? 's' : ''}` || null;
  }

  /**
   * Get the type of an entity based on its entity number.
   */
  static getType(
    entityNumber: string,
    seperator: string = '-',
  ): EntityIdAliasPrefixes | null {
    const splits: string[] = entityNumber.split(seperator);

    if (!splits.length) {
      return null;
    }

    if (
      Object.values(EntityIdAliasPrefixes).includes(
        splits[0].toUpperCase() as EntityIdAliasPrefixes,
      )
    ) {
      return splits[0].toUpperCase() as EntityIdAliasPrefixes;
    }

    return null;
  }

  /**
   * Get the actual number of an entity based on its entity number.
   */
  static getNumber(entityNumber: string): number | null {
    const splits: string[] = entityNumber.split('-');

    if (
      !splits.length ||
      Number.isNaN(Number(splits[1])) ||
      !Number.isInteger(Number(splits[1]))
    ) {
      return null;
    }

    return Number(splits[1]);
  }
}
