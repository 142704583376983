@if (!avatar() || error()) {
  <span
    class="avtar__text"
    data-testid="avatar"
  >
    {{ name() | initials }}
  </span>
}
@if (avatar() && !error()) {
  <img
    class="avatar__image"
    [src]="avatar()"
    [alt]="name()"
    (error)="onImageError()"
  />
}
