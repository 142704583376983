import { Injectable, computed, inject, signal } from '@angular/core';
import { deepEqual } from 'fast-equals';
import { AdditionalInfoModel } from '../models/additional-info.model';
import { CurrentUserModel } from '../models/current-user.model';
import { LookupDataState } from './lookup-data.state';
import { SettingsState } from './settings.state';

export interface UserStateShape {
  current: CurrentUserModel | null;
  isSubstitute: boolean;
  isForbidden: boolean;
  additionalInfo: AdditionalInfoModel | null;
  additionalInfoMessage: string | null;
}

const initialState: UserStateShape = {
  current: null,
  isSubstitute: false,
  isForbidden: false,
  additionalInfo: null,
  additionalInfoMessage: null,
};

@Injectable({
  providedIn: 'root',
})
export class UserState {
  readonly current = computed(() => this.state().current, { equal: deepEqual });

  readonly isSubstitute = computed(() => this.state().isSubstitute, {
    equal: deepEqual,
  });
  readonly isForbidden = computed(() => this.state().isForbidden, {
    equal: deepEqual,
  });

  readonly additionalInfo = computed(() => this.state().additionalInfo, {
    equal: deepEqual,
  });

  readonly additionalInfoMessage = computed(
    () => this.state().additionalInfoMessage,
    {
      equal: deepEqual,
    },
  );

  private readonly state = signal({ ...initialState });
  private readonly lookupDataState = inject(LookupDataState);
  private readonly settingsState = inject(SettingsState);

  setAdditionalInfoMessage(additionalInfoMessage: string | null): void {
    this.state.update((state) => ({
      ...state,
      additionalInfoMessage,
    }));
  }

  setAdditionalInfo(additionalInfo: AdditionalInfoModel | null): void {
    this.state.update((state) => ({
      ...state,
      additionalInfo,
    }));
  }

  setForbidden(isForbidden: boolean): void {
    this.state.update((state) => ({
      ...state,
      isForbidden,
    }));
  }

  setCurrentUser(user: CurrentUserModel | null, isSubstitute: boolean): void {
    this.state.update((state) => ({
      ...state,
      current: user,
      isSubstitute,
      isForbidden: false,
      tsp: null,
    }));

    if (!user) {
      return;
    }

    this.lookupDataState.fetchETag();
    this.lookupDataState.fetchMenu();
    this.lookupDataState.fetchTSP();
    this.settingsState.fetchStorageUploadSettings();
  }
}
