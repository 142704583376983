import { PaginatedResponse } from '../types/paginated-response.type';

export class PaginatedResponseModel implements PaginatedResponse {
  readonly totalCount: number;
  readonly pageIndex: number;
  readonly pageSize: number;
  readonly orderBy: string | null;
  readonly orderIsAscending: boolean;

  constructor(paginatedResponse: PaginatedResponse) {
    this.totalCount = paginatedResponse.totalCount;
    this.pageIndex = paginatedResponse.pageIndex;
    this.pageSize = paginatedResponse.pageSize;
    this.orderBy = paginatedResponse.orderBy;
    this.orderIsAscending = paginatedResponse.orderIsAscending;
  }
}
