import { BaseModel } from '@abb-procure/data';
import { RequestForQuotationSearchIndexDefinitionSearchDocumentResult } from '../types/request-for-quotation-search-index-definition-search-document-result.type';
import { RequestForQuotationSearchIndexDefinitionSearchDocumentItemResultModel } from './request-for-quotation-search-index-definition-search-document-item-result.model';

export class RequestForQuotationSearchIndexDefinitionSearchDocumentResultModel extends BaseModel {
  readonly count: number | null;
  readonly values: RequestForQuotationSearchIndexDefinitionSearchDocumentItemResultModel[];

  constructor(
    result: RequestForQuotationSearchIndexDefinitionSearchDocumentResult,
  ) {
    super();

    this.count = result.count;

    this.values = result.values
      .map((value) => new RequestForQuotationSearchIndexDefinitionSearchDocumentItemResultModel(value));
  }
}
