import {
  ChangeDetectionStrategy, Component, Input,
} from '@angular/core';

@Component({
  selector: 'procure-environment-banner',
  templateUrl: './environment-banner.component.html',
  styleUrls: ['./environment-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class EnvironmentBannerComponent {
  @Input() identifier?: string;
}
