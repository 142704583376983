import { ResourceLinkSlim } from '../types/resource-link-slim.type';
import { BaseModel } from './base.model';

export class ResourceLinkSlimModel
  extends BaseModel
  implements ResourceLinkSlim
{
  readonly id: number;
  readonly name: string | null = null;
  readonly url: string | null = null;
  readonly description: string | null = null;
  readonly isDocument: boolean | null = null;
  readonly icon: string | null = null;

  constructor(resourceLinkSlim: ResourceLinkSlim) {
    super();

    this.id = resourceLinkSlim.id;

    if (resourceLinkSlim.name) {
      this.name = resourceLinkSlim.name;
    }

    if (resourceLinkSlim.url) {
      this.url = resourceLinkSlim.url;
    }

    if (resourceLinkSlim.description) {
      this.description = resourceLinkSlim.description;
    }

    if (resourceLinkSlim.isDocument) {
      this.isDocument = resourceLinkSlim.isDocument;
    }

    if (resourceLinkSlim.icon) {
      this.icon = resourceLinkSlim.icon;
    }
  }
}
