import { ApiService } from '@abb-procure/api';
import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Observable, map } from 'rxjs';
import { CurrentUserModel } from '../models/current-user.model';
import { PreferenceModel } from '../models/preference.model';
import { CurrentUser } from '../types/current-user.type';

@Injectable({
  providedIn: 'root',
})
export class PreferencesApiService extends ApiService {
  updatePreferences$(
    request: PreferenceModel,
  ): Observable<CurrentUserModel | null> {
    return this.put$<CurrentUserModel, PreferenceModel>(
      'User/Preference',
      CurrentUser,
      request,
    ).pipe(
      this.catchError$('There was an issue while updating user preferences.'),
      map((response) =>
        response != null ? new CurrentUserModel(response) : null,
      ),
      takeUntilDestroyed(this.destroyRef),
    );
  }
}
