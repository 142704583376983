import { nullable } from '@abb-procure/api';
import * as t from 'io-ts';

const GuidName = t.type(
  {
    id: t.string,
    name: nullable(t.string),
  },
  'GuidName',
);

type GuidName = t.TypeOf<typeof GuidName>;
export { GuidName };
