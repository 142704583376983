/**
 * A dictionary of permissions.
 */
export type UserPermissions<K extends string> = {
  [key in K]: boolean;
};

/**
 * Stub of a user object with permission information.
 */
export type UserWithPermissions<T extends string> = {
  readonly permissions: UserPermissions<T>;
};

function checkPermissions(
  checks: string[],
  permissions: UserPermissions<string>,
): boolean {
  return checks.every((check) => !!permissions[check]);
}

/**
 * Checks whether the the given user has the required permissions.
 * If no user is given, checks are made against the currently active user of the application.
 */
export function hasPermissions(
  user: UserWithPermissions<string>,
  permissions?: string[],
): boolean {
  if (!permissions) {
    return true;
  }

  const userPermissions = user.permissions || {};

  return checkPermissions(permissions, userPermissions);
}
