import { IdNameDescription } from '../types/id-name-description.type';
import { BaseModel } from './base.model';

export class IdNameDescriptionModel
  extends BaseModel
  implements IdNameDescription
{
  readonly id: number;
  readonly name: string | null;
  readonly description: string | null;

  constructor(data: IdNameDescription) {
    super();

    this.id = data.id;
    this.name = data.name;
    this.description = data.description;
  }

  getDisplay(): string {
    return `${this.name}`;
  }
}
