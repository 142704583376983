import { GuidName } from '../types/guid-name.type';
import { BaseModel } from './base.model';

export class GuidNameModel extends BaseModel implements GuidName {
  readonly id: string;
  readonly name: string | null;

  constructor(data: GuidName) {
    super();

    this.id = data.id;
    this.name = data.name;
  }

  getDisplay(): string {
    return `${this.name}`;
  }
}
