import { nullable } from '@abb-procure/api';
import * as t from 'io-ts';
import { GuidName } from './guid-name.type';
import { OrganisationReview } from './organisation-review.type';
import { UserLocalDivision } from './user-local-division.type';
import { User } from './user.type';

const UserDetails = t.intersection(
  [
    User,
    t.type({
      firstName: nullable(t.string),
      lastName: nullable(t.string),
      company: nullable(t.string),
      department: nullable(t.string),
      telephone: nullable(t.string),
      mobile: nullable(t.string),
      avatarUrl: nullable(t.string),
      permissions: nullable(t.array(GuidName)),
      assignments: nullable(t.array(UserLocalDivision)),
      divisionManagers: nullable(t.array(OrganisationReview)),
      hubManagers: nullable(t.array(OrganisationReview)),
      localDivisionManagers: nullable(t.array(OrganisationReview)),
    }),
  ],
  'UserDetails',
);

type UserDetails = t.TypeOf<typeof UserDetails>;
export { UserDetails };
