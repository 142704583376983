<procure-card
  condensed
  [cover]="cover()"
  [loading]="loading()"
  [flat]="flat()"
  [accent]="accent()"
>
  <div class="expansion-panel">
    <mat-accordion>
      <mat-expansion-panel
        [class]="expansionPanelClass()"
        [expanded]="expanded()"
        [hideToggle]="disabled() || hideToggle()"
        [disabled]="disabled() || hideToggle()"
        (closed)="closed.emit()"
        (opened)="opened.emit()"
      >
        @if (!hideHeader()) {
          <mat-expansion-panel-header
            [class.expansion-panel__header--disabled]="disabled()"
            [collapsedHeight]="visibleHeight()"
            [expandedHeight]="visibleHeight()"
          >
            <mat-panel-title>
              @if (icon(); as icon) {
                <mat-icon>
                  {{ icon }}
                </mat-icon>
              }
              @if (badge() || (badge() === 0 && showZeroBadge())) {
                <procure-number-badge
                  [value]="badge() || 0"
                  [clickable]="false"
                />
              }
              @if (color(); as color) {
                <div
                  class="expansion-panel__color"
                  [style.background-color]="color"
                ></div>
              }
              <div class="expansion-panel__label">
                @if (hint()) {
                  @if (fromSAPAriba()) {
                    <img
                      class="expansion-panel__sap-ariba-icon"
                      src="/assets/images/sap-ariba.png"
                      alt="SAP Ariba"
                      [matTooltip]="hint() ?? ''"
                    />
                  } @else {
                    <mat-icon
                      class="expansion-panel__hint"
                      [matTooltip]="hint() ?? ''"
                    >
                      info
                    </mat-icon>
                  }
                }
                {{ label() }}
                @if (labelSuffix(); as labelSuffix) {
                  <span class="expansion-panel__label-suffix">
                    @if (label()) {
                      -
                    }
                    {{ labelSuffix }}
                  </span>
                }
                @if (labelLink() && link()) {
                  <span class="expansion-panel__label-link">
                    <a
                      class="expansion-panel__link"
                      target="_blank"
                      rel="noopener noreferrer"
                      [href]="link()"
                    >
                      {{ labelLink() }}
                    </a>
                  </span>
                }
              </div>
              @if (status(); as status) {
                <procure-status-badge
                  class="expansion-panel__status"
                  [status]="status"
                />
              }
            </mat-panel-title>
          </mat-expansion-panel-header>
        }
        <ng-content />
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</procure-card>
