import { DomSanitizer } from '@angular/platform-browser';
import {
  PipeTransform, Pipe, inject, SecurityContext,
} from '@angular/core';

@Pipe({
  name: 'sanitize',
  standalone: true,
})
export class SanitizePipe implements PipeTransform {
  private sanitized = inject(DomSanitizer);

  transform(value: string | null): string | null {
    if (!value) {
      return '';
    }

    return this.sanitized.sanitize(SecurityContext.HTML, value);
  }
}
