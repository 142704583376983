<div class="number-badge">
  <div
    class="number-badge__count"
    [class.number-badge__count--clickable]="clickable()"
    [class.number-badge__count--small]="small()"
  >
    {{ value() }}
  </div>
  @if (label()) {
    <div class="number-badge__label">
      {{ label() }}
    </div>
  }
</div>
