import { UserLocalDivision } from '../types/user-local-division.type';
import { BaseModel } from './base.model';
import { EntityStatusModel } from './entity-status.model';
import { GuidNameCodeModel } from './guid-name-code.model';
import { IdNameModel } from './id-name.model';

export class UserLocalDivisionModel
  extends BaseModel
  implements UserLocalDivision
{
  readonly unit: GuidNameCodeModel;
  readonly role: IdNameModel;
  readonly reviewStatus: EntityStatusModel | null = null;
  readonly reviewed: Date | null = null;
  readonly nextReview: Date | null = null;

  constructor(data: UserLocalDivision) {
    super();

    this.unit = new GuidNameCodeModel(data.unit);
    this.role = new IdNameModel(data.role);

    if (data.reviewStatus) {
      this.reviewStatus = new EntityStatusModel(data.reviewStatus);
    }

    if (data.reviewed) {
      this.reviewed = data.reviewed;
    }

    if (data.nextReview) {
      this.nextReview = data.nextReview;
    }
  }
}
