import { ApiService, CustomHttpParams } from '@abb-procure/api';
import { CustomHttpErrorResponse } from '@abb-procure/error-handling';
import { Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { array as ioArray } from 'io-ts';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AdditionalInfoModel } from '../models/additional-info.model';
import { CurrentUserModel } from '../models/current-user.model';
import { PeoplePickerUserModel } from '../models/people-picker-user.model';
import { UserDetailsModel } from '../models/user-details.model';
import { UserState } from '../state/user.state';
import { CurrentUser } from '../types/current-user.type';
import { PeoplePickerUser } from '../types/people-picker-user.type';
import { UserDetails } from '../types/user-details.type';
import { User } from '../types/user.type';
import { UsersRequest } from '../types/users-request.type';

@Injectable({
  providedIn: 'root',
})
export class UserApiService extends ApiService {
  private readonly userState = inject(UserState);

  /**
   * Retrieves the collection of dashboard items.
   */
  getUsers$(usersRequest: UsersRequest): Observable<PeoplePickerUser[] | null> {
    const queryParams = new CustomHttpParams(usersRequest);

    return this.get$<PeoplePickerUser[]>(
      'User/Search',
      ioArray(PeoplePickerUser),
      queryParams,
    ).pipe(
      map((response) =>
        response != null
          ? response.map((user) => new PeoplePickerUserModel(user))
          : null,
      ),
      takeUntilDestroyed(this.destroyRef),
    );
  }

  getUser$(userId?: number): Observable<CurrentUserModel | null> {
    const queryParams = new CustomHttpParams(
      userId
        ? {
            userId,
          }
        : {},
    );

    return this.get$<CurrentUser>('User', User, queryParams).pipe(
      catchError((error: unknown) => {
        if (error instanceof CustomHttpErrorResponse && error.status === 401) {
          this.userState.setForbidden(true);

          if (error.error.additionalInfo) {
            this.userState.setAdditionalInfo(
              new AdditionalInfoModel(error.error.additionalInfo),
            );
          }

          if (error.error.message) {
            this.userState.setAdditionalInfoMessage(error.error.message);
          }

          return of(null);
        }

        this.errorHandlerService.handleError(error, {
          text: 'There was an issue retrieving the user data.',
        });

        return of(null);
      }),
      map((response) =>
        response != null ? new CurrentUserModel(response) : null,
      ),
      takeUntilDestroyed(this.destroyRef),
    );
  }

  getUserDetails$(userId?: number): Observable<UserDetailsModel | null> {
    const queryParams = new CustomHttpParams(
      userId
        ? {
            userId,
          }
        : {},
    );

    return this.get$<UserDetails>(
      'User/Details',
      UserDetails,
      queryParams,
    ).pipe(
      this.catchError$('There was an issue retrieving the user data.'),
      map((response) =>
        response != null ? new UserDetailsModel(response) : null,
      ),
      takeUntilDestroyed(this.destroyRef),
    );
  }

  confirmUserTour$(tour: string): Observable<CurrentUserModel | null> {
    const params = new CustomHttpParams({
      tourId: tour,
    });

    return this.put$<CurrentUserModel, null>(
      'User/Tour/Confirm',
      CurrentUser,
      null,
      params,
    ).pipe(
      this.catchError$('There was an issue marking the tour as completed.'),
      takeUntilDestroyed(this.destroyRef),
    );
  }

  completeUserTour$(tour: string): Observable<CurrentUserModel | null> {
    const params = new CustomHttpParams({
      tourId: tour,
    });

    return this.put$<CurrentUserModel, null>(
      'User/Tour/Complete',
      CurrentUser,
      null,
      params,
    ).pipe(
      this.catchError$('There was an issue marking the tour as completed.'),
      takeUntilDestroyed(this.destroyRef),
    );
  }
}
