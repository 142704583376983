<div
  class="quick-search"
  [class.quick-search--focused]="isFocused"
  (mousedown)="onMouseDown($event)"
>
  <form [formGroup]="form">
    <mat-form-field
      appearance="outline"
      subscriptSizing="dynamic"
    >
      <input
        #searchInput
        class="quick-search__input"
        id="searchInput"
        type="text"
        matInput
        formControlName="quickSearch"
        [matAutocomplete]="resultPanel"
        (keyup.enter)="onSubmit()"
        (focus)="openFakePanel()"
        (blur)="closeFakePanel()"
      />
      @if (!isSearching()) {
        <mat-icon
          class="quick-search__icon"
          matSuffix
        >
          search
        </mat-icon>
      } @else {
        <mat-spinner
          class="quick-search__spinner"
          matSuffix
          [diameter]="24"
        />
      }
      <div
        class="quick-search__hint"
        [hidden]="!isFakePannelVisible || (hasResults && !isEmpty)"
      >
        <ng-container *ngTemplateOutlet="panelTooltip" />
        @if (getFormControl("quickSearch").value && !isSearching() && isEmpty) {
          <div class="quick-search__empty">
            Your search - <strong>{{ getFormControl("quickSearch").value }}</strong> - did not match any documents.
            <br />
            <a
              [routerLink]="['search']"
              [queryParams]="{ query: getFormControl('quickSearch').value, showInactive: true, showClosed: true }"
            >
              See more results
            </a>
          </div>
        }
      </div>
      <mat-autocomplete
        #resultPanel="matAutocomplete"
        class="quick-search__results"
        panelWidth="640px"
        (optionSelected)="onSelect($event.option.value)"
      >
        <ng-container *ngTemplateOutlet="panelTooltip" />
        @if (searchResult) {
          @if (searchResult.projects && searchResult.projects.count) {
            <mat-optgroup>
              <span class="quick-search__result-title">
                {{ searchResult.projects.count }}
                {{ getEntityName(EntityIdAliasPrefixes.Project, searchResult.projects.values.length > 1) }}
                <a
                  class="quick-search__result-link"
                  routerLink="/search"
                  [queryParams]="{ type: EntityIdAliasPrefixes.Project, query: searchInput.value }"
                  (click)="closePanel()"
                >
                  Show All
                </a>
              </span>
              @for (res of searchResult.projects.values; track res.uuid) {
                <mat-option [value]="res.value">
                  <div
                    [class]="'quick-search__result-entry quick-search__result-entry--' + EntityIdAliasPrefixes.Project"
                  >
                    <procure-entity-badge
                      class="quick-search__result-badge"
                      [label]="res.value.number"
                    />
                    @if (res.value.projectName) {
                      <span
                        [innerHTML]="res.value.projectName | searchHighlight: getFormControl('quickSearch').value"
                      ></span>
                    }
                  </div>
                </mat-option>
              }
            </mat-optgroup>
          }
          @if (searchResult.requestForRequestForQuotations && searchResult.requestForRequestForQuotations.count) {
            <mat-optgroup>
              <span class="quick-search__result-title">
                {{ searchResult.requestForRequestForQuotations.count }}
                {{
                  getEntityName(
                    EntityIdAliasPrefixes.RfRFQ,
                    searchResult.requestForRequestForQuotations.values.length > 1
                  )
                }}
                <a
                  class="quick-search__result-link"
                  routerLink="/search"
                  [queryParams]="{ type: EntityIdAliasPrefixes.RfRFQ, query: searchInput.value }"
                  (click)="closePanel()"
                >
                  Show All
                </a>
              </span>
              @for (res of searchResult.requestForRequestForQuotations.values; track res.uuid) {
                <mat-option [value]="res.value">
                  <div
                    [class]="'quick-search__result-entry quick-search__result-entry--' + EntityIdAliasPrefixes.RfRFQ"
                  >
                    <procure-entity-badge
                      class="quick-search__result-badge"
                      [label]="res.value.number"
                    />
                    @if (res.value.requiredScope) {
                      <span
                        [innerHTML]="
                          res.value.projectName + ' / ' + res.value.requiredScope
                            | searchHighlight: getFormControl('quickSearch').value
                        "
                      ></span>
                    }
                  </div>
                </mat-option>
              }
            </mat-optgroup>
          }
          @if (searchResult.requestForQuotations && searchResult.requestForQuotations.count) {
            <mat-optgroup>
              <span class="quick-search__result-title">
                {{ searchResult.requestForQuotations.count }}
                {{ getEntityName(EntityIdAliasPrefixes.Rfq, searchResult.requestForQuotations.values.length > 1) }}
                <a
                  class="quick-search__result-link"
                  routerLink="/search"
                  [queryParams]="{ type: EntityIdAliasPrefixes.Rfq, query: searchInput.value }"
                  (click)="closePanel()"
                >
                  Show All
                </a>
              </span>
              @for (res of searchResult.requestForQuotations.values; track res.uuid) {
                <mat-option [value]="res.value">
                  <div [class]="'quick-search__result-entry quick-search__result-entry--' + EntityIdAliasPrefixes.Rfq">
                    <procure-entity-badge
                      class="quick-search__result-badge"
                      [label]="res.value.number"
                    />
                    @if (res.value.subject) {
                      <span
                        [innerHTML]="
                          res.value.projectName + ' / ' + res.value.requiredScope + ' / ' + res.value.subject
                            | searchHighlight: getFormControl('quickSearch').value
                        "
                      ></span>
                    }
                  </div>
                </mat-option>
              }
            </mat-optgroup>
          }
          @if (searchResult.purchaseRequisitions && searchResult.purchaseRequisitions.count) {
            <mat-optgroup>
              <span class="quick-search__result-title">
                {{ searchResult.purchaseRequisitions.count }}
                {{
                  getEntityName(
                    EntityIdAliasPrefixes.PurchaseRequisition,
                    searchResult.purchaseRequisitions.values.length > 1
                  )
                }}
                <a
                  class="quick-search__result-link"
                  routerLink="/search"
                  [queryParams]="{ type: EntityIdAliasPrefixes.PurchaseRequisition, query: searchInput.value }"
                  (click)="closePanel()"
                >
                  Show All
                </a>
              </span>
              @for (res of searchResult.purchaseRequisitions.values; track res.uuid) {
                <mat-option [value]="res.value">
                  <div
                    [class]="
                      'quick-search__result-entry quick-search__result-entry--' +
                      EntityIdAliasPrefixes.PurchaseRequisition
                    "
                  >
                    <procure-entity-badge
                      class="quick-search__result-badge"
                      [label]="res.value.number"
                    />
                    @if (res.value.requiredScope) {
                      <span
                        [innerHTML]="
                          res.value.projectName + ' / ' + res.value.requiredScope
                            | searchHighlight: getFormControl('quickSearch').value
                        "
                      ></span>
                    }
                  </div>
                </mat-option>
              }
            </mat-optgroup>
          }
          @if (searchResult.suppliers && searchResult.suppliers.count) {
            <mat-optgroup>
              <span class="quick-search__result-title">
                {{ searchResult.suppliers.count }}
                {{ getEntityName(EntityIdAliasPrefixes.Supplier, searchResult.suppliers.values.length > 1) }}
                <a
                  class="quick-search__result-link"
                  routerLink="/search"
                  [queryParams]="{ type: EntityIdAliasPrefixes.Supplier, query: searchInput.value }"
                  (click)="closePanel()"
                >
                  Show All
                </a>
              </span>
              @for (res of searchResult.suppliers.values; track res.uuid) {
                <mat-option [value]="'/suppliers/' + res.value.id">
                  <div
                    [class]="'quick-search__result-entry quick-search__result-entry--' + EntityIdAliasPrefixes.Supplier"
                  >
                    @if (res.value.companyName) {
                      <span
                        [innerHTML]="
                          res.value.companyName +
                            (res.value.guid ? ' / ' + res.value.guid : '') +
                            ' / ' +
                            res.value.city +
                            ', ' +
                            res.value.country | searchHighlight: getFormControl('quickSearch').value
                        "
                      ></span>
                    }
                  </div>
                </mat-option>
              }
            </mat-optgroup>
          }
        }
      </mat-autocomplete>
    </mat-form-field>
  </form>
</div>
<ng-template #panelTooltip>
  <div class="panel-tooltip">
    You can search for keywords globally or for specific entities (e.g. RFQ:LV400) and specific entities (e.g. RFQ-123).
  </div>
</ng-template>
