import { TermsAndConditionsCategory } from '../types/terms-and-conditions-category.type';
import { IdNameModel } from './id-name.model';
import { TermsAndConditionsModel } from './terms-and-conditions.model';

export class TermsAndConditionsCategoryModel
  extends IdNameModel
  implements TermsAndConditionsCategory
{
  readonly termsAndConditions: TermsAndConditionsModel[] | null = null;

  constructor(data: TermsAndConditionsCategory) {
    super(data);

    if (data.termsAndConditions) {
      this.termsAndConditions = data.termsAndConditions.map(
        (item) => new TermsAndConditionsModel(item),
      );
    }
  }
}
