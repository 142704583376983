<div [class]="'show-field__status show-field__status--' + statusColor()">
  <div class="show-field__content">
    @if (!hideLabel()) {
      <div
        class="show-field__label"
        [class.show-field__label--invalid]="invalid()"
      >
        @if (hint()) {
          <mat-icon
            class="show-field__icon"
            [matTooltip]="hint()"
          >
            info
          </mat-icon>
        }
        {{ label() }}
        @if (required() && !showMode()) {
          <span class="input-required"> * </span>
        }
      </div>
    }
    <div class="show-field__value">
      <ng-content />
    </div>
  </div>
</div>
