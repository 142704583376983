import { BaseModel } from '@abb-procure/data';
import { ProjectSearchIndexDefinitionSearchDocumentItemResult } from '../types/project-search-index-definition-search-document-item-result.type';
import { ProjectSearchIndexDefinitionModel } from './project-search-index-definition.model';

export class ProjectSearchIndexDefinitionSearchDocumentItemResultModel extends BaseModel {
  readonly value: ProjectSearchIndexDefinitionModel;
  readonly highlights: Record<string, string[]> | null;
  readonly searchScore: number | null;

  constructor(
    result: ProjectSearchIndexDefinitionSearchDocumentItemResult,
  ) {
    super();

    this.value = new ProjectSearchIndexDefinitionModel(result.value);
    this.highlights = result.highlights;
    this.searchScore = result.searchScore;
  }
}
