<div
  #header
  class="app-header"
  [class.app-header--collapsed]="!isExpanded()"
>
  @if (isEnvironmentBannerEnabled) {
    <procure-environment-banner [identifier]="environmentInfo.id">
      Environment: <b>{{ environmentInfo.title }}</b
      >, Version: <b>{{ environmentInfo.version }}</b
      >, Build: <b>{{ environmentInfo.buildNumber }}</b>
    </procure-environment-banner>
  }
  <div class="app-header__container">
    <div class="app-header__brand">
      <a
        class="app-header__logo-container logo"
        routerLink="/"
      >
        <img
          class="logo__img logo__img--large"
          src="./assets/images/logo-procure-brand.svg"
          alt="Procure App Logo"
        />
        <img
          class="logo__img logo__img--small"
          src="./assets/images/logo-procure-brand-small.svg"
          alt="Procure App Logo"
        />
      </a>
      <button
        class="app-header__toggle"
        procure-button
        condensed
        variant="ghost"
        color="white"
        (click)="toggleMenu()"
      >
        <mat-icon>menu</mat-icon>
      </button>
    </div>
    <div class="app-header__center">
      <div class="app-header__menu-items">
        <a
          class="app-header__button app-header__button--legacy"
          procure-button
          variant="ghost"
          color="white"
          matTooltip="Dashboard"
          [routerLink]="['/']"
        >
          <mat-icon>home</mat-icon>
        </a>
        <a
          class="app-header__button app-header__button--legacy"
          procure-button
          variant="ghost"
          color="white"
          matTooltip="Suppliers"
          [routerLink]="['/suppliers']"
        >
          <mat-icon>local_shipping</mat-icon>
        </a>
        @if (
          userState.current()?.permissions?.canAddRequestForRequestForQuotation ||
          userState.current()?.permissions?.canAddProject ||
          userState.current()?.permissions?.canAddRequestForRequestForQutoationFromTemplate
        ) {
          <button
            class="app-header__button app-header__button--legacy"
            procure-button
            variant="ghost"
            color="white"
            matTooltip="Create"
            [matMenuTriggerFor]="createMenu.menu"
          >
            <mat-icon>add_circle</mat-icon>
          </button>
        }
      </div>
      <procure-quick-search class="app-header__search" />
    </div>
    @if (lookupDataState.menu().length || userState.current()) {
      <div class="app-header__buttons">
        @if (userState.isSubstitute()) {
          <button
            class="app-header__button app-header__button--substitute"
            procure-button
            variant="ghost"
            color="white"
            [matTooltip]="'Impersonating ' + userState.current()?.name"
            (click)="logoutSubstituteUser()"
          >
            <mat-icon>admin_panel_settings</mat-icon>
          </button>
        }
        @if (lookupDataState.tsp(); as tsp) {
          <button
            class="app-header__button"
            procure-button
            variant="ghost"
            color="white"
            [matTooltip]="tsp.description || ''"
            (click)="resourceClick(tsp)"
          >
            <mat-icon>{{ tsp.icon }}</mat-icon>
          </button>
        }
        <procure-inbox />
        @if (lookupDataState.menu().length) {
          <button
            class="app-header__button"
            procure-button
            variant="ghost"
            color="white"
            matTooltip="Resources"
            [matMenuTriggerFor]="resourcesMenu"
          >
            <mat-icon>library_books</mat-icon>
          </button>
        }
        @if (userState.current(); as user) {
          @if (
            user.permissions.canViewOrganisation ||
            user.permissions.canManageOrganization ||
            user.permissions.canManageUsers ||
            user.permissions.canManageNotifications ||
            user.permissions.canManageResources ||
            user.permissions.canViewAdminView
          ) {
            <button
              class="app-header__button"
              procure-button
              variant="ghost"
              color="white"
              matTooltip="Settings"
              [matMenuTriggerFor]="adminMenu"
            >
              <mat-icon>settings</mat-icon>
            </button>
          }
          <button
            class="app-header__button app-header__button--user"
            procure-button
            variant="ghost"
            matTooltip="User"
            [matMenuTriggerFor]="userMenu"
          >
            <procure-avatar
              class="app-header__avatar"
              [avatar]="user.avatarUrl"
              [name]="user.name"
            />
          </button>
        }
      </div>
    }
    <mat-menu #resourcesMenu="matMenu">
      @for (item of lookupDataState.menu(); track item.id) {
        <a
          mat-menu-item
          [id]="'app-header-' + item.id"
          [href]="!item.isDocument ? item.url : '#'"
          [attr.target]="!item.isDocument ? '_blank' : null"
          [attr.rel]="!item.isDocument ? 'noopener noreferrer' : null"
          [matTooltip]="item.description || ''"
          (click)="resourceClick(item)"
        >
          <mat-icon>{{ item.icon ? item.icon : "open_in_new" }}</mat-icon> {{ item.name }}
        </a>
      }
    </mat-menu>
    <mat-menu #userMenu="matMenu">
      <span
        mat-menu-item
        disabled
      >
        {{ userState.current()?.name }}
      </span>
      <a
        mat-menu-item
        routerLink="/user/profile"
      >
        <mat-icon>person</mat-icon> Profile
      </a>
      <a
        mat-menu-item
        routerLink="/user/settings"
      >
        <mat-icon>settings</mat-icon> Settings
      </a>
      <a
        mat-menu-item
        routerLink="/user/notifications"
      >
        <mat-icon>notifications</mat-icon> Notifications
      </a>
      <button
        mat-menu-item
        (click)="logout()"
      >
        <mat-icon>logout</mat-icon> Logout
      </button>
    </mat-menu>
    <mat-menu #adminMenu="matMenu">
      @if (userState.current()?.permissions?.canViewOrganisation) {
        <div
          mat-menu-item
          disabled
        >
          Organisation
        </div>
      }
      @if (userState.current()?.permissions?.canViewOrganisation) {
        <a
          mat-menu-item
          routerLink="/organisation"
        >
          <mat-icon>business</mat-icon>
          Organisation
        </a>
      }
      @if (userState.current()?.permissions?.canManageUsers) {
        <a
          mat-menu-item
          routerLink="/management/users"
        >
          <mat-icon>person</mat-icon> Users
        </a>
      }
      @if (
        userState.current()?.permissions?.canManageNotifications || userState.current()?.permissions?.canManageResources
      ) {
        <div
          mat-menu-item
          disabled
        >
          Management
        </div>
      }
      @if (userState.current()?.permissions?.canManageNotifications) {
        <a
          mat-menu-item
          routerLink="/management/global-notification"
        >
          <mat-icon>announcement</mat-icon> Global Notification
        </a>
      }
      @if (userState.current()?.permissions?.canManageResources) {
        <a
          mat-menu-item
          routerLink="/management/resources"
        >
          <mat-icon>library_books</mat-icon> Resources
        </a>
      }
      @if (userState.current()?.permissions?.canViewAdminView) {
        <div
          mat-menu-item
          disabled
        >
          Admin
        </div>
      }
      @if (userState.current()?.permissions?.canViewAdminView) {
        <a
          mat-menu-item
          routerLink="/admin"
        >
          <mat-icon>admin_panel_settings</mat-icon> Admin
        </a>
      }
      @if (userState.current()?.permissions?.canViewAdminView) {
        <a
          mat-menu-item
          routerLink="/monitoring"
        >
          <mat-icon>troubleshoot</mat-icon> Monitoring
        </a>
      }
    </mat-menu>
    <procure-create-menu #createMenu />
  </div>
</div>
