import { DestroyRef, WritableSignal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Observable, Subscription } from 'rxjs';

export const fetchData$ = <T>(
  apiCall$: Observable<T>,
  loading: WritableSignal<boolean>,
  destroyRef: DestroyRef,
  callback: (response: NonNullable<T>) => void,
): Subscription => {
  loading.set(true);

  return apiCall$.pipe(takeUntilDestroyed(destroyRef)).subscribe((response) => {
    loading.set(false);

    if (!response) {
      return;
    }

    callback(response);
  });
};

export async function fetchData<T>(
  apiCall: Promise<T>,
  loading: WritableSignal<boolean>,
  callback: (response: NonNullable<T>) => void,
): Promise<void> {
  loading.set(true);

  try {
    const response = await apiCall;

    if (!response) {
      return;
    }

    callback(response);
  } finally {
    loading.set(false);
  }
}
