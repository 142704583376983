import { nullable } from '@abb-procure/api';
import * as t from 'io-ts';
import { IdName } from './id-name.type';

const TermsAndConditions = t.intersection(
  [
    IdName,
    t.type({
      isCustomized: t.boolean,
      url: nullable(t.string),
    }),
  ],
  'TermsAndConditions',
);

type TermsAndConditions = t.TypeOf<typeof TermsAndConditions>;
export { TermsAndConditions };
