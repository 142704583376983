import { nullable } from '@abb-procure/api';
import * as t from 'io-ts';
import { ProjectSearchIndexDefinitionSearchDocumentItemResult } from './project-search-index-definition-search-document-item-result.type';

const ProjectSearchIndexDefinitionSearchDocumentResult = t.type({
  count: nullable(t.number),
  values: t.array(ProjectSearchIndexDefinitionSearchDocumentItemResult),
}, 'ProjectSearchIndexDefinitionSearchDocumentResult');

type ProjectSearchIndexDefinitionSearchDocumentResult = t.TypeOf<
  typeof ProjectSearchIndexDefinitionSearchDocumentResult
>;
export { ProjectSearchIndexDefinitionSearchDocumentResult };
