import { RequestForQuotationSearchIndexDefinition } from '../types/request-for-quotation-search-index-definition.type';

export class RequestForQuotationSearchIndexDefinitionModel {
  readonly id: string | null;
  readonly projectID: string | null;
  readonly isClosed: boolean;
  readonly entityNumber: number;
  readonly number: string | null;
  readonly creator: string | null;
  readonly statusUpdater: string | null;
  readonly purchaser: string | null;
  readonly statusName: string | null;
  readonly supplier: string | null;
  readonly guid: string | null;
  readonly supplierContact: string | null;
  readonly supplierContactEmail: string | null;
  readonly subject: string | null;
  readonly internalUseComment: string | null;
  readonly projectName: string | null;
  readonly requiredScope: string | null;
  readonly projectCountryCode: string | null;
  readonly projectCountry: string | null;
  readonly mdfCodeName: string | null;
  readonly mdfCode: string | null;
  readonly wbsCode: string | null;
  readonly additionalEditors: string | null;
  readonly projectSCM: string | null;
  readonly quotations: string | null;
  readonly quotationOrderLineRows: string | null;

  constructor(definition: RequestForQuotationSearchIndexDefinition) {
    this.id = definition.id;
    this.projectID = definition.projectID;
    this.isClosed = definition.isClosed;
    this.entityNumber = definition.entityNumber;
    this.number = definition.number;
    this.creator = definition.creator;
    this.statusUpdater = definition.statusUpdater;
    this.purchaser = definition.purchaser;
    this.statusName = definition.statusName;
    this.supplier = definition.supplier;
    this.guid = definition.guid;
    this.supplierContact = definition.supplierContact;
    this.supplierContactEmail = definition.supplierContactEmail;
    this.subject = definition.subject;
    this.internalUseComment = definition.internalUseComment;
    this.projectName = definition.projectName;
    this.requiredScope = definition.requiredScope;
    this.projectCountryCode = definition.projectCountryCode;
    this.projectCountry = definition.projectCountry;
    this.mdfCodeName = definition.mdfCodeName;
    this.mdfCode = definition.mdfCode;
    this.wbsCode = definition.wbsCode;
    this.additionalEditors = definition.additionalEditors;
    this.projectSCM = definition.projectSCM;
    this.quotations = definition.quotations;
    this.quotationOrderLineRows = definition.quotationOrderLineRows;
  }
}
