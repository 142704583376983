import { v4 as uuidV4 } from 'uuid';

export const BASE_ORDER_BY_OPTIONS = [
  { uuid: uuidV4(), value: 'name', label: 'Name' },
  { uuid: uuidV4(), value: 'entity', label: 'Entity' },
  { uuid: uuidV4(), value: 'status', label: 'Status' },
];

export const DASHBOARD_ORDER_BY_OPTIONS = [
  { uuid: uuidV4(), value: 'name', label: 'Name' },
  { uuid: uuidV4(), value: 'entity', label: 'Entity' },
  { uuid: uuidV4(), value: 'status', label: 'Status' },
  { uuid: uuidV4(), value: 'date', label: 'Due Date' },
  { uuid: uuidV4(), value: 'createddate', label: 'Created Date' },
  { uuid: uuidV4(), value: 'updateddate', label: 'Updated Date' },
];

export const SOURCING_SCOPE_ORDER_BY_OPTIONS = [
  { uuid: uuidV4(), value: 'name', label: 'Name' },
  { uuid: uuidV4(), value: 'entity', label: 'Entity' },
  { uuid: uuidV4(), value: 'status', label: 'Status' },
  { uuid: uuidV4(), value: 'organisation', label: 'Organisation' },
  { uuid: uuidV4(), value: 'purchaser', label: 'Purchaser' },
  { uuid: uuidV4(), value: 'dueDate', label: 'Due Date' },
];

export const SUPPLIERS_ORDER_BY_OPTIONS = [
  { uuid: uuidV4(), value: 'SmVendorId', label: 'SM Vendor ID' },
  { uuid: uuidV4(), value: 'ErpVendorId', label: 'ERP Vendor ID' },
  { uuid: uuidV4(), value: 'Guid', label: 'GUID' },
  { uuid: uuidV4(), value: 'Company', label: 'Company' },
  { uuid: uuidV4(), value: 'Country', label: 'Country' },
  { uuid: uuidV4(), value: 'City', label: 'City' },
  { uuid: uuidV4(), value: 'Contacts', label: 'Contacts' },
  {
    uuid: uuidV4(),
    value: 'LocalDivisionAssignments',
    label: 'Local Division Assignments',
  },
  { uuid: uuidV4(), value: 'Qualification', label: 'Qualification' },
  { uuid: uuidV4(), value: 'Registration', label: 'Registration' },
  { uuid: uuidV4(), value: 'Active', label: 'Is Active' },
  { uuid: uuidV4(), value: 'Internal', label: 'Is Internal' },
];

export const CATEGORIES_ORDER_BY_OPTIONS = [
  { uuid: uuidV4(), value: 'Name', label: 'Name' },
  { uuid: uuidV4(), value: 'Validity', label: 'Validity' },
  { uuid: uuidV4(), value: 'Status', label: 'Status' },
];

export const TEMPLATES_ORDER_BY_OPTIONS = [
  { uuid: uuidV4(), value: 'Name', label: 'Name' },
  { uuid: uuidV4(), value: 'Category', label: 'Category ID' },
  { uuid: uuidV4(), value: 'Owner', label: 'Owner' },
  { uuid: uuidV4(), value: 'Status', label: 'Status' },
];

export const SUPPLIER_STRATEGY_SOURCING_EVENTS_ORDER_BY_OPTIONS = [
  { uuid: uuidV4(), value: 'RfqNumber', label: 'RFQ Number' },
  { uuid: uuidV4(), value: 'RfqStatus', label: 'RFQ Status' },
  { uuid: uuidV4(), value: 'DueDate', label: 'Due Date' },
  { uuid: uuidV4(), value: 'Purchaser', label: 'Purchaser' },
  { uuid: uuidV4(), value: 'Created', label: 'Created' },
  { uuid: uuidV4(), value: 'RfrfqNumber', label: 'RFRFQ Number' },
  { uuid: uuidV4(), value: 'RfrfqStatus', label: 'RFRFQ Status' },
  { uuid: uuidV4(), value: 'RfrfqName', label: 'RFRFQ Name' },
  { uuid: uuidV4(), value: 'ProjectStatus', label: 'Project Status' },
  { uuid: uuidV4(), value: 'ProjectNumber', label: 'Project Number' },
  { uuid: uuidV4(), value: 'LocalDivision', label: 'Local Division' },
  { uuid: uuidV4(), value: 'QuotationNumber', label: 'Quotation Number' },
  { uuid: uuidV4(), value: 'QuotationStatus', label: 'Quotation Status' },
];

export const CATEGORY_MONITORING_ORDER_BY_OPTIONS = [
  { uuid: uuidV4(), value: 'Entity', label: 'Entity' },
  { uuid: uuidV4(), value: 'Template', label: 'Template' },
  { uuid: uuidV4(), value: 'Status', label: 'Status' },
  { uuid: uuidV4(), value: 'Organisation', label: 'Organisation' },
  { uuid: uuidV4(), value: 'Purchaser', label: 'Purchaser' },
  { uuid: uuidV4(), value: 'DueDate', label: 'Due Date' },
  { uuid: uuidV4(), value: 'DeliveryDate', label: 'Delivery Date' },
  { uuid: uuidV4(), value: 'OfferValidityDate', label: 'Offer Validity Date' },
  { uuid: uuidV4(), value: 'MdfCode', label: 'MDF Code' },
  {
    uuid: uuidV4(),
    value: 'SourcingEventBudget',
    label: 'Sourcing Event Budget (USD)',
  },
  { uuid: uuidV4(), value: 'TargetPrice', label: 'Target Price (USD)' },
  {
    uuid: uuidV4(),
    value: 'LeadingQuotationBudget',
    label: 'Leading Quotation Budget (USD)',
  },
  {
    uuid: uuidV4(),
    value: 'CompetitiveBiddingStatus',
    label: 'Competitive Bidding Status',
  },
];

export const EXTERNAL_CONTACTS_ORDER_BY_OPTIONS = [
  { uuid: uuidV4(), value: 'FirstName', label: 'First Name' },
  { uuid: uuidV4(), value: 'LastName', label: 'Last Name' },
  { uuid: uuidV4(), value: 'Email', label: 'Email' },
  { uuid: uuidV4(), value: 'Function', label: 'Function' },
  { uuid: uuidV4(), value: 'Telephone', label: 'Telephone' },
  { uuid: uuidV4(), value: 'Mobile', label: 'Mobile' },
  { uuid: uuidV4(), value: 'Emails', label: 'Emails' },
  { uuid: uuidV4(), value: 'Rfqs', label: 'RFQs' },
  { uuid: uuidV4(), value: 'ResponseTime', label: 'Response Time' },
  { uuid: uuidV4(), value: 'LastResponse', label: 'Last Response' },
  { uuid: uuidV4(), value: 'Note', label: 'Note' },
  { uuid: uuidV4(), value: 'Archived', label: 'Archived' },
];

export const LEGAL_CONTRACTS_ORDER_BY_OPTIONS = [
  { uuid: uuidV4(), value: 'Title', label: 'Title' },
  { uuid: uuidV4(), value: 'Owner', label: 'Owner' },
  { uuid: uuidV4(), value: 'Effective', label: 'Effective' },
  { uuid: uuidV4(), value: 'Amount', label: 'Amount' },
  { uuid: uuidV4(), value: 'Status', label: 'Status' },
];

export const STRATEGY_MANAGEMENT_ORDER_BY_OPTIONS = [
  { uuid: uuidV4(), value: 'Name', label: 'Name' },
  { uuid: uuidV4(), value: 'OpenActionsCount', label: 'Open Actions' },
  { uuid: uuidV4(), value: 'ExpirationDate', label: 'Expiration Date' },
  { uuid: uuidV4(), value: 'Status', label: 'Status' },
];

export const LOCAL_DIVISION_USERS_ORDER_BY_OPTIONS = [
  { uuid: uuidV4(), value: 'Name', label: 'Name' },
  { uuid: uuidV4(), value: 'Email', label: 'Email' },
  { uuid: uuidV4(), value: 'Role', label: 'Role' },
  { uuid: uuidV4(), value: 'Status', label: 'Status' },
  { uuid: uuidV4(), value: 'ReviewStatus', label: 'Review Status' },
  { uuid: uuidV4(), value: 'ReviewDate', label: 'Review Date' },
  { uuid: uuidV4(), value: 'NextReview', label: 'Next Review' },
];

export const ORGANISATION_MANAGERS_ORDER_BY_OPTIONS = [
  { uuid: uuidV4(), value: 'Name', label: 'Name' },
  { uuid: uuidV4(), value: 'Status', label: 'Status' },
  { uuid: uuidV4(), value: 'ReviewStatus', label: 'Review Status' },
  { uuid: uuidV4(), value: 'ReviewDate', label: 'Last Review' },
  { uuid: uuidV4(), value: 'NextReview', label: 'Next Review' },
];

export const LOCAL_DIVISION_ASSIGNMENTS_ORDER_BY_OPTIONS = [
  { uuid: uuidV4(), value: 'Name', label: 'Name' },
  { uuid: uuidV4(), value: 'Role', label: 'Role' },
  { uuid: uuidV4(), value: 'ReviewStatus', label: 'Review Status' },
  { uuid: uuidV4(), value: 'ReviewDate', label: 'Review Date' },
];

export const DEFAULT_ORDER = {
  orderBy: 'name',
  orderIsAscending: true,
};

export const USERS_ORDER_BY_OPTIONS = [
  { uuid: uuidV4(), value: 'Name', label: 'Name' },
  { uuid: uuidV4(), value: 'Email', label: 'Email' },
  { uuid: uuidV4(), value: 'LocalDivision', label: 'Local Division' },
  { uuid: uuidV4(), value: 'Active', label: 'Active' },
];

export const CURRENCIES_ORDER_BY_OPTIONS = [
  { uuid: uuidV4(), value: 'Name', label: 'Name' },
  { uuid: uuidV4(), value: 'Code', label: 'Code' },
  { uuid: uuidV4(), value: 'Status', label: 'Status' },
];

export const INCOTERMS_ORDER_BY_OPTIONS = [
  { uuid: uuidV4(), value: 'Name', label: 'Name' },
  { uuid: uuidV4(), value: 'Code', label: 'Code' },
];

export const PAYMENT_TERMS_ORDER_BY_OPTIONS = [
  { uuid: uuidV4(), value: 'Name', label: 'Name' },
];

export const EXCHANGE_RATES_ORDER_BY_OPTIONS = [
  { uuid: uuidV4(), value: 'Name', label: 'Name' },
  { uuid: uuidV4(), value: 'Code', label: 'Code' },
  { uuid: uuidV4(), value: 'Unit', label: 'Unit' },
  { uuid: uuidV4(), value: 'ClosingRate', label: 'Closing Rate' },
];

export const VARIABLES_ORDER_BY_OPTIONS = [
  { uuid: uuidV4(), value: 'Name', label: 'Name' },
];

export const LINKS_ORDER_BY_OPTIONS = [
  { uuid: uuidV4(), value: 'Name', label: 'Name' },
  { uuid: uuidV4(), value: 'Description', label: 'Description' },
  { uuid: uuidV4(), value: 'URL', label: 'URL' },
];

export const DOCUMENTS_ORDER_BY_OPTIONS = [
  { uuid: uuidV4(), value: 'Name', label: 'Name' },
  { uuid: uuidV4(), value: 'Description', label: 'Description' },
  { uuid: uuidV4(), value: 'Document', label: 'Document' },
];

export const HEATMAP_ORDER_BY_OPTIONS = [
  { uuid: uuidV4(), value: 'supplierName', label: 'Supplier Name' },
  { uuid: uuidV4(), value: 'supplierSmVendorId', label: 'SmVendorId' },
  { uuid: uuidV4(), value: 'supplierCity', label: 'City' },
  { uuid: uuidV4(), value: 'supplierGuid', label: 'GUID' },
  { uuid: uuidV4(), value: 'supplierScore', label: 'Score' },
  { uuid: uuidV4(), value: 'supplierResponseScore', label: 'Response Time' },
];

export const TERMS_AND_CONDITIONS_ORDER_BY_OPTIONS = [
  { uuid: uuidV4(), value: 'Name', label: 'Name' },
  { uuid: uuidV4(), value: 'Url', label: 'Url' },
  { uuid: uuidV4(), value: 'IsAvailable', label: 'Available' },
];

export const INCIDENTS_ORDER_BY_OPTIONS = [
  { uuid: uuidV4(), value: 'Name', label: 'Name' },
  { uuid: uuidV4(), value: 'MdfCode', label: 'MDF Code' },
  { uuid: uuidV4(), value: 'Status', label: 'Status' },
  { uuid: uuidV4(), value: 'ImpactStatus', label: 'Impact' },
  { uuid: uuidV4(), value: 'Created', label: 'Created' },
  { uuid: uuidV4(), value: 'Resolved', label: 'Resolved' },
];

export const SPE_SUPPLIERS_ORDER_BY_OPTIONS = [
  { uuid: uuidV4(), value: 'Name', label: 'Name' },
  { uuid: uuidV4(), value: 'Status', label: 'Status' },
];

export const SPE_EVALUATORS_ORDER_BY_OPTIONS = [
  { uuid: uuidV4(), value: 'Name', label: 'Name' },
  { uuid: uuidV4(), value: 'Dimension', label: 'Dimension' },
  { uuid: uuidV4(), value: 'Email', label: 'Email' },
];

export const SUPPLIER_PERFORMANCE_EVALUATIONS_ORDER_BY_OPTIONS = [
  { uuid: uuidV4(), value: 'entityNumber', label: 'Entity Number' },
  { uuid: uuidV4(), value: 'surveyName', label: 'Survey Name' },
  { uuid: uuidV4(), value: 'evaluationPeriod', label: 'Evaluation Period' },
  { uuid: uuidV4(), value: 'suppliersCount', label: 'Suppliers Count' },
  { uuid: uuidV4(), value: 'mdfCodes', label: 'MDF Codes' },
  { uuid: uuidV4(), value: 'status', label: 'Status' },
  { uuid: uuidV4(), value: 'remainingDays', label: 'Remaining Days' },
  {
    uuid: uuidV4(),
    value: 'generalSubmissionProgress',
    label: 'General Submission Progress',
  },
];

export const USER_SURVEY_EVALUATIONS_ORDER_BY_OPTIONS = [
  { uuid: uuidV4(), value: 'user', label: 'User' },
  { uuid: uuidV4(), value: 'status', label: 'Status' },
];

export const SURVEY_SUPPLIER_EVALUATIONS_ORDER_BY_OPTIONS = [
  { uuid: uuidV4(), value: 'supplier', label: 'Supplier' },
  { uuid: uuidV4(), value: 'score', label: 'Score' },
  { uuid: uuidV4(), value: 'submittedCount', label: 'Submitted Count' },
];

export const SUPPLIER_EVALUATIONS_SEARCH_ORDER_BY_OPTIONS = [
  { uuid: uuidV4(), value: 'localdivision', label: 'Local Division' },
  { uuid: uuidV4(), value: 'hub', label: 'Hub' },
  { uuid: uuidV4(), value: 'displayName', label: 'Title' },
  { uuid: uuidV4(), value: 'score', label: 'Score' },
  { uuid: uuidV4(), value: 'mdfCodes', label: 'MDF Codes' },
  { uuid: uuidV4(), value: 'owners', label: 'Owners' },
  { uuid: uuidV4(), value: 'supplierName', label: 'Supplier Name' },
  { uuid: uuidV4(), value: 'publishedDate', label: 'Published Date' },
  { uuid: uuidV4(), value: 'minimumDateRange', label: 'Minimum Date Range' },
  { uuid: uuidV4(), value: 'maximumDateRange', label: 'Maximum Date Range' },
];

export const SURVEY_ACTIVITY_EVENTS_ORDER_BY_OPTIONS = [
  { uuid: uuidV4(), value: 'date', label: 'Date' },
];
