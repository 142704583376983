import { Directive, HostBinding, Input } from '@angular/core';
import { DEFAULT_LAYOUT_GAP } from '@abb-procure/constants';

@Directive({
  selector: '[formRow]',
  standalone: true,
})
export class FormRowDirective {
  @HostBinding('class.form-row') formRowClass: boolean = true;

  @Input() @HostBinding('class.form-row__border-bottom') borderBottom: boolean = false;

  @Input() @HostBinding('class.form-row--standalone') standalone: boolean = false;

  @Input() formChildGap: number = DEFAULT_LAYOUT_GAP;
}
