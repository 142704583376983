import { ProjectSearchIndexDefinition } from '../types/project-search-index-definition.type';

export class ProjectSearchIndexDefinitionModel
  implements ProjectSearchIndexDefinition
{
  readonly id: string | null;
  readonly projectID: string | null;
  readonly isTest: boolean;
  readonly isClosed: boolean;
  readonly entityNumber: number;
  readonly projectName: string | null;
  readonly creator: string | null;
  readonly statusUpdater: string | null;
  readonly purchaser: string | null;
  readonly projectResponsible: string | null;
  readonly technicalResponsible: string | null;
  readonly statusName: string | null;
  readonly customer: string | null;
  readonly salesforceID: string | null;
  readonly ipmProjectId: string | null;
  readonly erpProjectId: string | null;
  readonly scope: string | null;
  readonly termsAndConditions: string | null;
  readonly generalInformationComment: string | null;
  readonly projectDescriptionComment: string | null;
  readonly projectCountry: string | null;
  readonly projectCountryCode: string | null;
  readonly number: string | null;
  readonly internalConsortium: string | null;
  readonly additionalEditors: string | null;
  readonly projectSCM: string | null;

  constructor(definition: ProjectSearchIndexDefinition) {
    this.id = definition.id;
    this.projectID = definition.projectID;
    this.isTest = definition.isTest;
    this.isClosed = definition.isClosed;
    this.entityNumber = definition.entityNumber;
    this.projectName = definition.projectName;
    this.creator = definition.creator;
    this.statusUpdater = definition.statusUpdater;
    this.purchaser = definition.purchaser;
    this.projectResponsible = definition.projectResponsible;
    this.technicalResponsible = definition.technicalResponsible;
    this.statusName = definition.statusName;
    this.customer = definition.customer;
    this.salesforceID = definition.salesforceID;
    this.ipmProjectId = definition.ipmProjectId;
    this.erpProjectId = definition.erpProjectId;
    this.scope = definition.scope;
    this.termsAndConditions = definition.termsAndConditions;
    this.generalInformationComment = definition.generalInformationComment;
    this.projectDescriptionComment = definition.projectDescriptionComment;
    this.projectCountry = definition.projectCountry;
    this.projectCountryCode = definition.projectCountryCode;
    this.number = definition.number;
    this.internalConsortium = definition.internalConsortium;
    this.additionalEditors = definition.additionalEditors;
    this.projectSCM = definition.projectSCM;
  }
}
