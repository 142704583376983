import { nullable } from '@abb-procure/api';
import * as t from 'io-ts';

const PurchaseRequisitionSearchIndexDefinition = t.type(
  {
    id: nullable(t.string),
    projectID: nullable(t.string),
    isClosed: t.boolean,
    entityNumber: t.number,
    creator: nullable(t.string),
    statusUpdater: nullable(t.string),
    purchaser: nullable(t.string),
    requester: nullable(t.string),
    statusName: nullable(t.string),
    poNumber: nullable(t.string),
    projectName: nullable(t.string),
    sapCostAccount: nullable(t.string),
    sapSdPosition: nullable(t.string),
    sapCostCenter: nullable(t.string),
    sapCostElement: nullable(t.string),
    sapProjectNumber: nullable(t.string),
    sapPspElement: nullable(t.string),
    poDocument: nullable(t.string),
    poComment: nullable(t.string),
    requiredScope: nullable(t.string),
    supplierInvoicingInstructions: nullable(t.string),
    deliveryDestinationRelatedComments: nullable(t.string),
    specialTermsAndConditionsAndAnnexes: nullable(t.string),
    attachmentsToPO: nullable(t.string),
    supplier: nullable(t.string),
    supplierContact: nullable(t.string),
    supplierContactEmail: nullable(t.string),
    deliveryDestination: nullable(t.string),
    comment: nullable(t.string),
    internalUseComment: nullable(t.string),
    endUseCountry: nullable(t.string),
    endUseCountryCode: nullable(t.string),
    requiredSubmittalsToBeReturned: nullable(t.string),
    returnedSubmittals: nullable(t.string),
    projectLUI: nullable(t.string),
    number: nullable(t.string),
    projectCountry: nullable(t.string),
    projectCountryCode: nullable(t.string),
    costAllocation: nullable(t.string),
    businessUnit: nullable(t.string),
    mdfCodeName: nullable(t.string),
    mdfCode: nullable(t.string),
    goodsCountryOfOrigin: nullable(t.string),
    goodsCountryOfOriginCode: nullable(t.string),
    forFurtherDeliveryTo: nullable(t.string),
    deliveryAddressName: nullable(t.string),
    deliveryAddressLine: nullable(t.string),
    deliveryAddressCity: nullable(t.string),
    deliveryAddressCountry: nullable(t.string),
    deliveryAddressCountryCode: nullable(t.string),
    incotermsComments: nullable(t.string),
    personAndDepartment: nullable(t.string),
    supplierSuggestions: nullable(t.string),
    additionalEditors: nullable(t.string),
    projectSCM: nullable(t.string),
  },
  'PurchaseRequisitionSearchIndexDefinition',
);

type PurchaseRequisitionSearchIndexDefinition = t.TypeOf<
  typeof PurchaseRequisitionSearchIndexDefinition
>;
export { PurchaseRequisitionSearchIndexDefinition };
