import { nullable } from '@abb-procure/api';
import * as t from 'io-ts';

const GuidNameCode = t.type(
  {
    id: t.string,
    code: nullable(t.string),
    name: nullable(t.string),
  },
  'GuidNameCode',
);

type GuidNameCode = t.TypeOf<typeof GuidNameCode>;
export { GuidNameCode };
