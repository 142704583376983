import { RequestForRequestForQuotationSearchIndexDefinition } from '../types/request-for-request-for-quotation-search-index-definition.type';

export class RequestForRequestForQuotationSearchIndexDefinitionModel {
  readonly id: string | null;
  readonly projectID: string | null;
  readonly isClosed: boolean;
  readonly entityNumber: number;
  readonly requiredScope: string | null;
  readonly creator: string | null;
  readonly statusName: string | null;
  readonly requester: string | null;
  readonly purchaser: string | null;
  readonly statusUpdater: string | null;
  readonly requiredSubmittalsToBeReturned: string | null;
  readonly projectName: string | null;
  readonly suppliers: string | null;
  readonly supplierContacts: string | null;
  readonly supplierContactEmails: string | null;
  readonly number: string | null;
  readonly projectCountry: string | null;
  readonly projectCountryCode: string | null;
  readonly mdfCodeName: string | null;
  readonly mdfCode: string | null;
  readonly wbsCode: string | null;
  readonly businessUnit: string | null;
  readonly comments: string | null;
  readonly internalUseComment: string | null;
  readonly finalDestinationCountry: string | null;
  readonly finalDestinationCountryCode: string | null;
  readonly specificationAndQuantities: string | null;
  readonly supplierSuggestions: string | null;
  readonly projectResponsible: string | null;
  readonly technicalResponsible: string | null;
  readonly additionalEditors: string | null;
  readonly projectSCM: string | null;

  constructor(definition: RequestForRequestForQuotationSearchIndexDefinition) {
    this.id = definition.id;
    this.projectID = definition.projectID;
    this.isClosed = definition.isClosed;
    this.entityNumber = definition.entityNumber;
    this.requiredScope = definition.requiredScope;
    this.creator = definition.creator;
    this.statusName = definition.statusName;
    this.requester = definition.requester;
    this.purchaser = definition.purchaser;
    this.statusUpdater = definition.statusUpdater;
    this.requiredSubmittalsToBeReturned =
      definition.requiredSubmittalsToBeReturned;
    this.projectName = definition.projectName;
    this.suppliers = definition.suppliers;
    this.supplierContacts = definition.supplierContacts;
    this.supplierContactEmails = definition.supplierContactEmails;
    this.number = definition.number;
    this.projectCountry = definition.projectCountry;
    this.projectCountryCode = definition.projectCountryCode;
    this.mdfCodeName = definition.mdfCodeName;
    this.mdfCode = definition.mdfCode;
    this.wbsCode = definition.wbsCode;
    this.businessUnit = definition.businessUnit;
    this.comments = definition.comments;
    this.internalUseComment = definition.internalUseComment;
    this.finalDestinationCountry = definition.finalDestinationCountry;
    this.finalDestinationCountryCode = definition.finalDestinationCountryCode;
    this.specificationAndQuantities = definition.specificationAndQuantities;
    this.supplierSuggestions = definition.supplierSuggestions;
    this.projectResponsible = definition.projectResponsible;
    this.technicalResponsible = definition.technicalResponsible;
    this.additionalEditors = definition.additionalEditors;
    this.projectSCM = definition.projectSCM;
  }
}
