import { BaseModel } from '@abb-procure/data';
import { PurchaseRequisitionSearchIndexDefinitionSearchDocumentItemResult } from '../types/purchase-requisition-search-index-definition-search-document-item-result.type';
import { PurchaseRequisitionSearchIndexDefinitionModel } from './purchase-requisition-search-index-definition.model';

export class PurchaseRequisitionSearchIndexDefinitionSearchDocumentItemResultModel extends BaseModel {
  readonly value: PurchaseRequisitionSearchIndexDefinitionModel;
  readonly highlights: Record<string, string[]> | null;
  readonly searchScore: number | null;

  constructor(
    result: PurchaseRequisitionSearchIndexDefinitionSearchDocumentItemResult,
  ) {
    super();

    this.value = new PurchaseRequisitionSearchIndexDefinitionModel(result.value);
    this.highlights = result.highlights;
    this.searchScore = result.searchScore;
  }
}
