import { GuidNameCode } from '../types/guid-name-code.type';
import { BaseModel } from './base.model';

export class GuidNameCodeModel extends BaseModel implements GuidNameCode {
  readonly id: string;
  readonly code: string | null;
  readonly name: string | null;

  constructor(guidCodeName: GuidNameCode) {
    super();

    this.id = guidCodeName.id;
    this.code = guidCodeName.code;
    this.name = guidCodeName.name;
  }

  getDisplay(): string {
    if (this.code && this.name) {
      return `${this.code} - ${this.name}`;
    }

    if (this.code) {
      return this.code;
    }

    return `${this.name}`;
  }
}
