<div class="button__focus"></div>
@if (prefix) {
  <mat-icon class="button__prefix">
    {{ prefix }}
  </mat-icon>
}
<div class="button__content">
  <ng-content />
  @if (condensedIcon) {
    <mat-icon class="button__condensed-icon">
      {{ condensedIcon }}
    </mat-icon>
  }
  @if (suffix) {
    <mat-icon class="button__suffix">
      {{ suffix }}
    </mat-icon>
  }
</div>
@if (loading) {
  <div class="button__spinner-wrapper">
    <mat-spinner
      class="button__spinner"
      [diameter]="16"
    />
  </div>
}
@if (badge) {
  <div
    matBadge
    class="button__badge"
  >
    {{ badge > badgeLimit ? badgeLimit + '+' : badge }}
  </div>
}
