import { UserAssignment } from '../types/user-assignment.type';
import { GuidNameCodeModel } from './guid-name-code.model';
import { UserSlimModel } from './user-slim.model';

export class UserAssignmentModel implements UserAssignment {
  readonly unit: GuidNameCodeModel | null = null;
  readonly managers: UserSlimModel[] | null = null;

  constructor(userAssignment: UserAssignment) {
    if (userAssignment.unit) {
      this.unit = new GuidNameCodeModel(userAssignment.unit);
    }

    if (userAssignment.managers) {
      this.managers = userAssignment.managers.map(
        (manager) => new UserSlimModel(manager),
      );
    }
  }
}
