import {
  Component, Input, HostBinding, ChangeDetectionStrategy, ViewEncapsulation,
} from '@angular/core';
import { EntityStatusModel } from '@abb-procure/data';
import { BadgeComponent } from '../badge/badge.component';

@Component({
  selector: 'procure-status-badge',
  templateUrl: './status-badge.component.html',
  styleUrls: ['./status-badge.component.scss'],
  standalone: true,
  imports: [BadgeComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class StatusBadgeComponent {
  @HostBinding('class')
  @Input()
  protected hostClass: string = '';

  @Input() protected status?: EntityStatusModel | null;
}
