import { Preference } from '../types/preference.type';

export class PreferenceModel implements Preference {
  readonly key: string | null = null;
  readonly value: string | null = null;

  constructor(preference: Preference) {
    this.key = preference.key;
    this.value = preference.value;
  }
}
