import {
  Component, EventEmitter, Output, ViewEncapsulation, booleanAttribute, input,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSlideToggleChange, MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NgTemplateOutlet } from '@angular/common';
import { isRequired } from '../../utilities/form-control.util';

@Component({
  selector: 'procure-input-switch',
  templateUrl: './input-switch.component.html',
  styleUrls: ['./input-switch.component.scss'],
  standalone: true,
  imports: [
    MatSlideToggleModule,
    MatTooltipModule,
    ReactiveFormsModule,
    NgTemplateOutlet,
  ],
  encapsulation: ViewEncapsulation.None,
})
export class InputSwitchComponent {
  @Output() readonly valueChange = new EventEmitter<MatSlideToggleChange>();

  id = input<string | null>(null);
  control = input.required<FormControl>();
  tooltip = input<string>('');
  labelPosition = input<'before' | 'after'>('after');
  showMode = input<boolean, boolean | string>(false, { transform: booleanAttribute });
  condensed = input<boolean, boolean | string>(false, { transform: booleanAttribute });

  protected get isRequired(): boolean {
    return isRequired(this.control());
  }
}
