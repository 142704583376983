import * as t from 'io-ts';
import { ProjectSearchIndexDefinitionSearchDocumentResult } from './project-search-index-definition-search-document-result.type';
import { PurchaseRequisitionSearchIndexDefinitionSearchDocumentResult } from './purchase-requisition-search-index-definition-search-document-result.type';
import { RequestForQuotationSearchIndexDefinitionSearchDocumentResult } from './request-for-quotation-search-index-definition-search-document-result.type';
import { RequestForRequestForQuotationSearchIndexDefinitionSearchDocumentResult } from './request-for-request-for-quotation-search-index-definition-search-document-result.type';
import { SupplierSearchIndexDefinitionSearchDocumentResult } from './supplier-search-index-definition-search-document-result.type';

const SearchDocumentResponse = t.type({
  projects: t.union([ProjectSearchIndexDefinitionSearchDocumentResult, t.null], 'projects'),
  requestForRequestForQuotations: t.union([
    RequestForRequestForQuotationSearchIndexDefinitionSearchDocumentResult,
    t.null,
  ], 'requestForRequestForQuotations'),
  requestForQuotations: t.union([RequestForQuotationSearchIndexDefinitionSearchDocumentResult, t.null], 'requestForQuotations'),
  purchaseRequisitions: t.union([PurchaseRequisitionSearchIndexDefinitionSearchDocumentResult, t.null], 'purchaseRequisitions'),
  suppliers: t.union([SupplierSearchIndexDefinitionSearchDocumentResult, t.null], 'suppliers'),
}, 'SearchDocumentResponse');

type SearchDocumentResponse = t.TypeOf<
  typeof SearchDocumentResponse
>;
export { SearchDocumentResponse };
