<div
  class="message"
  [class.message--condensed]="condensed"
  >
  @if (message.sender; as sender) {
    <procure-avatar
      class="message__avatar"
      [name]="sender.name"
      [avatar]="sender.avatarUrl"
    />
  } @else {
    <div class="message__system">
      <img
        class="message__system-icon"
        src="/assets/images/logo-procure-brand-small.svg"
        alt="ABB Procure"
      />
    </div>
  }
  <ng-template #system>
    <div class="message__system">
      <img
        class="message__system-icon"
        src="/assets/images/logo-procure-brand-small.svg"
        alt="ABB Procure"
      />
    </div>
  </ng-template>
  <div class="message__detail">
    <div class="message__header">
      <div
        class="message__title"
        [class.message__title--unread]="!message.isRead"
        >
        @if (message.data.entityNumber) {
          <procure-entity-badge
            [label]="message.data.entityNumber"
            [condensed]="condensed"
          />
        }
        {{ message.title }}
      </div>
      <div class="message__date">
        {{ message.createdOn | date : 'short' }}
        @if (message.isArchived) {
          - Archived
        }
      </div>
    </div>
    @if (message.message && message.message !== '<p></p>') {
      <div
        class="message__content"
        [innerHTML]="message.message | sanitize"
      ></div>
    }
  </div>
  <div class="message__actions">
    <button
      procure-button
      variant="ghost"
      condensed
      color="black"
      [matTooltip]="message.isArchived ? 'Unarchive' : 'Archive'"
      (click)="toggleArchive.emit($event)"
    >
      <mat-icon class="message__icon">{{ message.isArchived ? 'unarchive' : 'archive' }}</mat-icon>
    </button>
    <button
      procure-button
      variant="ghost"
      condensed
      [color]="message.isRead ? 'black' : 'primary'"
      [matTooltip]="message.isRead ? 'Mark as unread' : 'Mark as read'"
      (click)="toggleRead.emit($event)"
    >
      <mat-icon class="message__icon">{{ message.isRead ? 'radio_button_unchecked' : 'radio_button_checked' }}</mat-icon>
    </button>
  </div>
</div>
