import {
  Injectable, computed, effect, inject, signal,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { UNREAD_NOTIFICATIONS_LIMIT } from '@abb-procure/constants';

@Injectable({
  providedIn: 'root',
})
export class TitleService {
  private view = signal<string | null>(null);
  private unread = signal<number | null>(null);

  private title = computed(() => {
    const view = this.view();
    const unread = this.unread();

    if (!view) {
      return 'ABB Procure';
    }

    if (!unread) {
      return `${view} - ABB Procure`;
    }

    const unreadLabel = unread > UNREAD_NOTIFICATIONS_LIMIT ? `${UNREAD_NOTIFICATIONS_LIMIT}+` : unread;

    return `(${unreadLabel}) ${view} - ABB Procure`;
  });

  private readonly titleHelper = inject(Title);

  constructor() {
    effect(() => {
      this.titleHelper.setTitle(this.title());
    });
  }

  setTitle(viewTitle: string | null): void {
    this.view.set(viewTitle);
  }

  setUnread(value: number | null): void {
    this.unread.set(value);
  }
}
