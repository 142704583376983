import {
  HttpEvent, HttpHandlerFn, HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';

const checkOnPureObject = (obj: unknown): boolean => {
  if (typeof obj !== 'object') return false;
  if (obj instanceof Date) return false;
  if (obj instanceof Function) return false;
  if (obj instanceof Array) return false;

  return true;
};

const shiftDates = (body: Record<string, unknown> | null): Record<string, unknown> | null => {
  if (body === null || body === undefined || !checkOnPureObject(body)) {
    return body;
  }

  const copy = { ...body };

  Object.keys(copy).forEach((key) => {
    const value = copy[key];

    if (value instanceof Date) {
      copy[key] = new Date(Date.UTC(
        value.getFullYear(),
        value.getMonth(),
        value.getDate(),
        value.getHours(),
        value.getMinutes(),
        value.getSeconds(),
      ));
    } else if (Array.isArray(value)) {
      copy[key] = value.map((item) => shiftDates(item));
    } else if (typeof value === 'object') {
      copy[key] = shiftDates(value as Record<string, unknown>);
    }
  });

  return copy;
};

export const dateInterceptor = (
  req: HttpRequest<Record<string, unknown>>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> => {
  if (req.body instanceof FormData) {
    return next(req);
  }

  if (req.method === 'POST' || req.method === 'PUT') {
    // eslint-disable-next-line no-param-reassign
    req = req.clone({
      body: shiftDates(req.body),
    });
  }

  return next(req);
};
