import { nullable } from '@abb-procure/api';
import * as t from 'io-ts';
import { PurchaseRequisitionSearchIndexDefinitionSearchDocumentItemResult } from './purchase-requisition-search-index-definition-search-document-item-result.type';

const PurchaseRequisitionSearchIndexDefinitionSearchDocumentResult = t.type({
  count: nullable(t.number),
  values: t.array(PurchaseRequisitionSearchIndexDefinitionSearchDocumentItemResult),
}, 'PurchaseRequisitionSearchIndexDefinitionSearchDocumentResult');

type PurchaseRequisitionSearchIndexDefinitionSearchDocumentResult = t.TypeOf<
  typeof PurchaseRequisitionSearchIndexDefinitionSearchDocumentResult
>;
export { PurchaseRequisitionSearchIndexDefinitionSearchDocumentResult };
