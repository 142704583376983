import { GlobalNotificationResponse } from '../types/global-notification-response.type';
import { ApplicationNotificationModel } from './application-notification.model';

export class GlobalNotificationResponseModel
  implements GlobalNotificationResponse
{
  readonly globalNotification: ApplicationNotificationModel | null;
  readonly privacyNotification: ApplicationNotificationModel;

  constructor(data: GlobalNotificationResponse) {
    this.globalNotification = data.globalNotification
      ? new ApplicationNotificationModel(data.globalNotification)
      : null;

    this.privacyNotification = new ApplicationNotificationModel(
      data.privacyNotification,
    );
  }
}
