import { ApiService, CustomHttpParams, nullable } from '@abb-procure/api';
import { Injectable } from '@angular/core';
import {
  array as ioArray,
  number as ioNumber,
  record as ioRecord,
  string as ioString,
} from 'io-ts';
import { LookupDataResponseModel } from '../models/lookup/lookup-data-response.model';
import { MdfCodeModel } from '../models/mdf-code.model';
import { ResourceLinkSlimModel } from '../models/resource-link-slim.model';
import { ResourceResponseModel } from '../models/resource-response.model';
import { LookupDataResponse } from '../types/lookup/lookup-data-response.type';
import { MdfCode } from '../types/mdf-code.type';
import { ResourceLinkSlim } from '../types/resource-link-slim.type';
import { ResourceResponse } from '../types/resource-response.type';

@Injectable({
  providedIn: 'root',
})
export class LookupDataApiService extends ApiService {
  /**
   * Retrieves all required base data.
   */
  async getBaseData(): Promise<LookupDataResponseModel | null> {
    try {
      const response = await this.get<LookupDataResponse>(
        'Organisation/Lookup',
        LookupDataResponse,
      );

      return response != null ? new LookupDataResponseModel(response) : null;
    } catch (error) {
      return this.catchError(error, 'There was an issue retrieving base data.');
    }
  }

  /**
   * Retrieves the latest eTag for base lookup data.
   */
  async getETag(): Promise<string | null> {
    try {
      return await this.get<string>('Organisation/Lookup/ETag', ioString);
    } catch (error) {
      return this.catchError(
        error,
        'There was an issue retrieving the base data eTag.',
      );
    }
  }

  async getMenu(): Promise<ResourceResponseModel | null> {
    try {
      const response = await this.get<ResourceResponse>(
        'Organisation/Lookup/Menu',
        ResourceResponse,
      );

      return response != null ? new ResourceResponseModel(response) : null;
    } catch (error) {
      return this.catchError(
        error,
        'There was an issue retrieving the menu items.',
      );
    }
  }

  async getTSP(): Promise<ResourceLinkSlimModel | null> {
    try {
      const response = await this.get<ResourceLinkSlimModel | null>(
        'Organisation/Lookup/TSP',
        nullable(ResourceLinkSlim),
      );

      return response != null ? new ResourceLinkSlimModel(response) : null;
    } catch (error) {
      return this.catchError(
        error,
        'There was an issue retrieving the TSP link.',
      );
    }
  }

  async getSupportLink(): Promise<ResourceLinkSlimModel | null> {
    try {
      const response = await this.get<ResourceLinkSlimModel | null>(
        'Organisation/Lookup/SupportLink',
        nullable(ResourceLinkSlim),
      );

      return response != null ? new ResourceLinkSlimModel(response) : null;
    } catch (error) {
      return this.catchError(
        error,
        'There was an issue retrieving the support link.',
      );
    }
  }

  async getMdfCodes(): Promise<MdfCode[] | null> {
    try {
      const response = await this.get<MdfCode[]>(
        'Organisation/Lookup/MdfCodes',
        ioArray(MdfCode),
      );

      return response != null
        ? response.map((item) => new MdfCodeModel(item))
        : null;
    } catch (error) {
      return this.catchError(
        error,
        'There was an issue retrieving the MDF codes.',
      );
    }
  }

  async getExchangeRate(
    baseId: number,
    quoteId: number,
  ): Promise<number | null> {
    const params = new CustomHttpParams({
      baseCurrencyId: baseId,
      quoteCurrencyId: quoteId,
    });

    try {
      return await this.get<number>(
        'Organisation/Lookup/Currency/Exchange',
        ioNumber,
        params,
      );
    } catch (error) {
      return this.catchError(
        error,
        'There was an issue retrieving the currency exchange rate.',
      );
    }
  }

  async getExchangeRates(
    quoteCurrencyId: number,
  ): Promise<Record<string, number> | null> {
    try {
      return await this.get<Record<string, number>>(
        `Organisation/Lookup/Currency/Exchange/${quoteCurrencyId}`,
        ioRecord(ioString, ioNumber),
      );
    } catch (error) {
      return this.catchError(
        error,
        'There was an issue retrieving the currency exchange rates.',
      );
    }
  }
}
